// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-audio-wrapper {
  --bitmark-record-audio-audio-recorder-recording-default-background-color: #000;
  --bitmark-record-audio-audio-recorder-recording-default-size-height: 200px;
  --bitmark-record-audio-audio-recorder-recording-default-border-radius: 10px;
  background-color: var(--bitmark-record-audio-audio-recorder-recording-background-color, var(--bitmark-record-audio-audio-recorder-recording-default-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-record-audio-audio-recorder-recording-background-image, var(--bitmark-record-audio-audio-recorder-recording-default-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-record-audio-audio-recorder-recording-background-size, var(--bitmark-record-audio-audio-recorder-recording-default-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-record-audio-audio-recorder-recording-background-position, var(--bitmark-record-audio-audio-recorder-recording-default-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-record-audio-audio-recorder-recording-background-repeat, var(--bitmark-record-audio-audio-recorder-recording-default-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-record-audio-audio-recorder-recording-background-transform, var(--bitmark-record-audio-audio-recorder-recording-default-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-record-audio-audio-recorder-recording-background-animation-name) var(--bitmark-record-audio-audio-recorder-recording-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-record-audio-audio-recorder-recording-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-record-audio-audio-recorder-recording-background-backdrop-filter);
  width: var(--bitmark-record-audio-audio-recorder-recording-size-width, var(--bitmark-record-audio-audio-recorder-recording-default-size-width, inherit));
  height: var(--bitmark-record-audio-audio-recorder-recording-size-height, var(--bitmark-record-audio-audio-recorder-recording-default-size-height, inherit));
  margin: var(--bitmark-record-audio-audio-recorder-recording-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-record-audio-audio-recorder-recording-size-padding, var(--bitmark-x-size-padding, 0));
  border-top: var(--bitmark-record-audio-audio-recorder-recording-border-top-width, var(--bitmark-record-audio-audio-recorder-recording-default-border-top-width)) var(--bitmark-record-audio-audio-recorder-recording-border-style, var(--bitmark-record-audio-audio-recorder-recording-default-border-style)) var(--bitmark-record-audio-audio-recorder-recording-border-color, var(--bitmark-record-audio-audio-recorder-recording-default-border-color));
  border-right: var(--bitmark-record-audio-audio-recorder-recording-border-right-width, var(--bitmark-record-audio-audio-recorder-recording-default-border-right-width)) var(--bitmark-record-audio-audio-recorder-recording-border-style, var(--bitmark-record-audio-audio-recorder-recording-default-border-style)) var(--bitmark-record-audio-audio-recorder-recording-border-color, var(--bitmark-record-audio-audio-recorder-recording-default-border-color));
  border-bottom: var(--bitmark-record-audio-audio-recorder-recording-border-bottom-width, var(--bitmark-record-audio-audio-recorder-recording-default-border-bottom-width)) var(--bitmark-record-audio-audio-recorder-recording-border-style, var(--bitmark-record-audio-audio-recorder-recording-default-border-style)) var(--bitmark-record-audio-audio-recorder-recording-border-color, var(--bitmark-record-audio-audio-recorder-recording-default-border-color));
  border-left: var(--bitmark-record-audio-audio-recorder-recording-border-left-width, var(--bitmark-record-audio-audio-recorder-recording-default-border-left-width)) var(--bitmark-record-audio-audio-recorder-recording-border-style, var(--bitmark-record-audio-audio-recorder-recording-default-border-style)) var(--bitmark-record-audio-audio-recorder-recording-border-color, var(--bitmark-record-audio-audio-recorder-recording-default-border-color));
  border-radius: var(--bitmark-record-audio-audio-recorder-recording-border-radius, var(--bitmark-record-audio-audio-recorder-recording-default-border-radius, y));
}
.resource-audio-wrapper .resource-audio-action-buttons-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.resource-audio-wrapper .resource-audio-action-buttons-wrapper .resource-audio-action-btn {
  --bitmark-record-audio-audio-recorder-recording-default-icon-button-size-width: 70px;
  --bitmark-record-audio-audio-recorder-recording-default-icon-button-size-height: 70px;
  --bitmark-record-audio-audio-recorder-recording-default-icon-button-border-radius: 10px;
  width: var(--bitmark-record-audio-audio-recorder-recording-icon-button-size-width, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-size-width, inherit));
  height: var(--bitmark-record-audio-audio-recorder-recording-icon-button-size-height, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-size-height, inherit));
  min-width: var(--bitmark-record-audio-audio-recorder-recording-icon-button-size-width, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-size-width, inherit));
  min-height: var(--bitmark-record-audio-audio-recorder-recording-icon-button-size-height, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-size-height, inherit));
  padding: var(--bitmark-record-audio-audio-recorder-recording-icon-button-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-record-audio-audio-recorder-recording-icon-button-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-top-width, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-top-width)) var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-style, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-style)) var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-color, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-color));
  border-right: var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-right-width, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-right-width)) var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-style, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-style)) var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-color, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-color));
  border-bottom: var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-bottom-width, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-bottom-width)) var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-style, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-style)) var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-color, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-color));
  border-left: var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-left-width, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-left-width)) var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-style, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-style)) var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-color, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-color));
  border-radius: var(--bitmark-record-audio-audio-recorder-recording-icon-button-border-radius, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-border-radius, y));
  background: var(--bitmark-record-audio-audio-recorder-recording-icon-button-background-shorthand, var(--bitmark-record-audio-audio-recorder-recording-icon-button-background, #f00));
  color: var(--bitmark-record-audio-audio-recorder-recording-icon-button-color, #fff);
}
.resource-audio-wrapper .resource-audio-action-buttons-wrapper .resource-audio-action-btn-icon {
  --bitmark-record-audio-audio-recorder-recording-default-icon-button-icon-size-width: 32px;
  --bitmark-record-audio-audio-recorder-recording-default-icon-button-icon-size-height: 32px;
  width: var(--bitmark-record-audio-audio-recorder-recording-icon-button-icon-size-width, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-icon-size-width, inherit));
  height: var(--bitmark-record-audio-audio-recorder-recording-icon-button-icon-size-height, var(--bitmark-record-audio-audio-recorder-recording-default-icon-button-icon-size-height, inherit));
  padding: var(--bitmark-record-audio-audio-recorder-recording-icon-button-icon-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-record-audio-audio-recorder-recording-icon-button-icon-size-margin, var(--bitmark-x-size-margin, 0));
}
.resource-audio-wrapper .resource-audio-visualizer {
  height: 65px;
}
.resource-audio-wrapper .resource-audio-time {
  margin-bottom: 10px;
  --bitmark-record-audio-audio-recorder-recording-default-time-font-size: 18px;
  --bitmark-record-audio-audio-recorder-recording-default-time-font-line-height: 1.3;
  --bitmark-record-audio-audio-recorder-recording-default-time-font-weight: 400;
  --bitmark-record-audio-audio-recorder-recording-default-time-font-align: center;
  --bitmark-record-audio-audio-recorder-recording-default-time-font-color: #fff;
  font-family: var(--bitmark-record-audio-audio-recorder-recording-time-font-family, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-record-audio-audio-recorder-recording-time-font-size, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-record-audio-audio-recorder-recording-time-font-weight, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-record-audio-audio-recorder-recording-time-font-style, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-record-audio-audio-recorder-recording-time-font-text-decoration, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-record-audio-audio-recorder-recording-time-font-text-decoration, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-record-audio-audio-recorder-recording-time-font-line-height, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-record-audio-audio-recorder-recording-time-font-color, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-record-audio-audio-recorder-recording-time-font-align, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-record-audio-audio-recorder-recording-time-font-align, var(--bitmark-record-audio-audio-recorder-recording-default-time-font-align, var(--bitmark-main-font-alignt, left)));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
