// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chapter-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.chapter-background.level-1 {
  background-color: var(--bitmark-chapter-l-1-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-1-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-1-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-1-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-1-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-1-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-1-background-animation-name) var(--bitmark-chapter-l-1-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-1-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-1-background-backdrop-filter);
}
.chapter-background.level-2 {
  background-color: var(--bitmark-chapter-l-2-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-2-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-2-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-2-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-2-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-2-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-2-background-animation-name) var(--bitmark-chapter-l-2-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-2-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-2-background-backdrop-filter);
}
.chapter-background.level-3 {
  background-color: var(--bitmark-chapter-l-3-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-3-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-3-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-3-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-3-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-3-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-3-background-animation-name) var(--bitmark-chapter-l-3-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-3-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-3-background-backdrop-filter);
}
.chapter-background.level-4 {
  background-color: var(--bitmark-chapter-l-4-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-4-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-4-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-4-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-4-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-4-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-4-background-animation-name) var(--bitmark-chapter-l-4-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-4-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-4-background-backdrop-filter);
}
.chapter-background.level-5 {
  background-color: var(--bitmark-chapter-l-5-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-5-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-5-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-5-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-5-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-5-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-5-background-animation-name) var(--bitmark-chapter-l-5-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-5-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-5-background-backdrop-filter);
}
.chapter-background.level-6 {
  background-color: var(--bitmark-chapter-l-6-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-6-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-6-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-6-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-6-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-6-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-6-background-animation-name) var(--bitmark-chapter-l-6-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-6-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-6-background-backdrop-filter);
}
.chapter-background.level-7 {
  background-color: var(--bitmark-chapter-l-7-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-7-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-7-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-7-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-7-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-7-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-7-background-animation-name) var(--bitmark-chapter-l-7-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-7-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-7-background-backdrop-filter);
}

.chapter-wrapper {
  position: relative;
}
.chapter-wrapper.level-1 {
  width: var(--bitmark-chapter-l-1-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-1-size-height, var(--bitmark-x-size-height, inherit));
  display: flex;
  flex-direction: var(--bitmark-chapter-l-1-flex-direction, column);
  align-items: var(--bitmark-chapter-l-1-flex-align, center);
  justify-content: var(--bitmark-chapter-l-1-flex-justify, center);
  width: var(--bitmark-chapter-l-1-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-1-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-1-size-padding, var(--bitmark-x-size-padding, 0));
}
.chapter-wrapper.level-1 .chapter-item-wrapper {
  display: inline-flex;
  align-items: center;
}
.chapter-wrapper.level-1 .chapter-item-wrapper .bitmark-item {
  display: inline-block;
  font-family: var(--bitmark-chapter-l-1-item-font-family, var(--bitmark-chapter-l-1-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-1-item-font-size, var(--bitmark-chapter-l-1-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-1-item-font-weight, var(--bitmark-chapter-l-1-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-1-item-font-style, var(--bitmark-chapter-l-1-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-1-item-font-text-decoration, var(--bitmark-chapter-l-1-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-1-item-font-text-decoration, var(--bitmark-chapter-l-1-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-1-item-font-line-height, var(--bitmark-chapter-l-1-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-1-item-font-color, var(--bitmark-chapter-l-1-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-1-item-font-align, var(--bitmark-chapter-l-1-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-1-item-font-align, var(--bitmark-chapter-l-1-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-1-item-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-1-item-size-height, var(--bitmark-x-size-height, inherit));
  background-color: var(--bitmark-chapter-l-1-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-1-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-1-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-1-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-1-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-1-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-1-item-background-animation-name) var(--bitmark-chapter-l-1-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-1-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-1-item-background-backdrop-filter);
  padding: var(--bitmark-chapter-l-1-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-1-item-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-1-item-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-1-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-item-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-1-item-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-1-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-item-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-1-item-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-1-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-item-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-1-item-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-1-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-item-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-1-item-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-wrapper.level-1 .chapter-item-wrapper .bitmark-item:before {
  content: var(--bitmark-chapter-l-1-item-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-chapter-l-1-item-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-chapter-l-1-item-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-1-item-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-1-item-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-1-item-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-1-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-1-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-1-item-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-1-item-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-1-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-1-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-1 .chapter-item-wrapper .bitmark-item:after {
  content: var(--bitmark-chapter-l-1-item-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-chapter-l-1-item-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-chapter-l-1-item-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-1-item-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-1-item-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-1-item-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-1-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-1-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-1-item-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-1-item-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-1-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-1-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-1 .chapter-item-wrapper .chapter-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --bit-icon-position: var(--bitmark-chapter-l-1-item-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-chapter-l-1-item-icon-width, var(--bitmark-bit-item-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-chapter-l-1-item-icon-height, var(--bitmark-bit-item-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-chapter-l-1-item-icon-image, var(--bitmark-bit-item-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-chapter-l-1-item-icon-width, var(--bitmark-bit-item-icon-width, inherit));
  height: var(--bitmark-chapter-l-1-item-icon-height, var(--bitmark-bit-item-icon-height, inherit));
  inset: var(--bitmark-chapter-l-1-item-icon-location, var(--bitmark-bit-item-icon-location, inherit));
  margin: var(--bitmark-chapter-l-1-item-icon-margin, var(--bitmark-bit-item-icon-margin, 0));
}
.chapter-wrapper.level-1 .chapter-item-wrapper .chapter-item-icon.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.chapter-wrapper.level-1 .chapter-item-wrapper .chapter-item-icon.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
.chapter-wrapper.level-1 .chapter-title-wrapper {
  position: relative;
  width: 100%;
}
.chapter-wrapper.level-1 .chapter-title-wrapper .chapter-title {
  position: relative;
  white-space: pre-wrap;
  font-family: var(--bitmark-chapter-l-1-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-1-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-1-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-1-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-1-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-1-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-1-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-1-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-1-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-1-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-2 {
  width: var(--bitmark-chapter-l-2-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-2-size-height, var(--bitmark-x-size-height, inherit));
  display: flex;
  flex-direction: var(--bitmark-chapter-l-2-flex-direction, column);
  align-items: var(--bitmark-chapter-l-2-flex-align, center);
  justify-content: var(--bitmark-chapter-l-2-flex-justify, center);
  width: var(--bitmark-chapter-l-2-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-2-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-2-size-padding, var(--bitmark-x-size-padding, 0));
}
.chapter-wrapper.level-2 .chapter-item-wrapper {
  display: inline-flex;
  align-items: center;
}
.chapter-wrapper.level-2 .chapter-item-wrapper .bitmark-item {
  display: inline-block;
  font-family: var(--bitmark-chapter-l-2-item-font-family, var(--bitmark-chapter-l-2-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-2-item-font-size, var(--bitmark-chapter-l-2-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-2-item-font-weight, var(--bitmark-chapter-l-2-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-2-item-font-style, var(--bitmark-chapter-l-2-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-2-item-font-text-decoration, var(--bitmark-chapter-l-2-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-2-item-font-text-decoration, var(--bitmark-chapter-l-2-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-2-item-font-line-height, var(--bitmark-chapter-l-2-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-2-item-font-color, var(--bitmark-chapter-l-2-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-2-item-font-align, var(--bitmark-chapter-l-2-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-2-item-font-align, var(--bitmark-chapter-l-2-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-2-item-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-2-item-size-height, var(--bitmark-x-size-height, inherit));
  background-color: var(--bitmark-chapter-l-2-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-2-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-2-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-2-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-2-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-2-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-2-item-background-animation-name) var(--bitmark-chapter-l-2-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-2-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-2-item-background-backdrop-filter);
  padding: var(--bitmark-chapter-l-2-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-2-item-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-2-item-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-2-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-item-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-2-item-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-2-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-item-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-2-item-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-2-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-item-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-2-item-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-2-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-item-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-2-item-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-wrapper.level-2 .chapter-item-wrapper .bitmark-item:before {
  content: var(--bitmark-chapter-l-2-item-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-chapter-l-2-item-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-chapter-l-2-item-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-2-item-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-2-item-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-2-item-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-2-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-2-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-2-item-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-2-item-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-2-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-2-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-2 .chapter-item-wrapper .bitmark-item:after {
  content: var(--bitmark-chapter-l-2-item-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-chapter-l-2-item-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-chapter-l-2-item-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-2-item-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-2-item-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-2-item-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-2-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-2-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-2-item-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-2-item-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-2-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-2-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-2 .chapter-item-wrapper .chapter-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --bit-icon-position: var(--bitmark-chapter-l-2-item-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-chapter-l-2-item-icon-width, var(--bitmark-bit-item-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-chapter-l-2-item-icon-height, var(--bitmark-bit-item-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-chapter-l-2-item-icon-image, var(--bitmark-bit-item-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-chapter-l-2-item-icon-width, var(--bitmark-bit-item-icon-width, inherit));
  height: var(--bitmark-chapter-l-2-item-icon-height, var(--bitmark-bit-item-icon-height, inherit));
  inset: var(--bitmark-chapter-l-2-item-icon-location, var(--bitmark-bit-item-icon-location, inherit));
  margin: var(--bitmark-chapter-l-2-item-icon-margin, var(--bitmark-bit-item-icon-margin, 0));
}
.chapter-wrapper.level-2 .chapter-item-wrapper .chapter-item-icon.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.chapter-wrapper.level-2 .chapter-item-wrapper .chapter-item-icon.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
.chapter-wrapper.level-2 .chapter-title-wrapper {
  position: relative;
  width: 100%;
}
.chapter-wrapper.level-2 .chapter-title-wrapper .chapter-title {
  position: relative;
  white-space: pre-wrap;
  font-family: var(--bitmark-chapter-l-2-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-2-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-2-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-2-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-2-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-2-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-2-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-2-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-2-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-2-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-3 {
  width: var(--bitmark-chapter-l-3-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-3-size-height, var(--bitmark-x-size-height, inherit));
  display: flex;
  flex-direction: var(--bitmark-chapter-l-3-flex-direction, column);
  align-items: var(--bitmark-chapter-l-3-flex-align, center);
  justify-content: var(--bitmark-chapter-l-3-flex-justify, center);
  width: var(--bitmark-chapter-l-3-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-3-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-3-size-padding, var(--bitmark-x-size-padding, 0));
}
.chapter-wrapper.level-3 .chapter-item-wrapper {
  display: inline-flex;
  align-items: center;
}
.chapter-wrapper.level-3 .chapter-item-wrapper .bitmark-item {
  display: inline-block;
  font-family: var(--bitmark-chapter-l-3-item-font-family, var(--bitmark-chapter-l-3-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-3-item-font-size, var(--bitmark-chapter-l-3-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-3-item-font-weight, var(--bitmark-chapter-l-3-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-3-item-font-style, var(--bitmark-chapter-l-3-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-3-item-font-text-decoration, var(--bitmark-chapter-l-3-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-3-item-font-text-decoration, var(--bitmark-chapter-l-3-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-3-item-font-line-height, var(--bitmark-chapter-l-3-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-3-item-font-color, var(--bitmark-chapter-l-3-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-3-item-font-align, var(--bitmark-chapter-l-3-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-3-item-font-align, var(--bitmark-chapter-l-3-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-3-item-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-3-item-size-height, var(--bitmark-x-size-height, inherit));
  background-color: var(--bitmark-chapter-l-3-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-3-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-3-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-3-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-3-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-3-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-3-item-background-animation-name) var(--bitmark-chapter-l-3-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-3-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-3-item-background-backdrop-filter);
  padding: var(--bitmark-chapter-l-3-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-3-item-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-3-item-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-3-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-item-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-3-item-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-3-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-item-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-3-item-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-3-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-item-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-3-item-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-3-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-item-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-3-item-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-wrapper.level-3 .chapter-item-wrapper .bitmark-item:before {
  content: var(--bitmark-chapter-l-3-item-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-chapter-l-3-item-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-chapter-l-3-item-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-3-item-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-3-item-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-3-item-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-3-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-3-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-3-item-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-3-item-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-3-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-3-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-3 .chapter-item-wrapper .bitmark-item:after {
  content: var(--bitmark-chapter-l-3-item-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-chapter-l-3-item-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-chapter-l-3-item-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-3-item-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-3-item-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-3-item-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-3-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-3-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-3-item-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-3-item-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-3-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-3-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-3 .chapter-item-wrapper .chapter-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --bit-icon-position: var(--bitmark-chapter-l-3-item-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-chapter-l-3-item-icon-width, var(--bitmark-bit-item-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-chapter-l-3-item-icon-height, var(--bitmark-bit-item-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-chapter-l-3-item-icon-image, var(--bitmark-bit-item-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-chapter-l-3-item-icon-width, var(--bitmark-bit-item-icon-width, inherit));
  height: var(--bitmark-chapter-l-3-item-icon-height, var(--bitmark-bit-item-icon-height, inherit));
  inset: var(--bitmark-chapter-l-3-item-icon-location, var(--bitmark-bit-item-icon-location, inherit));
  margin: var(--bitmark-chapter-l-3-item-icon-margin, var(--bitmark-bit-item-icon-margin, 0));
}
.chapter-wrapper.level-3 .chapter-item-wrapper .chapter-item-icon.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.chapter-wrapper.level-3 .chapter-item-wrapper .chapter-item-icon.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
.chapter-wrapper.level-3 .chapter-title-wrapper {
  position: relative;
  width: 100%;
}
.chapter-wrapper.level-3 .chapter-title-wrapper .chapter-title {
  position: relative;
  white-space: pre-wrap;
  font-family: var(--bitmark-chapter-l-3-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-3-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-3-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-3-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-3-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-3-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-3-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-3-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-3-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-3-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-4 {
  width: var(--bitmark-chapter-l-4-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-4-size-height, var(--bitmark-x-size-height, inherit));
  display: flex;
  flex-direction: var(--bitmark-chapter-l-4-flex-direction, column);
  align-items: var(--bitmark-chapter-l-4-flex-align, center);
  justify-content: var(--bitmark-chapter-l-4-flex-justify, center);
  width: var(--bitmark-chapter-l-4-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-4-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-4-size-padding, var(--bitmark-x-size-padding, 0));
}
.chapter-wrapper.level-4 .chapter-item-wrapper {
  display: inline-flex;
  align-items: center;
}
.chapter-wrapper.level-4 .chapter-item-wrapper .bitmark-item {
  display: inline-block;
  font-family: var(--bitmark-chapter-l-4-item-font-family, var(--bitmark-chapter-l-4-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-4-item-font-size, var(--bitmark-chapter-l-4-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-4-item-font-weight, var(--bitmark-chapter-l-4-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-4-item-font-style, var(--bitmark-chapter-l-4-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-4-item-font-text-decoration, var(--bitmark-chapter-l-4-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-4-item-font-text-decoration, var(--bitmark-chapter-l-4-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-4-item-font-line-height, var(--bitmark-chapter-l-4-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-4-item-font-color, var(--bitmark-chapter-l-4-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-4-item-font-align, var(--bitmark-chapter-l-4-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-4-item-font-align, var(--bitmark-chapter-l-4-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-4-item-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-4-item-size-height, var(--bitmark-x-size-height, inherit));
  background-color: var(--bitmark-chapter-l-4-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-4-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-4-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-4-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-4-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-4-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-4-item-background-animation-name) var(--bitmark-chapter-l-4-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-4-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-4-item-background-backdrop-filter);
  padding: var(--bitmark-chapter-l-4-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-4-item-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-4-item-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-4-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-item-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-4-item-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-4-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-item-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-4-item-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-4-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-item-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-4-item-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-4-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-item-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-4-item-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-wrapper.level-4 .chapter-item-wrapper .bitmark-item:before {
  content: var(--bitmark-chapter-l-4-item-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-chapter-l-4-item-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-chapter-l-4-item-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-4-item-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-4-item-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-4-item-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-4-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-4-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-4-item-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-4-item-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-4-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-4-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-4 .chapter-item-wrapper .bitmark-item:after {
  content: var(--bitmark-chapter-l-4-item-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-chapter-l-4-item-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-chapter-l-4-item-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-4-item-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-4-item-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-4-item-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-4-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-4-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-4-item-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-4-item-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-4-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-4-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-4 .chapter-item-wrapper .chapter-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --bit-icon-position: var(--bitmark-chapter-l-4-item-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-chapter-l-4-item-icon-width, var(--bitmark-bit-item-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-chapter-l-4-item-icon-height, var(--bitmark-bit-item-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-chapter-l-4-item-icon-image, var(--bitmark-bit-item-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-chapter-l-4-item-icon-width, var(--bitmark-bit-item-icon-width, inherit));
  height: var(--bitmark-chapter-l-4-item-icon-height, var(--bitmark-bit-item-icon-height, inherit));
  inset: var(--bitmark-chapter-l-4-item-icon-location, var(--bitmark-bit-item-icon-location, inherit));
  margin: var(--bitmark-chapter-l-4-item-icon-margin, var(--bitmark-bit-item-icon-margin, 0));
}
.chapter-wrapper.level-4 .chapter-item-wrapper .chapter-item-icon.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.chapter-wrapper.level-4 .chapter-item-wrapper .chapter-item-icon.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
.chapter-wrapper.level-4 .chapter-title-wrapper {
  position: relative;
  width: 100%;
}
.chapter-wrapper.level-4 .chapter-title-wrapper .chapter-title {
  position: relative;
  white-space: pre-wrap;
  font-family: var(--bitmark-chapter-l-4-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-4-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-4-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-4-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-4-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-4-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-4-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-4-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-4-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-4-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-5 {
  width: var(--bitmark-chapter-l-5-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-5-size-height, var(--bitmark-x-size-height, inherit));
  display: flex;
  flex-direction: var(--bitmark-chapter-l-5-flex-direction, column);
  align-items: var(--bitmark-chapter-l-5-flex-align, center);
  justify-content: var(--bitmark-chapter-l-5-flex-justify, center);
  width: var(--bitmark-chapter-l-5-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-5-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-5-size-padding, var(--bitmark-x-size-padding, 0));
}
.chapter-wrapper.level-5 .chapter-item-wrapper {
  display: inline-flex;
  align-items: center;
}
.chapter-wrapper.level-5 .chapter-item-wrapper .bitmark-item {
  display: inline-block;
  font-family: var(--bitmark-chapter-l-5-item-font-family, var(--bitmark-chapter-l-5-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-5-item-font-size, var(--bitmark-chapter-l-5-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-5-item-font-weight, var(--bitmark-chapter-l-5-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-5-item-font-style, var(--bitmark-chapter-l-5-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-5-item-font-text-decoration, var(--bitmark-chapter-l-5-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-5-item-font-text-decoration, var(--bitmark-chapter-l-5-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-5-item-font-line-height, var(--bitmark-chapter-l-5-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-5-item-font-color, var(--bitmark-chapter-l-5-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-5-item-font-align, var(--bitmark-chapter-l-5-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-5-item-font-align, var(--bitmark-chapter-l-5-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-5-item-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-5-item-size-height, var(--bitmark-x-size-height, inherit));
  background-color: var(--bitmark-chapter-l-5-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-5-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-5-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-5-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-5-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-5-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-5-item-background-animation-name) var(--bitmark-chapter-l-5-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-5-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-5-item-background-backdrop-filter);
  padding: var(--bitmark-chapter-l-5-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-5-item-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-5-item-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-5-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-item-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-5-item-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-5-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-item-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-5-item-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-5-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-item-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-5-item-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-5-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-item-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-5-item-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-wrapper.level-5 .chapter-item-wrapper .bitmark-item:before {
  content: var(--bitmark-chapter-l-5-item-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-chapter-l-5-item-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-chapter-l-5-item-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-5-item-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-5-item-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-5-item-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-5-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-5-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-5-item-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-5-item-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-5-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-5-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-5 .chapter-item-wrapper .bitmark-item:after {
  content: var(--bitmark-chapter-l-5-item-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-chapter-l-5-item-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-chapter-l-5-item-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-5-item-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-5-item-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-5-item-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-5-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-5-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-5-item-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-5-item-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-5-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-5-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-5 .chapter-item-wrapper .chapter-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --bit-icon-position: var(--bitmark-chapter-l-5-item-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-chapter-l-5-item-icon-width, var(--bitmark-bit-item-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-chapter-l-5-item-icon-height, var(--bitmark-bit-item-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-chapter-l-5-item-icon-image, var(--bitmark-bit-item-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-chapter-l-5-item-icon-width, var(--bitmark-bit-item-icon-width, inherit));
  height: var(--bitmark-chapter-l-5-item-icon-height, var(--bitmark-bit-item-icon-height, inherit));
  inset: var(--bitmark-chapter-l-5-item-icon-location, var(--bitmark-bit-item-icon-location, inherit));
  margin: var(--bitmark-chapter-l-5-item-icon-margin, var(--bitmark-bit-item-icon-margin, 0));
}
.chapter-wrapper.level-5 .chapter-item-wrapper .chapter-item-icon.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.chapter-wrapper.level-5 .chapter-item-wrapper .chapter-item-icon.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
.chapter-wrapper.level-5 .chapter-title-wrapper {
  position: relative;
  width: 100%;
}
.chapter-wrapper.level-5 .chapter-title-wrapper .chapter-title {
  position: relative;
  white-space: pre-wrap;
  font-family: var(--bitmark-chapter-l-5-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-5-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-5-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-5-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-5-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-5-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-5-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-5-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-5-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-5-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-6 {
  width: var(--bitmark-chapter-l-6-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-6-size-height, var(--bitmark-x-size-height, inherit));
  display: flex;
  flex-direction: var(--bitmark-chapter-l-6-flex-direction, column);
  align-items: var(--bitmark-chapter-l-6-flex-align, center);
  justify-content: var(--bitmark-chapter-l-6-flex-justify, center);
  width: var(--bitmark-chapter-l-6-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-6-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-6-size-padding, var(--bitmark-x-size-padding, 0));
}
.chapter-wrapper.level-6 .chapter-item-wrapper {
  display: inline-flex;
  align-items: center;
}
.chapter-wrapper.level-6 .chapter-item-wrapper .bitmark-item {
  display: inline-block;
  font-family: var(--bitmark-chapter-l-6-item-font-family, var(--bitmark-chapter-l-6-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-6-item-font-size, var(--bitmark-chapter-l-6-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-6-item-font-weight, var(--bitmark-chapter-l-6-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-6-item-font-style, var(--bitmark-chapter-l-6-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-6-item-font-text-decoration, var(--bitmark-chapter-l-6-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-6-item-font-text-decoration, var(--bitmark-chapter-l-6-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-6-item-font-line-height, var(--bitmark-chapter-l-6-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-6-item-font-color, var(--bitmark-chapter-l-6-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-6-item-font-align, var(--bitmark-chapter-l-6-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-6-item-font-align, var(--bitmark-chapter-l-6-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-6-item-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-6-item-size-height, var(--bitmark-x-size-height, inherit));
  background-color: var(--bitmark-chapter-l-6-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-6-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-6-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-6-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-6-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-6-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-6-item-background-animation-name) var(--bitmark-chapter-l-6-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-6-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-6-item-background-backdrop-filter);
  padding: var(--bitmark-chapter-l-6-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-6-item-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-6-item-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-6-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-item-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-6-item-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-6-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-item-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-6-item-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-6-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-item-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-6-item-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-6-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-item-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-6-item-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-wrapper.level-6 .chapter-item-wrapper .bitmark-item:before {
  content: var(--bitmark-chapter-l-6-item-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-chapter-l-6-item-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-chapter-l-6-item-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-6-item-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-6-item-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-6-item-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-6-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-6-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-6-item-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-6-item-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-6-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-6-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-6 .chapter-item-wrapper .bitmark-item:after {
  content: var(--bitmark-chapter-l-6-item-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-chapter-l-6-item-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-chapter-l-6-item-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-6-item-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-6-item-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-6-item-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-6-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-6-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-6-item-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-6-item-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-6-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-6-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-6 .chapter-item-wrapper .chapter-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --bit-icon-position: var(--bitmark-chapter-l-6-item-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-chapter-l-6-item-icon-width, var(--bitmark-bit-item-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-chapter-l-6-item-icon-height, var(--bitmark-bit-item-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-chapter-l-6-item-icon-image, var(--bitmark-bit-item-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-chapter-l-6-item-icon-width, var(--bitmark-bit-item-icon-width, inherit));
  height: var(--bitmark-chapter-l-6-item-icon-height, var(--bitmark-bit-item-icon-height, inherit));
  inset: var(--bitmark-chapter-l-6-item-icon-location, var(--bitmark-bit-item-icon-location, inherit));
  margin: var(--bitmark-chapter-l-6-item-icon-margin, var(--bitmark-bit-item-icon-margin, 0));
}
.chapter-wrapper.level-6 .chapter-item-wrapper .chapter-item-icon.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.chapter-wrapper.level-6 .chapter-item-wrapper .chapter-item-icon.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
.chapter-wrapper.level-6 .chapter-title-wrapper {
  position: relative;
  width: 100%;
}
.chapter-wrapper.level-6 .chapter-title-wrapper .chapter-title {
  position: relative;
  white-space: pre-wrap;
  font-family: var(--bitmark-chapter-l-6-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-6-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-6-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-6-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-6-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-6-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-6-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-6-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-6-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-6-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-7 {
  width: var(--bitmark-chapter-l-7-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-7-size-height, var(--bitmark-x-size-height, inherit));
  display: flex;
  flex-direction: var(--bitmark-chapter-l-7-flex-direction, column);
  align-items: var(--bitmark-chapter-l-7-flex-align, center);
  justify-content: var(--bitmark-chapter-l-7-flex-justify, center);
  width: var(--bitmark-chapter-l-7-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-7-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-7-size-padding, var(--bitmark-x-size-padding, 0));
}
.chapter-wrapper.level-7 .chapter-item-wrapper {
  display: inline-flex;
  align-items: center;
}
.chapter-wrapper.level-7 .chapter-item-wrapper .bitmark-item {
  display: inline-block;
  font-family: var(--bitmark-chapter-l-7-item-font-family, var(--bitmark-chapter-l-7-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-7-item-font-size, var(--bitmark-chapter-l-7-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-7-item-font-weight, var(--bitmark-chapter-l-7-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-7-item-font-style, var(--bitmark-chapter-l-7-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-7-item-font-text-decoration, var(--bitmark-chapter-l-7-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-7-item-font-text-decoration, var(--bitmark-chapter-l-7-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-7-item-font-line-height, var(--bitmark-chapter-l-7-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-7-item-font-color, var(--bitmark-chapter-l-7-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-7-item-font-align, var(--bitmark-chapter-l-7-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-7-item-font-align, var(--bitmark-chapter-l-7-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-7-item-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-7-item-size-height, var(--bitmark-x-size-height, inherit));
  background-color: var(--bitmark-chapter-l-7-item-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-chapter-l-7-item-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-chapter-l-7-item-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-chapter-l-7-item-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-chapter-l-7-item-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-chapter-l-7-item-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-chapter-l-7-item-background-animation-name) var(--bitmark-chapter-l-7-item-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-chapter-l-7-item-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-chapter-l-7-item-background-backdrop-filter);
  padding: var(--bitmark-chapter-l-7-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-7-item-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-7-item-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-7-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-item-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-7-item-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-7-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-item-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-7-item-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-7-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-item-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-7-item-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-7-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-item-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-7-item-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-wrapper.level-7 .chapter-item-wrapper .bitmark-item:before {
  content: var(--bitmark-chapter-l-7-item-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-chapter-l-7-item-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-chapter-l-7-item-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-7-item-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-7-item-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-7-item-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-7-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-7-item-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-7-item-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-7-item-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-7-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-7-item-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-7 .chapter-item-wrapper .bitmark-item:after {
  content: var(--bitmark-chapter-l-7-item-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-chapter-l-7-item-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-chapter-l-7-item-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-7-item-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-7-item-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-7-item-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-7-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-7-item-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-7-item-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-7-item-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-7-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-7-item-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.chapter-wrapper.level-7 .chapter-item-wrapper .chapter-item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  --bit-icon-position: var(--bitmark-chapter-l-7-item-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-chapter-l-7-item-icon-width, var(--bitmark-bit-item-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-chapter-l-7-item-icon-height, var(--bitmark-bit-item-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-chapter-l-7-item-icon-image, var(--bitmark-bit-item-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-chapter-l-7-item-icon-width, var(--bitmark-bit-item-icon-width, inherit));
  height: var(--bitmark-chapter-l-7-item-icon-height, var(--bitmark-bit-item-icon-height, inherit));
  inset: var(--bitmark-chapter-l-7-item-icon-location, var(--bitmark-bit-item-icon-location, inherit));
  margin: var(--bitmark-chapter-l-7-item-icon-margin, var(--bitmark-bit-item-icon-margin, 0));
}
.chapter-wrapper.level-7 .chapter-item-wrapper .chapter-item-icon.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.chapter-wrapper.level-7 .chapter-item-wrapper .chapter-item-icon.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
.chapter-wrapper.level-7 .chapter-title-wrapper {
  position: relative;
  width: 100%;
}
.chapter-wrapper.level-7 .chapter-title-wrapper .chapter-title {
  position: relative;
  white-space: pre-wrap;
  font-family: var(--bitmark-chapter-l-7-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-7-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-7-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-7-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-7-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-7-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-7-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-7-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-7-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-7-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.chapter-instruction-wrapper {
  width: 100%;
  display: flex;
}
.chapter-instruction-wrapper.level-1 {
  justify-content: var(--bitmark-chapter-l-1-instruction-font-align, inherit);
}
.chapter-instruction-wrapper.level-1 .bitmark-instruction {
  font-family: var(--bitmark-chapter-l-1-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-1-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-1-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-1-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-1-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-1-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-1-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-1-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-1-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-1-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-1-instruction-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-1-instruction-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-1-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-1-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-1-instruction-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-1-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-instruction-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-1-instruction-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-1-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-instruction-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-1-instruction-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-1-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-instruction-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-1-instruction-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-1-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-instruction-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-1-instruction-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-instruction-wrapper.level-2 {
  justify-content: var(--bitmark-chapter-l-2-instruction-font-align, inherit);
}
.chapter-instruction-wrapper.level-2 .bitmark-instruction {
  font-family: var(--bitmark-chapter-l-2-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-2-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-2-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-2-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-2-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-2-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-2-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-2-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-2-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-2-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-2-instruction-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-2-instruction-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-2-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-2-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-2-instruction-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-2-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-instruction-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-2-instruction-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-2-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-instruction-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-2-instruction-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-2-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-instruction-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-2-instruction-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-2-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-instruction-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-2-instruction-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-instruction-wrapper.level-3 {
  justify-content: var(--bitmark-chapter-l-3-instruction-font-align, inherit);
}
.chapter-instruction-wrapper.level-3 .bitmark-instruction {
  font-family: var(--bitmark-chapter-l-3-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-3-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-3-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-3-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-3-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-3-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-3-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-3-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-3-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-3-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-3-instruction-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-3-instruction-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-3-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-3-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-3-instruction-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-3-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-instruction-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-3-instruction-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-3-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-instruction-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-3-instruction-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-3-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-instruction-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-3-instruction-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-3-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-instruction-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-3-instruction-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-instruction-wrapper.level-4 {
  justify-content: var(--bitmark-chapter-l-4-instruction-font-align, inherit);
}
.chapter-instruction-wrapper.level-4 .bitmark-instruction {
  font-family: var(--bitmark-chapter-l-4-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-4-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-4-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-4-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-4-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-4-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-4-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-4-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-4-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-4-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-4-instruction-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-4-instruction-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-4-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-4-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-4-instruction-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-4-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-instruction-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-4-instruction-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-4-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-instruction-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-4-instruction-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-4-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-instruction-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-4-instruction-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-4-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-instruction-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-4-instruction-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-instruction-wrapper.level-5 {
  justify-content: var(--bitmark-chapter-l-5-instruction-font-align, inherit);
}
.chapter-instruction-wrapper.level-5 .bitmark-instruction {
  font-family: var(--bitmark-chapter-l-5-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-5-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-5-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-5-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-5-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-5-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-5-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-5-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-5-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-5-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-5-instruction-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-5-instruction-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-5-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-5-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-5-instruction-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-5-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-instruction-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-5-instruction-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-5-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-instruction-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-5-instruction-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-5-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-instruction-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-5-instruction-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-5-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-instruction-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-5-instruction-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-instruction-wrapper.level-6 {
  justify-content: var(--bitmark-chapter-l-6-instruction-font-align, inherit);
}
.chapter-instruction-wrapper.level-6 .bitmark-instruction {
  font-family: var(--bitmark-chapter-l-6-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-6-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-6-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-6-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-6-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-6-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-6-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-6-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-6-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-6-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-6-instruction-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-6-instruction-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-6-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-6-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-6-instruction-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-6-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-instruction-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-6-instruction-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-6-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-instruction-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-6-instruction-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-6-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-instruction-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-6-instruction-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-6-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-instruction-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-6-instruction-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-instruction-wrapper.level-7 {
  justify-content: var(--bitmark-chapter-l-7-instruction-font-align, inherit);
}
.chapter-instruction-wrapper.level-7 .bitmark-instruction {
  font-family: var(--bitmark-chapter-l-7-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-7-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-7-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-7-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-7-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-7-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-7-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-7-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-7-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-7-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-7-instruction-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-7-instruction-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-7-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-7-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-7-instruction-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-7-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-instruction-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-7-instruction-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-7-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-instruction-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-7-instruction-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-7-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-instruction-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-7-instruction-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-7-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-instruction-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-7-instruction-border-radius, var(--bitmark-x-border-radius, y));
}

.chapter-body-wrapper {
  width: 100%;
  display: flex;
}
.chapter-body-wrapper.level-1 {
  justify-content: var(--bitmark-chapter-l-1-body-font-align, inherit);
}
.chapter-body-wrapper.level-1 .body {
  font-family: var(--bitmark-chapter-l-1-body-font-family, var(--bitmark-chapter-l-1-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-1-body-font-size, var(--bitmark-chapter-l-1-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-1-body-font-weight, var(--bitmark-chapter-l-1-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-1-body-font-style, var(--bitmark-chapter-l-1-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-1-body-font-text-decoration, var(--bitmark-chapter-l-1-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-1-body-font-text-decoration, var(--bitmark-chapter-l-1-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-1-body-font-line-height, var(--bitmark-chapter-l-1-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-1-body-font-color, var(--bitmark-chapter-l-1-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-1-body-font-align, var(--bitmark-chapter-l-1-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-1-body-font-align, var(--bitmark-chapter-l-1-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-1-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-1-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-1-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-1-body-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-1-body-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-1-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-body-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-1-body-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-1-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-body-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-1-body-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-1-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-body-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-1-body-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-1-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-1-body-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-1-body-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-body-wrapper.level-2 {
  justify-content: var(--bitmark-chapter-l-2-body-font-align, inherit);
}
.chapter-body-wrapper.level-2 .body {
  font-family: var(--bitmark-chapter-l-2-body-font-family, var(--bitmark-chapter-l-2-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-2-body-font-size, var(--bitmark-chapter-l-2-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-2-body-font-weight, var(--bitmark-chapter-l-2-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-2-body-font-style, var(--bitmark-chapter-l-2-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-2-body-font-text-decoration, var(--bitmark-chapter-l-2-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-2-body-font-text-decoration, var(--bitmark-chapter-l-2-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-2-body-font-line-height, var(--bitmark-chapter-l-2-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-2-body-font-color, var(--bitmark-chapter-l-2-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-2-body-font-align, var(--bitmark-chapter-l-2-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-2-body-font-align, var(--bitmark-chapter-l-2-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-2-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-2-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-2-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-2-body-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-2-body-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-2-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-body-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-2-body-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-2-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-body-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-2-body-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-2-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-body-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-2-body-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-2-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-2-body-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-2-body-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-body-wrapper.level-3 {
  justify-content: var(--bitmark-chapter-l-3-body-font-align, inherit);
}
.chapter-body-wrapper.level-3 .body {
  font-family: var(--bitmark-chapter-l-3-body-font-family, var(--bitmark-chapter-l-3-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-3-body-font-size, var(--bitmark-chapter-l-3-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-3-body-font-weight, var(--bitmark-chapter-l-3-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-3-body-font-style, var(--bitmark-chapter-l-3-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-3-body-font-text-decoration, var(--bitmark-chapter-l-3-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-3-body-font-text-decoration, var(--bitmark-chapter-l-3-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-3-body-font-line-height, var(--bitmark-chapter-l-3-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-3-body-font-color, var(--bitmark-chapter-l-3-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-3-body-font-align, var(--bitmark-chapter-l-3-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-3-body-font-align, var(--bitmark-chapter-l-3-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-3-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-3-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-3-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-3-body-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-3-body-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-3-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-body-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-3-body-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-3-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-body-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-3-body-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-3-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-body-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-3-body-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-3-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-3-body-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-3-body-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-body-wrapper.level-4 {
  justify-content: var(--bitmark-chapter-l-4-body-font-align, inherit);
}
.chapter-body-wrapper.level-4 .body {
  font-family: var(--bitmark-chapter-l-4-body-font-family, var(--bitmark-chapter-l-4-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-4-body-font-size, var(--bitmark-chapter-l-4-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-4-body-font-weight, var(--bitmark-chapter-l-4-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-4-body-font-style, var(--bitmark-chapter-l-4-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-4-body-font-text-decoration, var(--bitmark-chapter-l-4-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-4-body-font-text-decoration, var(--bitmark-chapter-l-4-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-4-body-font-line-height, var(--bitmark-chapter-l-4-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-4-body-font-color, var(--bitmark-chapter-l-4-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-4-body-font-align, var(--bitmark-chapter-l-4-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-4-body-font-align, var(--bitmark-chapter-l-4-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-4-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-4-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-4-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-4-body-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-4-body-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-4-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-body-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-4-body-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-4-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-body-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-4-body-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-4-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-body-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-4-body-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-4-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-4-body-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-4-body-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-body-wrapper.level-5 {
  justify-content: var(--bitmark-chapter-l-5-body-font-align, inherit);
}
.chapter-body-wrapper.level-5 .body {
  font-family: var(--bitmark-chapter-l-5-body-font-family, var(--bitmark-chapter-l-5-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-5-body-font-size, var(--bitmark-chapter-l-5-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-5-body-font-weight, var(--bitmark-chapter-l-5-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-5-body-font-style, var(--bitmark-chapter-l-5-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-5-body-font-text-decoration, var(--bitmark-chapter-l-5-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-5-body-font-text-decoration, var(--bitmark-chapter-l-5-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-5-body-font-line-height, var(--bitmark-chapter-l-5-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-5-body-font-color, var(--bitmark-chapter-l-5-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-5-body-font-align, var(--bitmark-chapter-l-5-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-5-body-font-align, var(--bitmark-chapter-l-5-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-5-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-5-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-5-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-5-body-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-5-body-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-5-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-body-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-5-body-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-5-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-body-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-5-body-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-5-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-body-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-5-body-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-5-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-5-body-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-5-body-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-body-wrapper.level-6 {
  justify-content: var(--bitmark-chapter-l-6-body-font-align, inherit);
}
.chapter-body-wrapper.level-6 .body {
  font-family: var(--bitmark-chapter-l-6-body-font-family, var(--bitmark-chapter-l-6-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-6-body-font-size, var(--bitmark-chapter-l-6-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-6-body-font-weight, var(--bitmark-chapter-l-6-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-6-body-font-style, var(--bitmark-chapter-l-6-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-6-body-font-text-decoration, var(--bitmark-chapter-l-6-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-6-body-font-text-decoration, var(--bitmark-chapter-l-6-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-6-body-font-line-height, var(--bitmark-chapter-l-6-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-6-body-font-color, var(--bitmark-chapter-l-6-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-6-body-font-align, var(--bitmark-chapter-l-6-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-6-body-font-align, var(--bitmark-chapter-l-6-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-6-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-6-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-6-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-6-body-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-6-body-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-6-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-body-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-6-body-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-6-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-body-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-6-body-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-6-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-body-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-6-body-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-6-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-6-body-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-6-body-border-radius, var(--bitmark-x-border-radius, y));
}
.chapter-body-wrapper.level-7 {
  justify-content: var(--bitmark-chapter-l-7-body-font-align, inherit);
}
.chapter-body-wrapper.level-7 .body {
  font-family: var(--bitmark-chapter-l-7-body-font-family, var(--bitmark-chapter-l-7-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-chapter-l-7-body-font-size, var(--bitmark-chapter-l-7-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-chapter-l-7-body-font-weight, var(--bitmark-chapter-l-7-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-chapter-l-7-body-font-style, var(--bitmark-chapter-l-7-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-chapter-l-7-body-font-text-decoration, var(--bitmark-chapter-l-7-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-chapter-l-7-body-font-text-decoration, var(--bitmark-chapter-l-7-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-chapter-l-7-body-font-line-height, var(--bitmark-chapter-l-7-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-chapter-l-7-body-font-color, var(--bitmark-chapter-l-7-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-chapter-l-7-body-font-align, var(--bitmark-chapter-l-7-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-chapter-l-7-body-font-align, var(--bitmark-chapter-l-7-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-chapter-l-7-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-chapter-l-7-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-chapter-l-7-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-chapter-l-7-body-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-chapter-l-7-body-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-chapter-l-7-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-body-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-chapter-l-7-body-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-chapter-l-7-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-body-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-chapter-l-7-body-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-chapter-l-7-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-body-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-chapter-l-7-body-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-chapter-l-7-body-border-style, var(--bitmark-x-border-style)) var(--bitmark-chapter-l-7-body-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-chapter-l-7-body-border-radius, var(--bitmark-x-border-radius, y));
}

.editor-chapter-selected-level {
  background-color: #543199;
  border-radius: 5px;
  color: #ffffff;
}

.editor-chapter-levels {
  right: 10px;
  bottom: 2px;
  font-size: 16px;
  z-index: 2;
  background-color: #ffffff;
  font-family: "Inter", "sans-serif";
  font-weight: 400;
  padding: 4px 7px;
  border-radius: 5px;
  border: #543199 solid 2px;
  color: initial;
}

.editor-chapter-edit-input {
  border: none;
  background-color: #ffffff;
  box-shadow: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
