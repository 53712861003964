// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grouped-item-strike {
  text-decoration: line-through;
}
.grouped-item-strike.cloze-instruction {
  text-decoration-color: var(--bitmark-cloze-instruction-grouped-strike-color, #000);
  text-decoration-thickness: var(--bitmark-cloze-instruction-grouped-strike-thickness, 1px);
}
.grouped-item-strike.cloze-solution {
  text-decoration-color: var(--bitmark-cloze-solution-grouped-strike-color, #000);
  text-decoration-thickness: var(--bitmark-cloze-solution-grouped-strike-thickness, 1px);
}

.cloze-instruction .cloze-count-content:before {
  content: var(--bitmark-cloze-instruction-grouped-count-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-cloze-instruction-grouped-count-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-cloze-instruction-grouped-count-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-cloze-instruction-grouped-count-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-cloze-instruction-grouped-count-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-cloze-instruction-grouped-count-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-cloze-instruction-grouped-count-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-cloze-instruction-grouped-count-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-cloze-instruction-grouped-count-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-cloze-instruction-grouped-count-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-cloze-instruction-grouped-count-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-cloze-instruction-grouped-count-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.cloze-instruction .cloze-count-content:after {
  content: var(--bitmark-cloze-instruction-grouped-count-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-cloze-instruction-grouped-count-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-cloze-instruction-grouped-count-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-cloze-instruction-grouped-count-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-cloze-instruction-grouped-count-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-cloze-instruction-grouped-count-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-cloze-instruction-grouped-count-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-cloze-instruction-grouped-count-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-cloze-instruction-grouped-count-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-cloze-instruction-grouped-count-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-cloze-instruction-grouped-count-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-cloze-instruction-grouped-count-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}

.cloze-solution .cloze-count-content:before {
  content: var(--bitmark-cloze-solution-grouped-count-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-cloze-solution-grouped-count-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-cloze-solution-grouped-count-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-cloze-solution-grouped-count-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-cloze-solution-grouped-count-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-cloze-solution-grouped-count-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-cloze-solution-grouped-count-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-cloze-solution-grouped-count-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-cloze-solution-grouped-count-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-cloze-solution-grouped-count-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-cloze-solution-grouped-count-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-cloze-solution-grouped-count-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.cloze-solution .cloze-count-content:after {
  content: var(--bitmark-cloze-solution-grouped-count-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-cloze-solution-grouped-count-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-cloze-solution-grouped-count-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-cloze-solution-grouped-count-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-cloze-solution-grouped-count-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-cloze-solution-grouped-count-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-cloze-solution-grouped-count-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-cloze-solution-grouped-count-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-cloze-solution-grouped-count-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-cloze-solution-grouped-count-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-cloze-solution-grouped-count-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-cloze-solution-grouped-count-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
