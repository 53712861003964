import {JSONContent} from '@tiptap/core';
import {BitmarkPipe} from '../bitmark.pipe';
import {BitmarkFormat} from '../../shared/models/bitmark.models';
import {BaseBit} from '../bits.models';
import {LearningPathWithHeaderBaseBit} from './learning-path.models.common';
import {BitmarkTextContentService, UrlRewriteService} from '../../shared';
import {ReaderTipTapTapService} from '../../reader/tiptap/reader-tiptap.service';

export class LearningPathCommon {
  public imageExists = false;

  constructor(protected readerTipTapService: ReaderTipTapTapService,
              protected bitmarkTextContentService: BitmarkTextContentService) {
  }

  handleImage(bit: BaseBit) {
    this.imageExists = !!(bit.resource && bit.resource.image && bit.resource.image.src);
  }

  handleImageLoadError() {
    this.imageExists = false;
  }

  onUpdateContent(bit: any, bitJson: JSONContent) {
    bit.body = bitJson?.content;
    bit.format = BitmarkFormat.PP;
  }

  getBodyFormat(body: any, format: BitmarkFormat) {
    return this.bitmarkTextContentService.getBodyFormat(body, format);
  }

  getTextFromJson(json: any, format: BitmarkFormat): string {
    if (typeof json !== 'object') {
      return json;
    }

    const html = new BitmarkPipe(this.readerTipTapService, new UrlRewriteService()).transform(json, this.bitmarkTextContentService.getBodyFormat(json, format), 'html') as string;

    const element = document.createElement('DIV');
    element.innerHTML = html;

    return element.innerText || element.textContent;
  }

  trimHeader(bit: BaseBit) {
    if (bit.instruction) {
      bit.instruction = bit.instruction.trim();
    }
    if (bit.lead) {
      bit.lead = bit.lead.trim();
    }
    if (bit.item) {
      bit.item = bit.item.trim();
    }

    const bitWithHeader = bit as LearningPathWithHeaderBaseBit;
    if (bitWithHeader) {
      if (bitWithHeader.action) {
        bitWithHeader.action = bitWithHeader.action.trim();
      }

      if (bitWithHeader.location) {
        bitWithHeader.location = bitWithHeader.location.trim();
      }
    }
  }
}
