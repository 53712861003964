import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ClipboardService} from 'ngx-clipboard';
import {Location} from '@angular/common';
import {
  SubSink,
  Pagination,
  DropdownItemModel,
} from '../../../../shared';
import {BitBookApiFilter, BitbookApiService, BitBookSearchResults} from '../../../bitbook-api.service';
import {BitbookMqService} from '../../../bitbook-mq.service';
import {ReaderBasketService} from '../../../reader-basket.service';
import {BitApiWrapper, BitResource, BitType} from '../../../../bits/bits.models';
import {ReaderLocalContentService} from '../../../reader-local-content.service';
import {ReaderClipboardService} from '../../../reader-clipboard.service';
import {BitmarkConfig} from '../../../../bitmark.module';
import {ReaderContentService} from '../reader-content.service';
import {BookType, HandInStatus, ProductFamily} from '../../../../shared/models/bitmark.models';
import {TaughtClass, RolesApiService} from "../../../roles-api.service";

@Component({
  selector: 'bitmark-reader-content-search',
  templateUrl: './reader-content-search.component.html',
  styleUrls: ['./reader-content-search.component.scss'],
})
export class ReaderContentSearchComponent implements OnInit, OnDestroy {
  @Input() bookExternalId: string;
  @Input() isExternalSearch: boolean;
  @Input() externalLocation: string;
  @Input() isReadOnly = false;
  @Output() sendBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() sendBitsToClass = new EventEmitter<Array<BitApiWrapper>>();
  @Output() saveBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() showBitInBook = new EventEmitter<Array<BitApiWrapper>>();
  @Output() copyLinkToBit = new EventEmitter<BitApiWrapper>();
  @Output() isWorking = new EventEmitter<boolean>();
  @Output() fail = new EventEmitter<any>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }>();
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();
  @Output() closeBook = new EventEmitter<any>();

  BitType = BitType;

  searchResults: Array<BitApiWrapper>;
  bitActions: Array<DropdownItemModel>;
  searchQuery = '';
  activeFilters: BitBookApiFilter = {};
  activeFiltersApi: BitBookApiFilter = {};
  bitBookAvailableFilters: any = {};
  filterOriginalNames = {
    g: 'groups',
    quiz: 'quiz',
    rg: 'resourceGroups'
  };
  displayedActiveFiltersArr = [];
  pagination: Pagination = new Pagination(30);
  totalResults = 0;
  isLoadingBottom = false;
  isAtTheBottom = false;
  latestSearchId = '';
  isLoadingAdditionalContent = false;

  private sub = new SubSink();

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private bitBookApiService: BitbookApiService,
              private router: Router,
              private location: Location,
              private bitbookMqService: BitbookMqService,
              private activatedRoute: ActivatedRoute,
              private readerBasketService: ReaderBasketService,
              private readerLocalContentService: ReaderLocalContentService,
              private clipboardService: ClipboardService,
              private translate: TranslateService,
              private readerClipboard: ReaderClipboardService,
              private readerContentService: ReaderContentService,
              private rolesApiService: RolesApiService) {
  }

  ngOnInit() {
    this.rolesApiService.getTaughtClasses()
      .subscribe((classes: Array<TaughtClass>) => {
        this.bitActions = [{
          label: this.translate.instant('Reader.Basket.AddToBasket'),
          handler: this.addToBasket.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.SendContinue'),
          handler: this.sendBit.bind(this)
        }, classes?.length ? {
          label: this.translate.instant('Reader.Actions.SendToClass'),
          handler: this.sendBitToClass.bind(this)
        } : null, {
          label: this.translate.instant('Reader.Actions.SaveToNotebook'),
          handler: this.saveBit.bind(this)
        }, {id: 'reset-answer', isSeparator: true}, {
          id: 'reset-answer',
          label: this.translate.instant('Reader.Actions.ResetAnswer'),
          handler: this.resetAnswer.bind(this)
        }, {
          isSeparator: true,
          isHidden: !this.bitmarkConfig.isProUser,
        }, {
          label: 'Reader.Actions.CopyBitmarkType',
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkToClipboard.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.CopyBitmarkJSON'),
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkJsonToClipboard.bind(this)
        }, {
          label: 'Reader.Actions.BrandingPublisherTheme',
          isHidden: !this.bitmarkConfig.isProUser,
          isDisabled: true
        }, {isSeparator: true}, {
          label: this.translate.instant('Shared.Copy'),
          handler: this.copyBitToClipboard.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.CopyLinkToBit'),
          handler: this.copyBitLink.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.FindInBook'),
          handler: this.navigateReaderToBit.bind(this)
        }].filter(x => !!x);
      });

    this.setIsWorkingState(true);
    this.sub.sink = this.bitbookMqService.onSearchHistorySelected()
      .subscribe(this.searchFromHistory.bind(this));
    this.sub.sink = this.bitbookMqService.onSearchFiltersChanged()
      .subscribe(this.searchFilters.bind(this));
    this.sub.sink = this.bitbookMqService.onSearchQueryChanged()
      .subscribe(this.searchNormal.bind(this));
    this.searchQuery = this.activatedRoute.snapshot.queryParams.q;

    this.readerLocalContentService.getBookFilters(this.bookExternalId)
      .subscribe((bitBookFilters) => {
        Object.keys(bitBookFilters)?.forEach((k) => {
          if (Array.isArray(bitBookFilters[k])) {
            bitBookFilters[k] = bitBookFilters[k].filter((f) => f);
          }
        });
        this.bitBookAvailableFilters = bitBookFilters;

        const queryObj = this.getObjectFromQueryString();
        console.log(queryObj);
        // if (Object.keys(queryObj).length) {
        this.searchFilters(queryObj);
        // }

        setTimeout(() => {
          this.bitbookMqService.notifyReaderSearchFiltersInitialSet(queryObj);
        }, 100);
      });

    setTimeout(() => {
      this.setIsWorkingState(false);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setIsWorkingState(value: boolean) {
    this.isLoadingAdditionalContent = value;
    this.isWorking.emit(value);
    if(this.isReadOnly){
      this.setReaderAsHandedInReadonlyPartial();
    }
  }

  // private getObjectFromQueryString(): any {
  //   const queryParams = Object.assign({}, this.activatedRoute.snapshot.queryParams);
  //   delete queryParams.p;
  //   Object.keys(queryParams).forEach((k) => {
  //     if (typeof queryParams[k] === 'string') {
  //       queryParams[k] = [queryParams[k]];
  //     }
  //   });
  //   return queryParams;
  // }

  getObjectFromQueryString(): any {
    const queryParams: any = window.location.href.indexOf('?') !== -1 ? window.location.href.slice(window.location.href.indexOf('?') + 1).split('&') : [];
    const obj: any = {};

    queryParams?.forEach(item => {
      let [key, value] = item.split('=');
      key = key?.trim()?.replace(/"/g, ''); // remove quotes and trim spaces
      value = decodeURIComponent(value.trim().replace(/"/g, ''))

      if (obj.hasOwnProperty(key)) {
        if (value.indexOf(',') !== -1) {
          obj[key] = value.split(',');
        }
        if (Array.isArray(obj[key])) {
          obj[key].push(value);
        } else {
          obj[key] = [obj[key], value];
        }
      } else {
        if (value.indexOf(',') !== -1) {
          obj[key] = value.split(',');
        } else {
          obj[key] = [value];
        }
      }
    });

    console.log('reader content search query params: ', obj);
    delete obj.p;
    return obj;
  }

  private mergeUnique(arr1: string[], arr2: string[]): string[] {
    return arr1?.length && arr2?.length ? Array.from(new Set([...arr1, ...arr2])) : arr1?.length ? arr1 : arr2?.length ? arr2 : [];
  }

  private mergeTags(searchResult: { results: Array<BitApiWrapper> }) {
    if (searchResult?.results?.length > 0) {
      searchResult.results.forEach((b) => {
        b.tags = this.mergeUnique(b.tags, (b?.bit as any)?.tag || [])
      })
    }
  }

  private searchFilters(activeFiltersFromSidebar?: any) {
    this.setIsWorkingState(true);
    document.querySelector('.reader-content').scrollTop = 0;
    this.pagination.reset();
    this.displayedActiveFiltersArr = [];
    if (!activeFiltersFromSidebar) {
      activeFiltersFromSidebar = this.getObjectFromQueryString();
    }
    this.activeFilters = Object.assign({}, activeFiltersFromSidebar);
    Object.keys(activeFiltersFromSidebar)?.forEach((k) => {
      if (typeof activeFiltersFromSidebar[k] !== 'object') {
        activeFiltersFromSidebar[k] = [activeFiltersFromSidebar[k]];
      }
      if (typeof activeFiltersFromSidebar[k] === 'object') {
        activeFiltersFromSidebar[k]?.forEach((f) => {
          const propertyName = this.filterOriginalNames[k] ? this.filterOriginalNames[k] : k;
          if (this.bitBookAvailableFilters && this.bitBookAvailableFilters[propertyName]?.length) {
            const selectedAvailableFilter = this.bitBookAvailableFilters[propertyName].find((fil) => fil.name === f);
            if (selectedAvailableFilter) {
              this.displayedActiveFiltersArr.push({
                text: selectedAvailableFilter.text,
                name: selectedAvailableFilter.name,
                parent: k
              });
            }
          }
        });
      }
    });
    this.activeFiltersApi = Object.assign({}, activeFiltersFromSidebar, {
      bookId: this.bookExternalId
    });
    if (this.searchQuery?.length) {
      this.activeFiltersApi.q = this.searchQuery;
    } else {
      delete this.activeFiltersApi.q;
    }
    setTimeout(() => {
      this.createNewSearchId();
      if (this.isExternalSearch) {
        this.activeFiltersApi.location = this.externalLocation;
        this.bitBookApiService.searchAllBooks(this.activeFiltersApi)
          .subscribe((searchResult: BitBookSearchResults) => {
            this.bitbookMqService.notifyReaderSearchMade();
            this.mergeTags(searchResult);
            this.searchResults = searchResult.results;
            this.totalResults = searchResult.count;
            this.setIsWorkingState(false);
            this.isLoadingBottom = false;
          }, (err) => console.error(err));
        return;
      }
      this.bitBookApiService.searchBook(this.activeFiltersApi, this.latestSearchId)
        .subscribe((searchResult: BitBookSearchResults) => {
          if (searchResult.searchId === this.latestSearchId) {
            this.bitbookMqService.notifyReaderSearchMade();
            this.mergeTags(searchResult);
            this.searchResults = searchResult.results;
            this.totalResults = searchResult.count;
            this.setIsWorkingState(false);
            this.isLoadingBottom = false;
          }
        }, (err) => console.error(err));
    });

    const transformedFilters = {...activeFiltersFromSidebar};

    Object.keys(activeFiltersFromSidebar).forEach(key => {
      if (Array.isArray(activeFiltersFromSidebar[key])) {
        transformedFilters[key] = activeFiltersFromSidebar[key].join(',');
      }
    });

    this.location.replaceState(
      this.router.createUrlTree(
        [],
        {
          queryParams: {
            p: this.isExternalSearch ? null : 'search',
            ...transformedFilters,
            q: this.searchQuery
          }
        })
        .toString(),
    );
  }

  private searchNormal(searchQuery: string) {
    this.searchQuery = searchQuery;
    if (searchQuery) {
      this.readerLocalContentService.addSearchHistoryItem(this.bookExternalId, {
        searchQuery: searchQuery,
        activeFilters: this.activeFilters
      }).subscribe();
    }
    this.searchFilters();
  }

  private searchFromHistory(historyItem: { searchQuery: string, activeFilters: any }) {
    this.pagination.reset();
    this.setIsWorkingState(true);
    this.searchQuery = historyItem.searchQuery;
    this.location.replaceState(
      this.router.createUrlTree(
        [],
        {
          queryParams: {q: historyItem.searchQuery, p: this.isExternalSearch ? null : 'search'},
          queryParamsHandling: 'merge',
          fragment: null
        })
        .toString(),
    );
    this.createNewSearchId();
    this.bitBookApiService.searchBook(this.activeFiltersApi, this.latestSearchId)
      .subscribe((searchResult: BitBookSearchResults) => {
        if (searchResult.searchId === this.latestSearchId) {
          this.bitbookMqService.notifyReaderSearchMade();
          this.mergeTags(searchResult);
          this.totalResults = searchResult.count;
          this.searchResults = searchResult.results;
          this.setIsWorkingState(false);
          this.isLoadingBottom = false;
        }
      }, (err) => {
        console.error(err);
      });
  }

  getBitId(bit: BitApiWrapper) {
    return bit.updatedTimestamp ? `${bit.id}-${bit.updatedTimestamp}` : bit.id;
  }

  onScrollDown() {
    if (!this.searchResults?.length || this.searchResults?.length < 20 || this.isLoadingBottom) {
      return;
    }
    this.isLoadingBottom = true;
    this.getNextPage();
  }

  private getNextPage() {
    this.activeFilters.page = this.pagination.page;
    this.activeFiltersApi = Object.assign({}, this.activeFilters, {q: this.searchQuery, bookId: this.bookExternalId});
    this.bitBookApiService.searchBook(this.activeFiltersApi, this.latestSearchId)
      .subscribe((searchResult: BitBookSearchResults) => {
        this.totalResults = searchResult.count;
        this.mergeTags(searchResult);
        this.searchResults = this.searchResults ? this.searchResults.concat(searchResult.results) : searchResult.results;
        this.setIsWorkingState(false);
        this.isLoadingBottom = false;
        if (searchResult.results?.length) {
          this.pagination.increment();
        }
      }, (err) => {
        console.error(err);
      });
  }

  private resetAnswer(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerContentService.resetAnswer(bitId, this.searchResults)
      .subscribe();
  }

  private addToBasket(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerBasketService.addToBasket(bitId).subscribe();
  }

  private sendBit(dropdownItemModel: DropdownItemModel) {
    this.sendBits.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private sendBitToClass(dropdownItemModel: DropdownItemModel) {
    this.sendBitsToClass.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private saveBit(dropdownItemModel: DropdownItemModel) {
    this.saveBits.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private copyBitmarkToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    return this.clipboardService.copyFromContent(bitWrapper.bitmark || '');
  }

  private copyBitmarkJsonToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    return this.clipboardService.copyFromContent(JSON.stringify(bitWrapper.bit || ''));
  }

  private copyBitToClipboard(dropdownItemModel: DropdownItemModel) {
    return this.readerClipboard.copyToClipboard([{id: dropdownItemModel.data.id}]).subscribe();
  }

  private copyBitLink(dropdownItemModel: DropdownItemModel) {
    this.copyLinkToBit.emit(dropdownItemModel.data as BitApiWrapper);
  }

  private navigateReaderToBit(dropdownItemModel: DropdownItemModel) {
    const handInId = this.activatedRoute.snapshot.queryParams.handInId;
    if (this.isExternalSearch) {
      const bit = dropdownItemModel.data;
      if (bit.book.innerBookExternalId && bit?.id) {
        let url = `space/${this.bitmarkConfig.space}`;
        if (bit?.book?.innerBookType === BookType.Collection) {
          url += `/reader/${bit.book.innerBookExternalId}`;
        } else {
          url += `/reader/${bit.book.innerBookExternalId}`;
        }
        this.router.navigate([url], {
          fragment: `${bit.id}`,
          queryParams: {p: null, handInId},
          replaceUrl: false,
        });
      }
      return;
    }
    this.bitbookMqService.notifySearchSideBarVisibleSet(false);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      fragment: `${dropdownItemModel.data.id}`,
      queryParams: {p: null, handInId},
      replaceUrl: false
    });
    this.readerLocalContentService.storeLastSearch({
      bookId: this.bookExternalId,
      filters: this.activeFilters,
      searchQuery: this.searchQuery,
      searchResults: this.searchResults,
      offsetToTop: document.querySelector('.reader-content').scrollTop,
      count: this.totalResults,
      page: this.pagination.page
    }).subscribe();
    this.showBitInBook.emit();
  }

  // setActiveFilters(filters: any) {
  //   this.activeFilters = filters;
  //   // this.displayedActiveFiltersArr = [];
  //   this.activeFiltersApi = {};
  //   Object.keys(this.activeFilters).forEach((k) => {
  //     if (typeof this.activeFilters[k] === 'object' && this.activeFilters[k]?.length) {
  //       this.activeFiltersApi[k] = [];
  //       this.activeFilters[k].forEach((fil) => {
  //         if (typeof fil === 'string') {
  //           // this.displayedActiveFiltersArr.push({text: fil, name: fil, parent: k});
  //           this.activeFiltersApi[k].push(fil);
  //         } else if (k) {
  //           // this.displayedActiveFiltersArr.push({text: fil.text, name: fil.name, parent: k});
  //           this.activeFiltersApi[k].push(fil.name);
  //         }
  //       });
  //     } else if (typeof this.activeFilters[k] === 'string' || typeof this.activeFilters[k] === 'number') {
  //       this.activeFiltersApi[k] = this.activeFilters[k];
  //     }
  //   });
  // }

  private createNewSearchId() {
    // todo: can we use UUidService? - YES
    this.latestSearchId = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(0, 5);
  }

  removeFilter(f) {
    this.activeFilters[f.parent] = this.activeFilters[f.parent].filter((fi) => fi !== f.name);
    this.bitbookMqService.notifyReaderSearchFilterRemoved(f);
    delete this.activeFilters.bookId;
    delete this.activeFilters.q;
    this.searchFilters(this.activeFilters);
  }

  onOpenResource(bitResource: BitResource) {
    this.readerContentService.handleOpenResource(bitResource);
  }

  setReaderAsHandedInReadonlyPartial() {
    //reader is reaonly for expert - only personal annotation adding/editing allowed
    setTimeout(() => {
      const allInputs = document.querySelectorAll('.bits-wrapper input, .bits-wrapper textarea, .bits-wrapper select, .bits-wrapper .bit-dropdown, .bits-wrapper button');

      allInputs.forEach(function(input: any) {
        if (!input.closest('.annotation-body') && !input.closest('.hand-in-container')) {
          input.disabled = true;
        }
      });
    }, 500)
  }
}
