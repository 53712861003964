// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.release-note-container .release-note-main-content {
  flex: 1;
}
.release-note-container .release-note-date {
  font-family: var(--bitmark-release-note-date-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-release-note-date-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-release-note-date-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-release-note-date-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-release-note-date-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-release-note-date-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-release-note-date-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-release-note-date-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-release-note-date-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-release-note-date-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.release-note-container .release-note-instruction {
  font-family: var(--bitmark-release-note-instruction-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-release-note-instruction-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-release-note-instruction-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-release-note-instruction-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-release-note-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-release-note-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-release-note-instruction-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-release-note-instruction-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-release-note-instruction-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-release-note-instruction-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.release-note-container .separator-vertical {
  width: 2px;
  background-color: var(--bitmark-release-note-separator-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-release-note-separator-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-release-note-separator-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-release-note-separator-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-release-note-separator-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-release-note-separator-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-release-note-separator-background-animation-name) var(--bitmark-release-note-separator-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-release-note-separator-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-release-note-separator-background-backdrop-filter);
}
.release-note-container .release-note-kind {
  padding: var(--bitmark-release-note-kind-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-release-note-kind-size-margin, var(--bitmark-x-size-margin, 0));
  font-family: var(--bitmark-release-note-kind-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-release-note-kind-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-release-note-kind-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-release-note-kind-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-release-note-kind-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-release-note-kind-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-release-note-kind-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-release-note-kind-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-release-note-kind-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-release-note-kind-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  background-color: var(--bitmark-release-note-kind-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-release-note-kind-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-release-note-kind-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-release-note-kind-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-release-note-kind-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-release-note-kind-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-release-note-kind-background-animation-name) var(--bitmark-release-note-kind-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-release-note-kind-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-release-note-kind-background-backdrop-filter);
  border-top: var(--bitmark-release-note-kind-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-release-note-kind-border-style, var(--bitmark-x-border-style)) var(--bitmark-release-note-kind-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-release-note-kind-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-release-note-kind-border-style, var(--bitmark-x-border-style)) var(--bitmark-release-note-kind-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-release-note-kind-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-release-note-kind-border-style, var(--bitmark-x-border-style)) var(--bitmark-release-note-kind-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-release-note-kind-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-release-note-kind-border-style, var(--bitmark-x-border-style)) var(--bitmark-release-note-kind-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-release-note-kind-border-radius, var(--bitmark-x-border-radius, y));
}
.release-note-container .release-note-version {
  padding: var(--bitmark-release-note-version-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-release-note-version-size-margin, var(--bitmark-x-size-margin, 0));
  font-family: var(--bitmark-release-note-version-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family))) !important;
  font-size: var(--bitmark-release-note-version-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size))) !important;
  font-weight: var(--bitmark-release-note-version-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight))) !important;
  font-style: var(--bitmark-release-note-version-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style))) !important;
  -webkit-text-decoration: var(--bitmark-release-note-version-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration))) !important;
          text-decoration: var(--bitmark-release-note-version-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration))) !important;
  line-height: var(--bitmark-release-note-version-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height))) !important;
  color: var(--bitmark-release-note-version-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color))) !important;
  text-align: var(--bitmark-release-note-version-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left))) !important;
  justify-content: var(--bitmark-release-note-version-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left))) !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
