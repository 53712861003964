import {BitType} from '../bits/bits.models';
import {
  BitmarkFormat,
  BookType,
  NotebookTypes,
  BookEntityToc,
  BitmarkBookTypes,
  NotebookCoverType,
  HandInStatus
} from '../shared/models/bitmark.models';

export enum ReaderModes {
  Book = 'book',
  Notebook = 'notebook',
  Search = 'search',
  Shop = 'shop',
  ShopReadonly = 'shop-readonly',
  ShopSection = 'shop-section',
  ShopSectionReadonly = 'shop-section-readonly',
  SelfLearningReadonly = 'self-learning-readonly',
  ShopPublic = 'shop-public',
  NewRelease = 'new-release',
  LearningPaths = 'learning-paths',
  NotebookLearningPath = 'notebook-learning-path',
  DraftBook = 'draft-book',
  Flashcards = 'flashcards'
}

export interface BookEntityAuthor {
  name?: string;
  role?: string;
}

export interface BookEntityPublisher {
  id?: number;
  name?: string;
  code?: string;
}

export interface HandInData {
  id?: number,
  book?: BookEntity,
  bookId?: string,
  handedInAt?: any,
  status?: HandInStatus,
  mainExpertId?: number,
  studentId?: number,
  student?: any,
  mainExpert?: any,
  deadline?: Date,
  createdAt?: Date,
  returnedAt?: Date
  lastUpdatedAt?: Date
}

export interface BookEntity {
  id?: string;
  title?: string;
  subtitle?: string;
  seriesTitle?: string;
  seriesId?: string;
  numberInSeries?: number;
  format?: string;
  type?: BookType;
  coverImage?: {
    src1x: string;
    src2x: string;
    src3x: string;
    width: number;
    height: number;
  };
  coverColor?: string;
  mainLanguage?: string;
  mainLearningLanguage?: string;
  lang?: string;
  isbn13?: string;
  description?: string;
  printPageCount?: number;
  tocLevel?: number;
  toc?: Array<BookEntityToc>;
  progress?: number;
  rating?: number;
  summary?: {
    levelCefr?: Array<string>;
    publisher?: {
      name?: string;
    };
  };
  compatibility?: {
    type?: Array<BitType>;
    imageResolutions?: Array<string>;
    textCommonDenominator?: BitmarkFormat;
    imageResolutionsCommonDenominator?: Array<'1x' | '2x' | '3x'>;
  };
  system?: {
    parser?: string;
    creationDate?: string;
    parserVersion?: string;
    bitBookVersion?: string;
  };
  course?: {
    id?: number;
    externalId?: string;
    description?: string;
    title?: string;
    trainingId?: string;
    type?: string;
  }
  createdAt?: string;
  lastUpdatedAt?: string;
  releaseDate?: string;
  version?: number;
  publisherId?: number;
  isLiveBook?: number;
  isSmartBook?: boolean;
  isHighQuality?: boolean;
  shopUrl?: string;
  publishingDate?: string;
  publisherArticleNr?: string;
  publisherCode?: string;
  createdBy?: number;
  externalId?: string;
  subtype?: NotebookTypes;
  authors?: Array<BookEntityAuthor>;
  publisher?: BookEntityPublisher;
  publications?: Array<string>;
  bitmarkLink?: string;
  isPublic?: boolean;
  openCodeUrl?: string;

  hasMarkAsDone?: boolean;
  filters?: Array<any>;
  capital?: string;
  color?: string;
  branding?: {
    primaryColor?: string;
    secondaryColor?: string;
    coverType?: NotebookCoverType;
  };
  handIn?: HandInData;
  processHandIn?: boolean;
  isHandInCurrentUserStudent?: boolean;
  sharedByUser?: string;
  isGenerated?: boolean;
  isEditing?: boolean;
  isNewRelease?: boolean;
  isWide?: boolean;
  theme?: string;
  spaceId?: string;
  meta?: {
    subject?: string;
    publisherId?: number;
    publisher?: BookEntityPublisher;
    releaseDate?: string;
    version?: string;
  }
  clientMeta?: {
    isAlreadyOwned?: boolean;
  };
}

export interface BookCoverEntity {
  id: number;
  externalId: string;
  title: string;
  subtitle?: string;
  type: string;
  subtype?: BitmarkBookTypes;
  coverImage: string;
  coverColor: string;
  format?: string;
}

export interface BookConfiguration {
  ignoreStartBit?: boolean;
  displayAddArticleLikeBitsMenu?: boolean;
}

export interface BitmarkPagination {
  pageNumber: number;
  pageSize: number;
  startBitId: any;
}

export type ReaderContext =
  'book'
  | 'notebook'
  | 'draft-book'
  | 'search'
  | 'basket'
  | 'timeline'
  | 'training'
  | 'editor'
  | 'new-release'
  | 'bin'
  | 'shop'
  | 'shop-readonly'
  | 'shop-section'
  | 'shop-section-readonly'
  | 'self-learning'
  | 'self-learning-readonly';
