import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {of} from 'rxjs';
import {environment} from 'environment';
import {IdentityService} from './identity.service';
import {ApiService} from '../api/api.service';
import {InternetConnectionService} from '../internet-connection/internet-connection.service';
import {SocketIoService, SocketMessageType} from '../websocket/socketio.service';
import {BaseUser} from 'core';

@Injectable({providedIn: 'root'})
export class UserPresenceService {

  private heartbeatHandler: any;

  constructor(private router: Router,
              private socketIoService: SocketIoService,
              private identityService: IdentityService,
              private apiService: ApiService,
              private internetConnection: InternetConnectionService) {
  }

  notifyCurrentAvailabilityStatus() {
    const me = this.identityService.user;
    if (!me) {
      return;
    }
    this.socketIoService.emit('timeline', {
      peerUserId: 0,
      messageType: SocketMessageType.UserAvailability,
      payload: me.isAvailable || false
    });
  }

  notifyUnavailable() {
    if (!this.identityService.user || !this.socketIoService.isConnected()) {
      return;
    }
    this.socketIoService.emit('timeline', {
      peerUserId: 0,
      messageType: SocketMessageType.UserAvailability,
      payload: false
    });
  }

  startHeartbeat() {
    if (this.heartbeatHandler) {
      return;
    }
    this.heartbeatHandler = setInterval(() => {
      if (!this.identityService.user || !this.internetConnection.isConnected()) {
        return;
      }
      this.socketIoService.emit('timeline', {messageType: SocketMessageType.UserHeartbeat});
    }, environment.heartBeatInterval);
  }

  getPulse(user: BaseUser) {
    if (user.botId) {
      return of({isActive: true});
    }
    return this.apiService.get('account/get-pulse/{userId}', {userId: user.id});
  }
}

