import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {ClipboardService} from 'ngx-clipboard';
import {concatMap} from 'rxjs/operators';
import {DeviceDetectorService} from 'ngx-device-detector';
import {highlightRange, removeHighlights} from '@funktechno/texthighlighter/lib';
import {normalizeHighlights} from '@funktechno/texthighlighter/lib/Library';
import {DebounceService, DropdownItemModel, EventEmitter as EE, SubSink} from '../../../../shared';
import {BitmarkPagination, BookEntity} from '../../../reader.models';
import {BitbookApiService} from '../../../bitbook-api.service';
import {BitbookMqService} from '../../../bitbook-mq.service';
import {BookPosition} from '../../../reader-tracking.service';
import {ReaderTocService} from '../../../reader-toc.service';
import {ReaderBasketService} from '../../../reader-basket.service';
import {BaseBit, BitApiAnnotation, BitApiWrapper, BitResource, BitType} from '../../../../bits/bits.models';
import {ReaderClipboardService} from '../../../reader-clipboard.service';
import {BitmarkConfig} from '../../../../bitmark.module';
import {ReaderContentService} from '../reader-content.service';
import {AnnotationNoteBit} from '../../../../bits/annotations/annotation-note/annotation-note.models';
import {AnnotationBookmarkBit} from '../../../../bits/annotations/annotation-bookmark/annotation-bookmark.models';
import {
  BitAnnotationDefaultColor,
  BitmarkFormat,
  DefaultBitAnnotationColors,
  HandInStatus,
  ProductFamily
} from '../../../../shared/models';
import {AnnotationBaseBit} from '../../../../bits/annotations/annotation-base.models';
import {BitmarkReversePipe} from '../../../../bits';
import {TocItem} from '../../reader-toc-sidebar/reader-toc-sidebar.component';
import {ReaderTipTapTapService} from '../../../tiptap/reader-tiptap.service';
import {InternalLinkBit} from '../../../../bits/internal-link/internal-link.models';
import {ReaderTextEditorComponent} from '../../../reader-editor/reader-text-editor/reader-text-editor.component';
import {
  ReaderSimpleTextEditorComponent
} from '../../../reader-editor/reader-simple-text-editor/reader-simple-text-editor.component';
import {DomUtilsService} from '../../../../shared/dom/dom-utils.service';
import {memoize} from '../../../../shared/decorators/memoize.decorator';
import {DomObserverService} from '../../../../shared/dom/dom-observer.service';
import {VirtualMarkBookAsReadBit} from '../../../../bits/virtual-mark-book-as-read/virtual-mark-book-as-read.models';
import {RolesApiService, TaughtClass} from '../../../roles-api.service';

@Component({
  selector: 'bitmark-reader-content-book',
  templateUrl: './reader-content-book.component.html',
  styleUrls: ['../../../reader-common.scss']
})
export class ReaderContentBookComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() bitBook: BookEntity;
  @Input() fontScale?: 80 | 100 | 110 | 120 | 140 = null;
  @Output() sendBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() sendBitsToClass = new EventEmitter<Array<BitApiWrapper>>();
  @Output() saveBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() copyLinkToBit = new EventEmitter<BitApiWrapper>();
  @Output() toggleToc = new EventEmitter<any>();
  @Output() isWorking = new EventEmitter<boolean>();
  @Output() fail = new EventEmitter<any>();
  @Output() assignHandIn = new EventEmitter<{ handInId: number, isSelfAssign?: boolean }>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }>();
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();
  @Output() closeBook = new EventEmitter<any>();
  @ViewChild('annotationsMenuTemplate') annotationsMenuTemplate: ElementRef;
  @ViewChild('annotationsMenuHighlightTemplate') annotationsMenuHighlightTemplate: ElementRef;
  @ViewChild('annotationsMenuPopup') annotationsMenuPopup: ElementRef;

  BitType = BitType;
  bitbookContentVersion: number;

  //blocks editing anything
  isReadOnlyReaderStudent = false;
  isAtTheTop = false;
  isAtTheBottom = false;
  bitBookContent: Array<BitApiWrapper>;
  bitActions: Array<DropdownItemModel>;
  handInReviewActions: Array<DropdownItemModel>;
  bitAnnotationActions: Array<DropdownItemModel>;
  private annotationMenuInstance: any = null;
  private annotationMenuHighlightInstance: any = null;
  isLoading = true;
  isLoadingAdditionalContent = false;
  isLoadingAdditionalContentTop = false;
  isLoadingAdditionalContentBottom = false;
  pageSize = 50;
  private pagination: BitmarkPagination = {
    pageSize: 20,
    pageNumber: 1,
    startBitId: null
  };
  shouldProtectAgainstUserCopyTimeouts: Array<NodeJS.Timeout> = [];
  shouldProtectAgainstUserCopy = false;
  virtualHandInBit: any;
  private scrollUnlisten;
  private scrollEndUnlisten;
  private mouseUpEventBound = null;
  private sub = new SubSink();
  private subImgClicked: string;
  private subBitmarkChanged: string;
  private subInternalLinkClicked: string;
  private subCrossLinkClicked: string;
  focusedBitId: string = null;
  private isBrowserSafari: boolean = null;

  @HostListener('window:popstate', ['$event'])
  // this is used when using the browser back button after selecting bits in toc/using internal link
  onPopState() {
    if (!window.location.pathname.startsWith(`/space/${this.bitmarkConfig.space}/reader`)) {
      return;
    }
    const fragment = decodeURIComponent(window.location.hash).replace('#', '');
    this.setIsWorkingState(true);
    console.log('rendering page for ', fragment);
    this.renderPage(fragment, true);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.debounceService.debounce(500, () => {
      this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
    });
  }

  @HostListener('document:gmbHandInReview', ['$event'])
  onHandInReview(event: any) {
    if (!event?.detail?.userId) {
      return;
    }
    this.setReaderAsHandedInReadonlyFull();
  }

  constructor(@Inject('BitmarkConfig') protected bitmarkConfig: BitmarkConfig,
              private bitBookApiService: BitbookApiService,
              private bitbookMqService: BitbookMqService,
              private readerTocService: ReaderTocService,
              private translate: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private ngZone: NgZone,
              private readerBasketService: ReaderBasketService,
              private readerClipboard: ReaderClipboardService,
              private readerContentService: ReaderContentService,
              private readerTipTapService: ReaderTipTapTapService,
              private clipboardService: ClipboardService,
              private domUtilsService: DomUtilsService,
              private domObserverService: DomObserverService,
              private debounceService: DebounceService,
              private rolesApiService: RolesApiService,
              protected deviceDetectorService: DeviceDetectorService) {
  }

  ngOnInit() {
    // this.bitBook.processHandIn = true;
    this.rolesApiService.getTaughtClasses()
      .subscribe((classes: Array<TaughtClass>) => {
        this.bitActions = [{
          label: this.translate.instant('Reader.Basket.AddToBasket'),
          handler: this.addToBasket.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.SendContinue'),
          handler: this.sendBit.bind(this)
        }, classes?.length ? {
          label: this.translate.instant('Reader.Actions.SendToClass'),
          handler: this.sendBitToClass.bind(this)
        } : null, {
          label: this.translate.instant('Reader.Actions.SaveToNotebook'),
          handler: this.saveBit.bind(this)
        }, {id: 'reset-answer', isSeparator: true}, {
          id: 'reset-answer',
          label: this.translate.instant('Reader.Actions.ResetAnswer'),
          handler: this.resetAnswer.bind(this)
        }, {id: 'reset-highlights', isSeparator: true}, {
          id: 'reset-highlights',
          label: this.translate.instant('Reader.Actions.ResetHighlights'),
          handler: this.resetHighlights.bind(this)
        }, {
          isSeparator: true,
          isHidden: !this.bitmarkConfig.isProUser,
        }, {
          label: 'Reader.Actions.CopyBitmarkType',
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkToClipboard.bind(this)
        }, {
          id: 'reset-book-rating',
          label: this.translate.instant('Reader.Actions.ResetBookRating'),
          handler: this.resetBookRating.bind(this),
          isHidden: true
        }, {
          label: this.translate.instant('Reader.Actions.CopyBitmarkJSON'),
          isHidden: !this.bitmarkConfig.isProUser,
          handler: this.copyBitmarkJsonToClipboard.bind(this)
        }, {
          label: 'Reader.Actions.BrandingPublisherTheme',
          isHidden: !this.bitmarkConfig.isProUser,
          isDisabled: true
        }, {
          isSeparator: true,
          // isHidden: this.bitmarkConfig.productionEnvironment
        }, {
          label: this.translate.instant('Reader.Actions.AddBookmark'),
          handler: this.createAnnotationBookmarkOnBit.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.AddFavorite'),
          handler: this.createAnnotationFavoriteOnBit.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.AddNote'),
          handler: this.createAnnotationNoteOnBit.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.AddHandwritten'),
          handler: this.createAnnotationHandwrittenOnBit.bind(this)
        }, {isSeparator: true}, {
          label: this.translate.instant('Shared.Copy'),
          handler: this.copyBitToClipboard.bind(this)
        }, {
          label: this.translate.instant('Reader.Actions.CopyLinkToBit'),
          handler: this.copyBitLink.bind(this)
        }].filter(x => !!x);
      });

    this.handInReviewActions = [{
      label: this.translate.instant('HandIn.Assign'),
      handler: this.assignToSomeoneElse.bind(this)
    }];

    this.bitAnnotationActions = [{
      id: 'color',
      colors: DefaultBitAnnotationColors,
      handler: this.selectAnnotationColor.bind(this)
    }, {isSeparator: true}, {
      label: this.translate.instant('Shared.Delete'),
      handler: this.deleteAnnotation.bind(this)
    }];

    this.sub.sink = this.bitbookMqService.onReaderBitSelected()
      .subscribe(this.handleBitSelected.bind(this));
    this.sub.sink = this.bitbookMqService.onInternalLinkClicked()
      .subscribe(this.handleInternalLink.bind(this));

    this.subImgClicked = EE.default.on('imgClicked', (x: BitResource) => this.ngZone.run(() => this.onOpenResource(x)));
    this.subBitmarkChanged = EE.default.on('bitmark-changed', (props: { id: string, data: any }) => {
      this.ngZone.run(() => {
        this.saveBitBitmark(props.id, props.data);
      });
    });
    this.subInternalLinkClicked = EE.default.on('internalLinkClicked', ({reference, bitId}: {reference: string, bitId: string}) => {
      this.ngZone.run(() => {
        this.navigateToBitReference(reference, bitId);
      });
    });
    this.subCrossLinkClicked = EE.default.on('crossRefLinkClicked', ({bookId, reference}: {bookId: string, reference: string}) => {
      this.ngZone.run(() => {
        this.navigateToBookBitReference(bookId, reference);
      });
    });

    this.sub.sink = this.bitbookMqService.onBookIsHandedInReadonly().subscribe((bookId) => {
      if (this.bitBook?.externalId === bookId) {
        console.log('setting reader as readonly');
        this.setReaderAsHandedInReadonlyFull();
        this.setReaderHandInBitForStudent();
      }
    });

    this.sub.sink = this.bitbookMqService.onHandInIsAssigned()
      .subscribe((data) => {
        if(data?.expertUser?.email === this.bitmarkConfig?.userEmails?.email || data?.expertUser?.ssoEmail === this.bitmarkConfig?.userEmails?.ssoEmail){
          //current user is assigned, allow creating/editing of personal annotations
          this.isReadOnlyReaderStudent = false;
          this.setReaderAsHandedInReadonlyPartial();
        } else{
          //someone else is assign, don't allow current user to edit anything
          this.isReadOnlyReaderStudent = true;
        }
      });

    this.renderPage(this.route.snapshot.fragment);
  }

  ngOnDestroy() {
    if (this.scrollUnlisten) {
      this.scrollUnlisten();
    }
    if (this.scrollEndUnlisten) {
      this.scrollEndUnlisten();
    }
    this.sub.unsubscribe();
    if (this.mouseUpEventBound) {
      document.removeEventListener('mouseup', this.mouseUpEventBound);
    }
    EE.default.clear(this.subImgClicked);
    EE.default.clear(this.subBitmarkChanged);
    EE.default.clear(this.subInternalLinkClicked);
    EE.default.clear(this.subCrossLinkClicked);
  }

  renderPage(fragment?: string, refreshToc?: boolean) {
    this.isLoading = true;
    this.setIsWorkingState(true);
    const handInId = this.route.snapshot.queryParams.handInId;
    const queryParams = {};
    if (handInId) {
      queryParams['handInId'] = handInId;
    }
    this.bitBook.isHandInCurrentUserStudent = this.bitBook.processHandIn && this.bitBook?.handIn?.student?.email && (this.bitmarkConfig.userEmails?.email === this.bitBook?.handIn?.student?.email ||
      this.bitmarkConfig.userEmails?.ssoEmail === this.bitBook?.handIn?.student?.email);

    this.readerContentService.loadContent(this.bitBook, this.pagination, fragment, null, null, queryParams)
      .pipe(concatMap((res: {
        bitBookContent: Array<BitApiWrapper>,
        lastPos: BookPosition,
        isAtTop: boolean,
        isAtBottom: boolean
      }) => {
        const scrollObs = this.readerContentService.scrollToLastPosition(res.bitBookContent, res.lastPos);

        this.bitBookContent = res.bitBookContent;

        if (this.bitBook?.handIn?.status && this.bitBook.isHandInCurrentUserStudent &&
          this.bitBook?.handIn?.status !== HandInStatus.Rejected) {
          //set reader readonly if author of hand-in is trying to open the book, but not if expert opened the book
          this.setReaderAsHandedInReadonlyFull();
          this.setReaderHandInBitForStudent();
        }
        //show student hand in if not yet handed in or if current user is student and status is rejected
        if (this.bitBook.processHandIn && (!this.bitBook?.handIn || (this.bitBook?.handIn?.status === HandInStatus.Rejected && this.bitBook?.isHandInCurrentUserStudent))) {
          this.setInitialReaderHandInBitForStudent();
        }
        this.setReaderAsHandedInReadonlyIfNotAssigned();

        this.isAtTheTop = res.isAtTop;
        this.isAtTheBottom = res.isAtBottom;
        this.bitbookContentVersion = new Date().getTime();

        return scrollObs;
      }))
      .subscribe(() => {
        this.scrollUnlisten = this.readerContentService.listenToScroll(this.bitBook, () => {
          this.toggleUserCopyProtection(false);
        }, () => this.bitBookContent);
        this.scrollEndUnlisten = this.readerContentService.listenToScrollEnd(this.bitBook, () => {
          const timeoutId = setTimeout(() => {
            this.toggleUserCopyProtection(true);
          }, 2000);
          this.shouldProtectAgainstUserCopyTimeouts.push(timeoutId);
        }, () => this.bitBookContent);
        setTimeout(() => {
          if (refreshToc && fragment) {
            this.bitbookMqService.notifyRefreshToc({id: this.bitBook.id, shouldInitializeBitVisibility: true});
          }
          this.readerContentService.computeAndNotifyBitsScrollVisibility(() => this.bitBookContent);
        }, 250);
        this.isLoading = false;
        this.setIsWorkingState(false);
      }, (err: HttpErrorResponse) => {
        this.bitBookContent = [];
        this.isLoading = false;
        this.setIsWorkingState(false);
        this.fail.emit(err);
      });
  }

  ngAfterViewInit() {
    const template = this.annotationsMenuTemplate.nativeElement;
    const highlightTemplate = this.annotationsMenuHighlightTemplate.nativeElement;
    this.annotationMenuInstance = template;
    this.annotationMenuHighlightInstance = highlightTemplate;

    this.mouseUpEventBound = this.handleMouseUp.bind(this, template, highlightTemplate);
    document.addEventListener('mouseup', this.mouseUpEventBound);

    this.initializeUserCopyProtection();
  }

  protected initializeUserCopyProtection(shouldComputeVisibility = true) {
    if (!this.isUserProtectionEnabled()) {
      return;
    }

    this.shouldProtectAgainstUserCopy = false;

    if (shouldComputeVisibility) {
      this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent, 2000);
    }

    setTimeout(() => {
      this.shouldProtectAgainstUserCopy = true;
    }, 3000);
  }

  private toggleUserCopyProtection(enabled: boolean) {
    if (!this.isUserProtectionEnabled()) {
      this.shouldProtectAgainstUserCopyTimeouts = [];
      return;
    }
    if (this.shouldProtectAgainstUserCopyTimeouts?.length) {
      this.shouldProtectAgainstUserCopyTimeouts.forEach(tid => clearTimeout(tid));
      this.shouldProtectAgainstUserCopyTimeouts = [];
    }
    this.shouldProtectAgainstUserCopy = enabled;
  }

  private isUserProtectionEnabled(): boolean {
    if (this.isBrowserSafari === null) {
      this.isBrowserSafari = this.deviceDetectorService.getDeviceInfo()?.browser?.toLowerCase() === 'safari';
    }

    return !(this.isBrowserSafari || this.bitmarkConfig.space !== 'swisslife');
  }

  private handleMouseUp(template: any, highlightTemplate: any, event: MouseEvent) {
    this.handleAnnotationsMouseUp(template, highlightTemplate);
    this.handleFocusBitMouseUp(event);
  }

  private handleFocusBitMouseUp(event: MouseEvent) {
    const targetNode: any = event.target;

    if (!targetNode) {
      this.focusedBitId = null;
      return;
    }

    const bitWrapper = targetNode?.closest('.bit-wrapper1');
    this.focusedBitId = bitWrapper?.id?.replace('bit-', '') || null;
  }

  private handleAnnotationsMouseUp(template: any, highlightTemplate: any) {
    if (this.annotationMenuInstance.style.display !== 'none') {
      this.annotationMenuInstance.style.display = 'none';
    }
    if (this.annotationMenuHighlightInstance.style.display !== 'none') {
      this.annotationMenuHighlightInstance.style.display = 'none';
    }
    if (window.getSelection().toString().length) {
      if (this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'reader-text-editor')) {
        console.log('is in tiptap editor');
      } else if (this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-wrapper1')) {
        const shouldShow = !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-type-bit-book-summary') &&
          !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-type-annotation-bookmark') &&
          !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-type-bit-book-ending') &&
          !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-type-virtual-mark-book-as-read') &&
          !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'disable-annotations');
        if (shouldShow) {
          const selection = window.getSelection();
          if (!selection.isCollapsed) {
            const range = selection.getRangeAt(0);
            const {left, top} = range.getBoundingClientRect();
            template.style.left = `${left}px`;
            template.style.top = `${document.querySelector('.infinite-scroll-container').scrollTop + top}px`;
            template.style.width = `auto`;
            template.style.display = 'block';

            this.readerContentService.handleAnnotationMenuPopupPosition(template, this.annotationsMenuPopup.nativeElement);

            const shouldShowHighlights = this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bit-body-allow-highlight') &&
              this.elementOrAncestorHasClass(window.getSelection().focusNode, 'bit-body-allow-highlight') &&
              !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'tiptap-image-copyright') &&
              !this.elementOrAncestorHasClass(window.getSelection().focusNode, 'tiptap-image-copyright') &&
              !this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'tiptap-image-caption') &&
              !this.elementOrAncestorHasClass(window.getSelection().focusNode, 'tiptap-image-caption');
            highlightTemplate.style.display = shouldShowHighlights ? 'block' : 'none';
          }
        }
      }
    }
  }

  createAnnotationNoteFromId() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const bitId = bitElement.id.split('-')[1];
      const bitWrapper = this.bitBookContent.find((b) => (b.id as any) === +bitId);
      this.createAnnotationNoteOnBit({data: bitWrapper}, window.getSelection().toString()?.trim());
    }
  }

  createAnnotationHandwrittenFromId() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const bitId = bitElement.id.split('-')[1];
      const bitWrapper = this.bitBookContent.find((b) => (b.id as any) === +bitId);
      this.createAnnotationHandwrittenOnBit({data: bitWrapper}, window.getSelection().toString()?.trim());
    }
  }

  createAnnotationBookmarkFromId() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const bitId = bitElement.id.split('-')[1];
      const bitWrapper = this.bitBookContent.find((b) => (b.id as any) === +bitId);
      this.createAnnotationBookmarkOnBit({data: bitWrapper}, window.getSelection().toString()?.trim());
    }
  }

  createAnnotationFavoriteFromId() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const bitId = bitElement.id.split('-')[1];
      const bitWrapper = this.bitBookContent.find((b) => (b.id as any) === +bitId);
      this.createAnnotationFavoriteOnBit({data: bitWrapper}, window.getSelection().toString()?.trim());
    }
  }

  setReaderHandInBitForExpert() {
    (this.virtualHandInBit as any) = {
      id: 'hand-in-absolute',
      bit: {
        id: 'hand-in-absolute',
        bookId: this.bitBook?.externalId,
        handIn: this.bitBook?.handIn,
        type: BitType.VirtualHandInReview,
        hint: this.bitBook?.type,
        lang: this.bitBook?.lang || this.bitBook?.mainLanguage
      },
      meta: {
        publisherId: 'system',
        themeId: 'hand-in',
        thisBook: {theme: 'hand-in'},
        originBook: null
      }
    };
  }

  setInitialReaderHandInBitForStudent() {
    if (this?.bitBook?.handIn && !this?.bitBook?.handIn?.createdAt) {
      this.bitBook.handIn.createdAt = new Date();
    }
    (this.virtualHandInBit as any) = {
      id: 'hand-in-absolute',
      bit: {
        id: 'hand-in-absolute',
        bookId: this.bitBook?.externalId,
        handIn: this.bitBook?.handIn,
        type: BitType.VirtualHandIn,
        hint: this.bitBook?.type,
        lang: this.bitBook?.lang || this.bitBook?.mainLanguage
      },
      meta: {
        publisherId: 'system',
        themeId: 'hand-in',
        thisBook: {theme: 'hand-in'},
        originBook: null
      }
    };
  }

  setReaderHandInBitForStudent() {
    if (this?.bitBook?.handIn && !this?.bitBook?.handIn?.createdAt) {
      this.bitBook.handIn.createdAt = new Date();
    }
    (this.virtualHandInBit as any) = {
      id: 'hand-in-absolute',
      bit: {
        id: 'hand-in-absolute',
        bookId: this.bitBook?.externalId,
        handIn: Object.assign({}, this.bitBook?.handIn, !this.bitBook?.handIn?.status ? {status: 'PENDING'} : {status: this.bitBook?.handIn?.status}),
        type: BitType.VirtualHandIn,
        hint: this.bitBook?.type,
        lang: this.bitBook?.lang || this.bitBook?.mainLanguage
      },
      meta: {
        publisherId: 'system',
        themeId: 'hand-in',
        thisBook: {theme: 'hand-in'},
        originBook: null
      }
    };
  }

  setReaderAsHandedInReadonlyFull() {
    //no editing allowed at all, for student or unassigned expert
    this.isReadOnlyReaderStudent = true;
  }

  setReaderAsHandedInReadonlyPartial() {
    //reader is reaonly for expert - only personal annotation adding/editing allowed
    setTimeout(() => {
      const allInputs = document.querySelectorAll('.bits-wrapper input, .bits-wrapper textarea, .bits-wrapper select, .bits-wrapper .bit-dropdown, .bits-wrapper button');

      allInputs.forEach(function(input: any) {
        if (!input.closest('.annotation-body') && !input.closest('.hand-in-container')) {
          input.disabled = true;
        }
      });
    }, 500)
  }

  setReaderAsHandedInReadonlyIfNotAssigned() {
    if (this.bitBook?.handIn?.status && !this.bitBook.isHandInCurrentUserStudent) {
      this.setReaderHandInBitForExpert();
      // only allow annotations adding/editing if current user is expert
      this.setReaderAsHandedInReadonlyPartial();
      // if hand in is rejected, do not allow expert to edit anything
      if((this.bitBook?.handIn?.status === HandInStatus.Rejected || this.bitBook?.handIn?.status === HandInStatus.Approved) && !this.bitBook.isHandInCurrentUserStudent){
        this.setReaderAsHandedInReadonlyFull();
      }
      if(this.bitBook?.handIn?.status === HandInStatus.HandedIn ||
        (this.bitBook?.handIn?.status === HandInStatus.Assigned &&
          this.bitBook?.handIn?.mainExpert?.email !== this.bitmarkConfig?.userEmails?.email &&
          this.bitBook?.handIn?.mainExpert?.email !== this.bitmarkConfig?.userEmails?.ssoEmail)){
        //also disable annotations if hand in is not assigned or current user not the assigned expert
        this.setReaderAsHandedInReadonlyFull();
      }
    }
  }

  assignToSomeoneElse(){
    this.assignHandIn.emit({
      handInId: this.bitBook?.handIn?.id,
      isSelfAssign: false
    });
  }

  getParentBitElement(element) {
    if (!element || element.length === 0) {
      return null;
    }
    let parent = element;
    do {
      if (parent === document) {
        break;
      }
      if (parent.className?.indexOf('bit-wrapper1') >= 0) {
        return parent;
      }
    } while (parent = parent.parentNode);
    return false;
  }

  elementOrAncestorHasClass(element, className) {
    if (!element || element.length === 0) {
      return false;
    }
    let parent = element;
    do {
      if (parent === document) {
        break;
      }
      if (parent.className?.indexOf(className) >= 0) {
        return true;
      }
    } while (parent = parent.parentNode);
    return false;
  }

  private setIsWorkingState(value: boolean) {
    this.isLoadingAdditionalContent = value;
    this.isWorking.emit(value);
  }

  handleBitSelected(selectedBitId: string) {
    this.location.replaceState(
      this.router.createUrlTree(
        [],
        {
          fragment: `${selectedBitId}`,
          queryParamsHandling: 'merge',
        })
        .toString(),
    );
    this.initializeUserCopyProtection(false);
    this.loadBit(selectedBitId);
  }

  handleInternalLink(internalLinkBit: InternalLinkBit) {
    this.navigateToBitReference(internalLinkBit.reference);
  }

  navigateToBitReference(ref: string, bitId?: string) {
    this.readerTocService.getBitByReferenceAnchor(this.bitBook.externalId, ref)
      .subscribe((targetedBit) => {
        if (targetedBit) {
          // this.location.replaceState(
          //   this.router.createUrlTree(
          //     [],
          //     {
          //       fragment: `${targetedBit.ref}`,
          //       queryParamsHandling: 'merge'
          //     })
          //     .toString(),
          // );
          // this.loadBit(targetedBit.ref);
          this.location.go(
            this.router.createUrlTree(
              [],
              {
                fragment: `${targetedBit.ref}`,
                queryParamsHandling: 'merge'
              })
              .toString()
          );
          this.loadBit(targetedBit.ref);
        } else {
          const refBit = this.bitBookContent.find(x => x.id == bitId);
          if (refBit?.meta?.originBook?.externalId) {
            this.readerContentService.getBookBit(refBit.meta.originBook.externalId, ref).subscribe(tocBit => {
              this.navigateToBook.emit({bookId: refBit.meta.originBook.externalId, fragment: tocBit?.ref});
            });
          }
        }
      });
  }

  navigateToBookBitReference(bookId: string, ref: string) {
    if (!bookId) {
      return;
    }
    if (!ref) {
      this.navigateToBook.emit({bookId, fragment: null});
      return;
    }
    this.readerTocService.getBitByReferenceAnchor(bookId, ref)
      .subscribe((targetedBit) => {
        if (targetedBit) {
          this.navigateToBook.emit({bookId, fragment: targetedBit.ref});
        }
      });
  }

  onScrollUp() {
    if (!this.bitBookContent?.length || this.isLoadingAdditionalContent || this.isLoading || this.isAtTheTop) {
      return;
    }

    this.isLoadingAdditionalContentTop = true;
    this.setIsWorkingState(true);
    const handInId = this.route.snapshot.queryParams.handInId;
    const queryParams = {};
    if (handInId) {
      queryParams['handInId'] = handInId;
    }
    this.readerContentService.handleScrollUp(this.bitBook, this.bitBookContent, null, queryParams)
      .subscribe((res: { newContent: Array<BitApiWrapper>, isAtTop: boolean }) => {
        const sub$ = this.domObserverService.observeResize2('.reader-content .bits-wrapper').subscribe(() => {
          sub$.unsubscribe();
          this.isLoadingAdditionalContentTop = false;
          this.setIsWorkingState(false);
        });

        this.bitBookContent = res.newContent; // .slice(0, this.pageSize * 1.5);
        this.setReaderAsHandedInReadonlyIfNotAssigned();
        this.isAtTheTop = res.isAtTop;
        this.isAtTheBottom = false;
      });
  }

  onScrollDown() {
    if (!this.bitBookContent?.length || this.isLoadingAdditionalContent || this.isLoading || this.isAtTheBottom) {
      return;
    }

    this.setIsWorkingState(true);
    this.isLoadingAdditionalContentBottom = true;
    const handInId = this.route.snapshot.queryParams.handInId;
    const queryParams = {};
    if (handInId) {
      queryParams['handInId'] = handInId;
    }
    this.readerContentService.handleScrollDown(this.bitBook, this.bitBookContent, null, queryParams)
      .subscribe((res: { newContent: Array<BitApiWrapper>, isAtBottom: boolean }) => {
        const sub$ = this.domObserverService.observeResize2('.reader-content .bits-wrapper').subscribe(() => {
          sub$.unsubscribe();
          this.isLoadingAdditionalContentBottom = false;
          this.setIsWorkingState(false);
        });

        this.bitBookContent = res.newContent; // .slice(-(this.pageSize * 1.5));
        this.setReaderAsHandedInReadonlyIfNotAssigned();
        this.isAtTheBottom = res.isAtBottom;
        this.isAtTheTop = false;
      });
  }

  loadBit(startingBit: string) {
    this.setIsWorkingState(true);

    const handInId = this.route.snapshot.queryParams.handInId;
    const queryParams = {};
    if (handInId) {
      queryParams['handInId'] = handInId;
    }

    const bitIndex = this.bitBookContent.findIndex(b => b.id == startingBit);
    if (!startingBit || (startingBit === 'start' && bitIndex === -1)) {
      this.readerContentService.loadContent(this.bitBook, this.pagination, null, true, null, queryParams)
        .subscribe((res: {
          bitBookContent: Array<BitApiWrapper>,
          lastPos: BookPosition,
          isAtTop: boolean,
          isAtBottom: boolean
        }) => {
          const scrollContainer = document.querySelector('.infinite-scroll-container');
          scrollContainer.scrollTop = 0;
          this.bitBookContent = res.bitBookContent;
          this.isAtTheTop = res.isAtTop;
          this.isAtTheBottom = res.isAtBottom;
          this.setReaderAsHandedInReadonlyIfNotAssigned();
          this.setIsWorkingState(false);
        }, (err: HttpErrorResponse) => {
          console.error(err);
          this.setIsWorkingState(false);
        });
      return;
    }

    if (bitIndex !== -1) {
      this.readerContentService.scrollToBookPos({
        bitId: startingBit,
        index: this.bitBookContent[bitIndex].index,
        distance: -.3
      }).subscribe(() => this.setIsWorkingState(false));
      return;
    }

    this.isAtTheTop = false;
    this.isAtTheBottom = false;
    this.setIsWorkingState(true);
    this.readerContentService.loadBit(startingBit, this.bitBook, queryParams)
      .subscribe((res: { bitBookContent: Array<BitApiWrapper>, bit: BitApiWrapper }) => {
        this.isLoading = true;
        const obsScroll = this.readerContentService.observeAndScrollToBookPos({
          bitId: res.bit.id,
          index: res.bit.index,
          distance: 0
        }).subscribe(() => {
          obsScroll.unsubscribe();
          this.isLoading = false;
          this.setIsWorkingState(false);
          this.setReaderAsHandedInReadonlyIfNotAssigned();
        });

        this.bitBookContent = res.bitBookContent;
      });
  }

  private resetBookRating(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    this.bitBookApiService.resetBookRating(this.bitmarkConfig.space, this.bitBook?.id).subscribe((result) => {
      const ratingBit = bitWrapper.bit as VirtualMarkBookAsReadBit;
      if (ratingBit) {
        ratingBit.rating = 0;
        ratingBit.isBookRead = false;
      }
      console.log('Rating result: ', result);
    }, (err) => {
      console.error(err);
    });
  }

  private resetAnswer(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerContentService.resetAnswer(bitId, this.bitBookContent)
      .subscribe();
  }

  private resetHighlights(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerContentService.resetAnswer(bitId, this.bitBookContent)
      .subscribe(() => {
        setTimeout(() => {
          const bitContainer: HTMLElement = document.querySelector(`#bit-${bitId} .bit-container`);
          if (bitContainer?.querySelector('.user-highlight') || bitContainer?.querySelector('.bitmark-text-user-highlight')) {
            removeHighlights(bitContainer);
          }
        }, 300);
      });
  }

  private addToBasket(dropdownItemModel: DropdownItemModel) {
    const bitId = (dropdownItemModel.data as BitApiWrapper).id;
    this.readerBasketService.addToBasket(bitId).subscribe();
  }

  private copyBitmarkToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    return this.clipboardService.copyFromContent(bitWrapper.bitmark || '');
  }

  private copyBitmarkJsonToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    return this.clipboardService.copyFromContent(JSON.stringify(bitWrapper.bit || ''));
  }

  private copyBitLink(dropdownItemModel: DropdownItemModel) {
    this.copyLinkToBit.emit(dropdownItemModel.data as BitApiWrapper);
  }

  private copyBitToClipboard(dropdownItemModel: DropdownItemModel) {
    return this.readerClipboard.copyToClipboard([{id: dropdownItemModel.data.id}]).subscribe();
  }

  private sendBit(dropdownItemModel: DropdownItemModel) {
    this.sendBits.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private sendBitToClass(dropdownItemModel: DropdownItemModel) {
    this.sendBitsToClass.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private saveBit(dropdownItemModel: DropdownItemModel) {
    this.saveBits.emit([dropdownItemModel.data as BitApiWrapper]);
  }

  private saveBitBitmark(id: string, data: any) {
    const bookBit = this.bitBookContent.find(x => x.id == id);

    const changedData = {};
    for (const dataProp of Object.keys(data)) {
      changedData[dataProp] = new BitmarkReversePipe(this.readerTipTapService).transform(data[dataProp]);
    }

    const changedBit = {...bookBit.bit, ...changedData};
    this.bitBookApiService.saveAnswer(bookBit.id, changedBit, bookBit.bitInstanceId)
      .subscribe((res: { bit: BaseBit, id: number, bitId: any }) => {
        bookBit.bitInstanceId = res.id;
      }, (err: HttpErrorResponse) => {
        console.error(err);
      });
  }

  onOpenResource(bitResource: BitResource) {
    this.readerContentService.handleOpenResource(bitResource);
  }

  @memoize()
  getApiWrapperFromAnnotation(annotation: BitApiAnnotation): BitApiWrapper {
    return {
      id: annotation.id.toString(),
      bit: {id: annotation.id.toString(), ...annotation.data},
      user: annotation.user
    };
  }

  @memoize()
  getBitAnnotationActions(annotation: BitApiAnnotation): Array<DropdownItemModel> {
    let annotationColor = (annotation.data as AnnotationBaseBit)?.color?.primary;

    if (!annotationColor) {
      annotationColor = BitAnnotationDefaultColor[annotation.type]?.primary;
    }

    return this.bitAnnotationActions.map(action => {
      if (action.id === 'color') {
        action.colors = action.colors.map(color => {
          color.isSelected = color.primary === annotationColor;

          return color;
        });
      }

      return action;
    });
  }

  @memoize()
  getTopAnnotations(annotations: Array<BitApiAnnotation>, externalAnnotations: Array<BitApiAnnotation>): Array<BitApiAnnotation> {
    const ret =  (externalAnnotations || []).filter(x => x.type === BitType.AnnotationFavorite || x.type === BitType.AnnotationBookmark)
      .concat((annotations || []).filter(x => x.type === BitType.AnnotationFavorite || x.type === BitType.AnnotationBookmark))
      .sort((a, b) => {
        return new Date(a?.createdAt) as any - (new Date(b?.createdAt) as any);
      });
    return ret;
  }

  @memoize()
  getBottomAnnotations(annotations: Array<BitApiAnnotation>, externalAnnotations: Array<BitApiAnnotation>): Array<BitApiAnnotation> {
    const ret = (externalAnnotations || []).filter(x => x.type === BitType.AnnotationNote || x.type === BitType.AnnotationHandwritten)
      .concat((annotations || []).filter(x => x.type === BitType.AnnotationNote || x.type === BitType.AnnotationHandwritten))
      .sort((a, b) => {
        return new Date(a?.createdAt) as any - (new Date(b?.createdAt) as any);
      });
    return ret;
  }

  private createAnnotationNoteOnBit(dropdownItemModel: DropdownItemModel, title = '') {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    if (!bitWrapper?.id) {
      return;
    }

    const newBit: AnnotationNoteBit = {
      title: [
        {
          attrs: {
            class: 'normal',
            paragraphType: 'normal'
          },
          content: [
            {
              text: title,
              type: 'text'
            }
          ],
          type: 'paragraph'
        }
      ],
      content: [
        {
          attrs: {
            class: 'normal',
            paragraphType: 'normal'
          },
          content: [
            {
              text: '',
              type: 'text'
            }
          ],
          type: 'paragraph'
        }
      ],
      format: BitmarkFormat.PP,
      type: BitType.AnnotationNote
    };
    this.bitBookApiService.addBitAnnotation(bitWrapper?.id, newBit, BitType.AnnotationNote, (this.bitBook?.handIn?.status !== HandInStatus.Rejected && this.bitBook?.handIn?.id) || null)
      .subscribe((result: { createdAnnotations: Array<BitApiAnnotation> }) => {
        if (!bitWrapper.annotations?.length) {
          bitWrapper.annotations = [];
        }

        if (result?.createdAnnotations?.length && result?.createdAnnotations[0]?.id) {
          this.onAnnotationCreated(result?.createdAnnotations[0]?.id, result?.createdAnnotations[0].tocEntry);
        }

        bitWrapper.annotations = [...bitWrapper.annotations, ...result.createdAnnotations];
      }, (err) => console.error(err));
  }

  private createAnnotationBookmarkOnBit(dropdownItemModel: DropdownItemModel, title = '') {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    if (!bitWrapper?.id) {
      return;
    }

    const newBit: AnnotationBookmarkBit = {
      content: title ? [
        {
          attrs: {
            class: 'normal',
            paragraphType: 'normal'
          },
          content: [
            {
              text: title,
              type: 'text'
            }
          ],
          type: 'paragraph'
        }
      ] : [
        {
          'type': 'paragraph',
          'attrs': {
            'class': 'normal',
            'paragraphType': 'normal'
          }
        }
      ],
      format: BitmarkFormat.PP,
      type: BitType.AnnotationBookmark
    };

    this.bitBookApiService.addBitAnnotation(bitWrapper?.id, newBit, BitType.AnnotationBookmark, (this.bitBook?.handIn?.status !== HandInStatus.Rejected && this.bitBook?.handIn?.id) || null)
      .subscribe((result: { createdAnnotations: Array<BitApiAnnotation> }) => {
        if (!bitWrapper.annotations?.length) {
          bitWrapper.annotations = [];
        }
        if (result?.createdAnnotations?.length && result?.createdAnnotations[0]?.id) {
          this.onAnnotationCreated(result?.createdAnnotations[0]?.id, result?.createdAnnotations[0].tocEntry);
        }
        bitWrapper.annotations = [...bitWrapper.annotations, ...result.createdAnnotations];
      }, (err) => console.error(err));
  }

  private createAnnotationFavoriteOnBit(dropdownItemModel: DropdownItemModel, title = '') {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    if (!bitWrapper?.id) {
      return;
    }

    const newBit: AnnotationBookmarkBit = {
      content: title ? [
        {
          attrs: {
            class: 'normal',
            paragraphType: 'normal'
          },
          content: [
            {
              text: title,
              type: 'text'
            }
          ],
          type: 'paragraph'
        }
      ] : [
        {
          'type': 'paragraph',
          'attrs': {
            'class': 'normal',
            'paragraphType': 'normal'
          }
        }
      ],
      format: BitmarkFormat.PP,
      type: BitType.AnnotationFavorite
    };
    this.bitBookApiService.addBitAnnotation(bitWrapper?.id, newBit, BitType.AnnotationFavorite, (this.bitBook?.handIn?.status !== HandInStatus.Rejected && this.bitBook?.handIn?.id) || null)
      .subscribe((result: { createdAnnotations: Array<BitApiAnnotation> }) => {
        if (!bitWrapper.annotations?.length) {
          bitWrapper.annotations = [];
        }
        if (result?.createdAnnotations?.length && result?.createdAnnotations[0]?.id) {
          this.onAnnotationCreated(result?.createdAnnotations[0]?.id, result?.createdAnnotations[0].tocEntry);
        }
        bitWrapper.annotations = [...bitWrapper.annotations, ...result.createdAnnotations];
      }, (err) => console.error(err));
  }

  private createAnnotationHandwrittenOnBit(dropdownItemModel: DropdownItemModel, title = '') {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }
    if (!bitWrapper?.id) {
      return;
    }

    const newBit: AnnotationNoteBit = {
      title: title ? [
        {
          attrs: {
            class: 'normal',
            paragraphType: 'normal'
          },
          content: [
            {
              text: title,
              type: 'text'
            }
          ],
          type: 'paragraph'
        }
      ] : [
        {
          'type': 'paragraph',
          'attrs': {
            'class': 'normal',
            'paragraphType': 'normal'
          }
        }
      ],
      content: [
        {
          'type': 'paragraph',
          'attrs': {
            'class': 'normal',
            'paragraphType': 'normal'
          }
        }
      ],
      format: BitmarkFormat.Text,
      type: BitType.AnnotationHandwritten
    };
    this.bitBookApiService.addBitAnnotation(bitWrapper?.id, newBit, BitType.AnnotationHandwritten, (this.bitBook?.handIn?.status !== HandInStatus.Rejected && this.bitBook?.handIn?.id) || null)
      .subscribe((result: { createdAnnotations: Array<BitApiAnnotation> }) => {
        if (!bitWrapper.annotations?.length) {
          bitWrapper.annotations = [];
        }

        if (result?.createdAnnotations?.length && result?.createdAnnotations[0]?.id) {
          // this.selectNoteAfterCreation(result?.createdAnnotations[0]?.id.toString());
          this.onAnnotationCreated(result?.createdAnnotations[0]?.id, result?.createdAnnotations[0].tocEntry, false);
        }

        bitWrapper.annotations = [...bitWrapper.annotations, ...result.createdAnnotations];
      }, (err) => console.error(err));
  }

  private onAnnotationCreated(createdAnnotationId: number, tocEntry: TocItem, shouldFocus = true) {
    if (shouldFocus) {
      const editorCreatedSub = this.bitbookMqService.onEditorCreated()
        .subscribe((editor: ReaderTextEditorComponent | ReaderSimpleTextEditorComponent) => {
          this.domUtilsService.waitForDifferentStyleValue(`#bit-${createdAnnotationId.toString()}`, 'visibility', 'hidden')
            .subscribe(() => editor.focus());
          editorCreatedSub.unsubscribe();
        });
    }

    this.refreshTocBit(tocEntry);
    this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
  }

  toggleAnnotationHighlight() {
    this.annotationMenuInstance.style.display = 'none';
    if (!window.getSelection().toString().length) {
      return;
    }

    const isOverlapping = (this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'user-highlight')
        && this.elementOrAncestorHasClass(window.getSelection().focusNode, 'user-highlight'))
      || (this.elementOrAncestorHasClass(window.getSelection().anchorNode, 'bitmark-text-user-highlight')
        && this.elementOrAncestorHasClass(window.getSelection().focusNode, 'bitmark-text-user-highlight'));

    if (isOverlapping) {
      this.removeAnnotationHighlight();
    } else {
      this.createAnnotationHighlight();
    }
  }

  createAnnotationHighlight() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);

      const markElem = document.createElement('mark');
      markElem.className = 'bitmark-text-user-highlight';

      const elems = highlightRange(bitElement, window.getSelection().getRangeAt(0), markElem);
      normalizeHighlights(elems);

      window.getSelection().removeAllRanges();

      const bitId = bitElement.id.replace('bit-', '');

      const bodyElem = bitElement.querySelector('.bit-body-allow-highlight .bitmark-text');
      this.saveBitBitmark(bitId, {body: bodyElem.innerHTML});
    }
  }

  removeAnnotationHighlight() {
    this.annotationMenuInstance.style.display = 'none';
    if (window.getSelection().toString().length) {
      const bitElement = this.getParentBitElement(window.getSelection().anchorNode);
      const range = window.getSelection().getRangeAt(0);

      const iterator = document.createNodeIterator(
        range.commonAncestorContainer,
        NodeFilter.SHOW_ALL, // pre-filter
        {
          // custom filter
          acceptNode: function () {
            return NodeFilter.FILTER_ACCEPT;
          }
        }
      );

      const nodes = [];
      while (iterator.nextNode()) {
        if (nodes.length === 0 && iterator.referenceNode !== range.startContainer) {
          continue;
        }

        if (iterator.referenceNode.parentElement.classList.contains('user-highlight')
          || iterator.referenceNode.parentElement.classList.contains('bitmark-text-user-highlight')) {
          nodes.push(iterator.referenceNode);
        }

        if (iterator.referenceNode === range.endContainer) {
          break;
        }
      }

      nodes?.forEach(node => {
        removeHighlights(node.parentElement);
      });

      window.getSelection().removeAllRanges();

      const bitId = bitElement.id.replace('bit-', '');

      const bodyElem = bitElement.querySelector('.bit-body-allow-highlight .bitmark-text');
      this.saveBitBitmark(bitId, {body: bodyElem.innerHTML});
    }
  }

  saveEditedAnnotation(bit: any) {
    const payload = {...bit.bitWrapper.bit, ...bit.content};
    this.bitBookApiService.updateBitAnnotation(bit.bitWrapper.id, payload, (this.bitBook?.handIn?.status !== HandInStatus.Rejected && this.bitBook?.handIn?.id) || null)
      .subscribe((result: BitApiAnnotation) => {
        this.refreshTocBit(result.tocEntry);
        const associatedBit = this.bitBookContent.find((b) => +b.id === result.bitId);
        const existingAnnotationIdx = associatedBit?.annotations.findIndex((a) => a.id === result.id);
        if (existingAnnotationIdx > -1) {
          associatedBit.annotations[existingAnnotationIdx].data = result.data;
        }
      }, (err) => {
        console.error(err);
      });
  }

  selectAnnotationColor(dropdownItemModel: DropdownItemModel) {
    const bit = this.bitBookContent.find(bookBit => {
      return bookBit.annotations?.find(x => x.id == dropdownItemModel.data.id);
    });

    if (bit && bit.annotations) {
      bit.annotations = bit.annotations.map(x => {
        if (x.id == dropdownItemModel.data.id) {
          const bitAnnotationData = x.data as AnnotationBaseBit;

          if (bitAnnotationData) {
            bitAnnotationData.color = dropdownItemModel.selectedColor;
            x = {
              ...x,
              data: {...bitAnnotationData}
            };

            this.bitBookApiService.updateBitAnnotation(x.id, bitAnnotationData)
              .subscribe((result) => this.refreshTocBit(result.tocEntry),
                (err) => console.error(err));
          }
        }
        return x;
      });
    }
  }

  deleteAnnotation(dropdownItemModel: DropdownItemModel) {
    this.bitBookApiService.deleteBitAnnotation(dropdownItemModel.data.id, (this.bitBook?.handIn?.status !== HandInStatus.Rejected && this.bitBook?.handIn?.id) || null)
      .subscribe((result: { tocEntry: TocItem }) => {
        const bit = this.bitBookContent.find(bookBit => {
          return bookBit.annotations?.find(x => x.id == dropdownItemModel.data.id);
        });

        if (bit && bit.annotations) {
          bit.annotations = bit.annotations.filter(x => x.id != dropdownItemModel.data.id);
        }

        this.refreshTocBit(result.tocEntry);
        this.readerContentService.computeAndNotifyBitsVisibility(() => this.bitBookContent);
      }, (err) => {
        console.error(err);
      });
  }

  selectNoteAfterCreation(bitId: string) {
    console.log('in select note');
    const el = document.querySelector(`#bit-${bitId} .ProseMirror[contenteditable="true"]`);
    if (el) {
      console.log('selecting note, ', el);
      this.selectContentEditableElement(el);
    } else {
      console.log('going to set timeout, ', el);
      setTimeout(() => {
        this.selectNoteAfterCreation(bitId);
      }, 100);
    }
  }

  selectContentEditableElement(el, selectAllText?: boolean) {
    el.focus();
    if (typeof window.getSelection !== 'undefined'
      && typeof document.createRange !== 'undefined') {
      const range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof (document.body as any).createTextRange !== 'undefined') {
      const textRange = (document.body as any).createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
    if (selectAllText) {
      document.execCommand('selectAll', false, null);
    }
  }

  refreshTocBit(tocEntry: TocItem) {
    this.bitbookMqService.notifyRefreshBit({id: this.bitBook.id, tocEntry: tocEntry});
    this.readerTocService.setIsTocReloadNeeded(this.bitBook.externalId, true).subscribe();
  }

  protected readonly HandInStatus = HandInStatus;
}
