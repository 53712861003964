// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.learning-path-classroom-event-header {
  height: auto;
  background-color: var(--bitmark-learning-path-header-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-learning-path-header-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-learning-path-header-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-learning-path-header-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-learning-path-header-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-learning-path-header-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-learning-path-header-background-animation-name) var(--bitmark-learning-path-header-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-learning-path-header-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-learning-path-header-background-backdrop-filter);
  font-family: var(--bitmark-learning-path-header-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-learning-path-header-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-learning-path-header-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-learning-path-header-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-learning-path-header-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-learning-path-header-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-learning-path-header-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-learning-path-header-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-learning-path-header-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-learning-path-header-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.learning-path-classroom-event-header .header-content {
  width: calc(100% - 72px);
}
.learning-path-classroom-event-header .header-content .header-text {
  width: calc(100% - 35px);
}
.learning-path-classroom-event-header .header-content .header-duration {
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.learning-path-classroom-event-header .header-content .is-done-check {
  width: 30px;
  cursor: pointer;
}

.learning-path-classroom-event-header-minimized, .learning-path-classroom-event-header-only {
  border-radius: 0.3rem;
}
.learning-path-classroom-event-header-minimized .scheduled-date, .learning-path-classroom-event-header-only .scheduled-date {
  border-bottom-left-radius: 0.2rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
