import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PageProductListBit} from './page-product-list.models';
import {BookType, HandInStatus, ProductFamily} from '../../shared/models/bitmark.models';
import {BitProductService} from '../bit-product.service';

@Component({
  selector: 'bitmark-page-product-list',
  templateUrl: './page-product-list.component.html',
  styleUrls: ['./page-product-list.component.scss']
})
export class PageProductListComponent implements OnInit {
  @Input() bit?: PageProductListBit;
  @Input() isNavigateDirectlyToBook?: boolean;
  @Output() navigateToProduct: EventEmitter<{ productId: string, family?: ProductFamily }> = new EventEmitter<{ productId: string, family?: ProductFamily }>();
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }> = new EventEmitter<{
    bookId: string,
    fragment: string,
    family?: ProductFamily
  }>();
  isWideEntry = false;
  publisher: string;
  title: string;
  bookType: string;
  products: Array<any>;

  @HostListener('document:gmbProductDetailsDelivered', ['$event'])
  onProductPriceDelivered(event: any) {
    this.products = this.bitProductService.getProductsFromEvent(event, this.products, this.bit?.product);
  }

  constructor(private bitProductService: BitProductService){

  }

  ngOnInit() {
    const event = new CustomEvent('gmbProductDetailsRequested', {
      detail: {
        productIds: this.bit?.product,
      }, bubbles: true
    });
    document.dispatchEvent(event);
  }

  openProductPage(product) {
    if (this.isNavigateDirectlyToBook) {
      if (product?.meta?.family === ProductFamily.XModule) {
        return this.navigateToBook.emit({bookId: product?._id, fragment: null, family: ProductFamily.XModule});
      }
      if (!product?.bookExists) {
        return;
      }
      this.navigateToBook.emit({bookId: product?._id, fragment: 'start'})
    } else {
      this.navigateToProduct.emit({productId: product?._id, family: product?.meta?.family});
    }
  }

  setWideImage() {

  }

  protected readonly BookType = BookType;
  protected readonly HandInStatus = HandInStatus;
}
