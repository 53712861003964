// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-container {
  --bitmark-bit-quiz-bits-feedback-default-avatar-size-width: 50px;
  --bitmark-bit-quiz-bits-feedback-default-avatar-size-height: 50px;
  --bitmark-bit-quiz-bits-feedback-default-message-bubble-background-color: #b1cfeb;
}
.feedback-container .avatar {
  --bitmark-bit-quiz-bits-feedback-default-avatar-background-color: #543199;
  --bitmark-bit-quiz-bits-feedback-default-avatar-background-image: url('data:image/svg+xml;utf-8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path style="fill: white" d="M21 6.285l-11.16 12.733-6.84-6.018 1.319-1.49 5.341 4.686 9.865-11.196 1.475 1.285z"/></svg>');
  background-color: var(--bitmark-bit-quiz-bits-feedback-avatar-background-color, var(--bitmark-bit-quiz-bits-feedback-default-avatar-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-bit-quiz-bits-feedback-avatar-background-image, var(--bitmark-bit-quiz-bits-feedback-default-avatar-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-bit-quiz-bits-feedback-avatar-background-size, var(--bitmark-bit-quiz-bits-feedback-default-avatar-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-bit-quiz-bits-feedback-avatar-background-position, var(--bitmark-bit-quiz-bits-feedback-default-avatar-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-bit-quiz-bits-feedback-avatar-background-repeat, var(--bitmark-bit-quiz-bits-feedback-default-avatar-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-bit-quiz-bits-feedback-avatar-background-transform, var(--bitmark-bit-quiz-bits-feedback-default-avatar-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-bit-quiz-bits-feedback-avatar-background-animation-name) var(--bitmark-bit-quiz-bits-feedback-avatar-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-bit-quiz-bits-feedback-avatar-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-bit-quiz-bits-feedback-avatar-background-backdrop-filter);
  width: var(--bitmark-bit-quiz-bits-feedback-avatar-size-width, var(--bitmark-bit-quiz-bits-feedback-default-avatar-size-width, inherit));
  height: var(--bitmark-bit-quiz-bits-feedback-avatar-size-height, var(--bitmark-bit-quiz-bits-feedback-default-avatar-size-height, inherit));
  margin: var(--bitmark-bit-quiz-bits-feedback-avatar-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-bit-quiz-bits-feedback-avatar-size-padding, var(--bitmark-x-size-padding, 0));
}
.feedback-container .avatar .triangle-wrapper {
  position: absolute;
  bottom: calc(-11px - var(--bitmark-bit-quiz-bits-feedback-avatar-size-margin, 0px));
  left: 50%;
  transform: translate(-50%, 50%);
  color: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-color, var(--bitmark-bit-quiz-bits-feedback-default-message-bubble-background-color));
}
.feedback-container .avatar-text::after {
  content: var(--bitmark-bit-quiz-bits-feedback-avatar-string, "Get More Brain");
  font-family: var(--bitmark-bit-quiz-bits-feedback-avatar-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-bit-quiz-bits-feedback-avatar-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-bit-quiz-bits-feedback-avatar-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-bit-quiz-bits-feedback-avatar-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-bit-quiz-bits-feedback-avatar-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-bit-quiz-bits-feedback-avatar-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-bit-quiz-bits-feedback-avatar-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-bit-quiz-bits-feedback-avatar-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-bit-quiz-bits-feedback-avatar-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-bit-quiz-bits-feedback-avatar-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.feedback-container .feedback-entry-bubble-wrapper .feedback-entry-bubble {
  position: relative;
  display: inline-block;
  max-width: 755px;
  min-width: 90px;
  width: auto;
  overflow: hidden;
  background-color: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-color, var(--bitmark-bit-quiz-bits-feedback-default-message-bubble-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-image, var(--bitmark-bit-quiz-bits-feedback-default-message-bubble-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-size, var(--bitmark-bit-quiz-bits-feedback-default-message-bubble-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-position, var(--bitmark-bit-quiz-bits-feedback-default-message-bubble-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-repeat, var(--bitmark-bit-quiz-bits-feedback-default-message-bubble-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-transform, var(--bitmark-bit-quiz-bits-feedback-default-message-bubble-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-animation-name) var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-bit-quiz-bits-feedback-message-bubble-background-backdrop-filter);
  width: var(--bitmark-bit-quiz-bits-feedback-message-bubble-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-bit-quiz-bits-feedback-message-bubble-size-height, var(--bitmark-x-size-height, inherit));
  font-family: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-bit-quiz-bits-feedback-message-bubble-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.feedback-container .feedback-entry-bubble-wrapper .feedback-entry-bubble.rounded {
  border-radius: 10px !important;
}
.feedback-container .feedback-entry-bubble-wrapper .feedback-entry-bubble .bit-entry-content {
  word-break: break-word;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
