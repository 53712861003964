// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host:has(.flashcards-container-modal) {
  flex: 1;
}

.flashcards-container .flashcard-mode-placeholder .header {
  height: 56px;
}
.flashcards-container .flashcard-mode-placeholder .navigation {
  height: 50px;
}
.flashcards-container .flashcard-mode-placeholder .card-content {
  margin: var(--bitmark-app-flashcards-card-size-margin, var(--bitmark-x-size-margin, 0));
  width: var(--bitmark-app-flashcards-card-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-app-flashcards-card-size-height, var(--bitmark-x-size-height, inherit));
}
.flashcards-container.flashcards-container-modal {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flashcards-container .flashcards-leitner {
  color: var(--bitmark-app-flashcards-label-color, #fff);
}
.flashcards-container .flashcards-leitner.is-in-modal {
  position: absolute;
  bottom: 0;
}
.flashcards-container .flashcards-leitner .leitner-mode-checkbox ~ .custom-control-label {
  padding-left: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 0.2rem;
}
.flashcards-container .flashcards-leitner .leitner-mode-checkbox.custom-control-input:not(:checked) ~ .custom-control-label::before {
  background-color: var(--bitmark-app-flashcards-toggle-button-color, #fff);
}
.flashcards-container .flashcards-leitner .leitner-mode-checkbox.custom-control-input:not(:checked) ~ .custom-control-label::after {
  background-color: var(--bitmark-app-flashcards-toggle-button-switch-color, #f00);
}
.flashcards-container .flashcards-leitner .leitner-mode-checkbox.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--bitmark-app-flashcards-toggle-button-switch-color, #f00);
  background-color: var(--bitmark-app-flashcards-toggle-button-switch-color, #f00);
}
.flashcards-container .flashcards-leitner .leitner-mode-checkbox.custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--bitmark-app-flashcards-toggle-button-color, #fff);
}

.flashcards-header {
  color: var(--bitmark-app-flashcards-label-color, #fff);
  font-size: 20px;
  height: 64px;
  position: relative;
}
.flashcards-header.flashcard-header-modal {
  height: 44px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.flashcards-header:after {
  content: "";
  display: block;
  width: 50px;
  height: 24px;
  padding: 2px 10px;
  border-radius: 20px;
  font-size: 18px;
  position: absolute;
  top: 20px;
  background-color: var(--bitmark-app-flashcards-selected-pod-color, #f00);
  z-index: 0;
  transition: left 0.2s ease;
}
.flashcards-header.flashcard-header-modal:after {
  top: 5px !important;
}
.flashcards-header .flashcards-pot {
  font-family: var(--bitmark-app-flashcards-pod-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-app-flashcards-pod-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-app-flashcards-pod-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-app-flashcards-pod-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-app-flashcards-pod-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-app-flashcards-pod-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-app-flashcards-pod-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-app-flashcards-pod-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-app-flashcards-pod-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-app-flashcards-pod-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  position: relative;
  height: 24px;
  min-width: 50px;
  text-align: center;
  background-color: var(--bitmark-app-flashcards-pod-color, #767676);
  padding: 2px 10px;
  border-radius: 20px;
}
.flashcards-header .flashcards-pot-value {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  min-width: 50px;
}
.flashcards-header .selected-flashcard-pot {
  font-family: var(--bitmark-app-flashcards-selected-pod-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-app-flashcards-selected-pod-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-app-flashcards-selected-pod-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-app-flashcards-selected-pod-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-app-flashcards-selected-pod-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-app-flashcards-selected-pod-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-app-flashcards-selected-pod-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-app-flashcards-selected-pod-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-app-flashcards-selected-pod-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-app-flashcards-selected-pod-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  text-align: center;
}

.flashcards-content {
  margin: var(--bitmark-app-flashcards-card-size-margin, var(--bitmark-x-size-margin, 0));
  width: var(--bitmark-app-flashcards-card-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-app-flashcards-card-size-height, var(--bitmark-x-size-height, inherit));
  width: calc(100% - 2 * var(--bitmark-app-flashcards-card-size-margin));
}
.flashcards-content.flashcards-content-modal {
  margin-top: 15px !important;
}
.flashcards-content .flashcards-card-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.flashcards-content .flashcards-card-container.current-card {
  padding: 5px;
}
.flashcards-content .flashcards-cards-empty {
  color: var(--bitmark-app-flashcards-label-color, #fff);
  font-size: 20px;
}

.flashcards-navigation .flashcards-navigation-content {
  height: 50px;
}
.flashcards-navigation .left-arrow {
  position: absolute;
  right: 75px;
  top: 50%;
  transform: translate(0%, -50%) !important;
  cursor: pointer;
}
.flashcards-navigation .right-arrow {
  transform: rotate(180deg);
  cursor: pointer;
}
.flashcards-navigation .arrows {
  color: var(--bitmark-app-flashcards-navigation-buttons-font-color, #fff);
  border-radius: 50%;
}
.flashcards-navigation .arrows-background {
  background-color: var(--bitmark-app-flashcards-navigation-buttons-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-app-flashcards-navigation-buttons-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-app-flashcards-navigation-buttons-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-app-flashcards-navigation-buttons-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-app-flashcards-navigation-buttons-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-app-flashcards-navigation-buttons-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-app-flashcards-navigation-buttons-background-animation-name) var(--bitmark-app-flashcards-navigation-buttons-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-app-flashcards-navigation-buttons-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-app-flashcards-navigation-buttons-background-backdrop-filter);
}

.flashcards-learn .flashcards-learn-btn {
  background-color: var(--bitmark-app-flashcards-navigation-buttons-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-app-flashcards-navigation-buttons-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-app-flashcards-navigation-buttons-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-app-flashcards-navigation-buttons-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-app-flashcards-navigation-buttons-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-app-flashcards-navigation-buttons-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-app-flashcards-navigation-buttons-background-animation-name) var(--bitmark-app-flashcards-navigation-buttons-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-app-flashcards-navigation-buttons-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-app-flashcards-navigation-buttons-background-backdrop-filter);
}

.flashcards-close-modal {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--bitmark-app-flashcards-navigation-buttons-font-color, #fff);
}

.keyboard-focus {
  color: var(--bitmark-app-flashcards-keyboard-button-color, #fff);
}

.keyboard-focus-active {
  color: var(--bitmark-app-flashcards-keyboard-active-button-color, #fff);
}

.disable-click {
  pointer-events: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
