import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BookLinkBit} from './book-link.models';
import {BitbookMqService} from '../../reader/bitbook-mq.service';
import {concatMap, Observable, of} from 'rxjs';
import {BookEntityToc, ProductFamily} from '../../shared/models/bitmark.models';
import {map} from 'rxjs/operators';
import {BookEntity} from '../../reader/reader.models';
import {ReaderTocService} from '../../reader/reader-toc.service';
import {BitbookApiService} from '../../reader/bitbook-api.service';

@Component({
  selector: 'bitmark-book-link',
  templateUrl: './book-link.component.html',
  styleUrls: ['./book-link.component.scss', '../bits.scss']
})
export class BookLinkComponent implements OnInit {
  @Input() bit?: BookLinkBit;
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string }> = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }>();
  @Output() isBookUnaccessibleToUser = new EventEmitter<{ bookId: string }>();
  isWideEntry = false;
  publisher: string;
  title: string;
  bookType: string;
  _operationId: string;
  product: any;

  @HostListener('document:gmbProductDetailsDelivered', ['$event'])
  onProductPriceDelivered(event: any) {
    const thisEventProductIds = event.detail?.products.filter((p) => {
      return this.bit?.book === p?._id;
    })
    if(thisEventProductIds.length){
      const prds = event?.detail?.products.filter((p) => {
        return this.bit?.book === p?._id
      })
      prds?.forEach((p) => {
        p.publisher = p && p?.meta?.publisher?.length ? p.meta.publisher[0].data : '';
        p.title = p && p?.texts?.title ? p.texts.title : '';
      })
      this.product = prds[0];
      this.publisher = this.product && this.product?.meta?.publisher?.length ? this.product.meta.publisher[0].data : '';
      this.title = this.product && this.product?.texts?.title ? this.product.texts.title : '';
    }
  }

  constructor(private readerTocService: ReaderTocService,
              private bitBookApiService: BitbookApiService) {
  }

  ngOnInit() {
    this._operationId = (this.bit?.book) + (Math.random() + 1).toString(36).substring(7);
    const event = new CustomEvent('gmbProductDetailsRequested', { detail: { productIds: [this.bit?.book], operationId: this._operationId} , bubbles: true });
    document.dispatchEvent(event);
  }

  openBook() {
    if (this.bit.book) {
      (this.bit.reference
          ? this.getBookBit(this.bit.book, this.bit.reference)
          : of(undefined)
      ).subscribe(bit => {
        this.navigateToBook.emit({bookId: this.bit.book, fragment: bit?.ref});
      }, (err) => {
        alert('You do not have access to this book.');
        this.isBookUnaccessibleToUser.emit({bookId: this.bit.book})
      });
    }
  }

  private getBookBit(bookExternalId: string, reference: string): Observable<BookEntityToc> {
    return this.readerTocService.getBitByReferenceAnchor(this.bit.book, this.bit.reference)
      .pipe(concatMap((bit: BookEntityToc) => {
        return bit
          ? of(bit)
          : this.bitBookApiService.getBookById(bookExternalId)
            .pipe(map((book: BookEntity) => book.toc.find((item: BookEntityToc) => item.anchor === reference)));
      }));
  }

  setWideImage(){

  }
}
