// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversation-text-wrapper {
  border-top: var(--bitmark-conversation-right-1-scream-bubble-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-conversation-right-1-scream-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-right-1-scream-bubble-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-conversation-right-1-scream-bubble-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-conversation-right-1-scream-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-right-1-scream-bubble-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-conversation-right-1-scream-bubble-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-conversation-right-1-scream-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-right-1-scream-bubble-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-conversation-right-1-scream-bubble-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-conversation-right-1-scream-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-right-1-scream-bubble-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-conversation-right-1-scream-bubble-border-radius, var(--bitmark-x-border-radius, y));
  padding: var(--bitmark-conversation-right-1-scream-bubble-content-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-conversation-right-1-scream-bubble-content-size-margin, var(--bitmark-x-size-margin, 0));
  background-color: var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-conversation-right-1-scream-bubble-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-conversation-right-1-scream-bubble-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-conversation-right-1-scream-bubble-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-conversation-right-1-scream-bubble-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-conversation-right-1-scream-bubble-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-conversation-right-1-scream-bubble-background-animation-name) var(--bitmark-conversation-right-1-scream-bubble-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-conversation-right-1-scream-bubble-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-conversation-right-1-scream-bubble-background-backdrop-filter);
}
.conversation-text-wrapper.with-partner {
  margin-right: -36px;
}
.conversation-text-wrapper.with-icon {
  margin-bottom: 57px;
}
.conversation-text-wrapper.without-icon {
  margin-bottom: 47px;
}

.user-avatar {
  font-family: var(--bitmark-conversation-right-1-scream-partner-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-conversation-right-1-scream-partner-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-conversation-right-1-scream-partner-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-conversation-right-1-scream-partner-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-conversation-right-1-scream-partner-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-conversation-right-1-scream-partner-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-conversation-right-1-scream-partner-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-conversation-right-1-scream-partner-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-conversation-right-1-scream-partner-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-conversation-right-1-scream-partner-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.rectangle-speech-lighting:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  bottom: -8px;
  border-radius: 0;
  background: var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-reader-content-background-color));
  transform: skew(45deg);
  right: 85px;
  box-shadow: 0 -3px 0 5px var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-reader-content-background-color)), 0 0 0 5px var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-reader-content-background-color)), 0 8px 0 5px var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-reader-content-background-color)), -8px 8px 0 5px var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-reader-content-background-color)), -16px 8px 0 5px var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-reader-content-background-color)), -24px 8px 0 5px var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-reader-content-background-color)), 0 0 0 8px var(--bitmark-conversation-right-1-scream-bubble-border-color, #5a8f00), 0 8px 0 8px var(--bitmark-conversation-right-1-scream-bubble-border-color, #5a8f00), -8px 8px 0 8px var(--bitmark-conversation-right-1-scream-bubble-border-color, #5a8f00), -16px 8px 0 8px var(--bitmark-conversation-right-1-scream-bubble-border-color, #5a8f00), -24px 8px 0 8px var(--bitmark-conversation-right-1-scream-bubble-border-color, #5a8f00);
}
.rectangle-speech-lighting:after {
  content: "";
  position: absolute;
  bottom: -41px;
  right: 54px;
  height: 20px;
  width: 40px;
  border-radius: 0 0 0 60%;
  transform: skew(45deg);
  box-shadow: 3px -2px 0 0 var(--bitmark-conversation-right-1-scream-bubble-border-color, #5a8f00) inset, 14px 0 0 0 var(--bitmark-conversation-right-1-scream-bubble-background-color, var(--bitmark-reader-content-background-color)) inset, 17px -2px 0 0 var(--bitmark-conversation-right-1-scream-bubble-border-color, #5a8f00) inset;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
