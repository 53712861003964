export * as EventEmitter from './browser-events/event-emitter';
export * from './api/pagination';
export * from './dropdown/dropdown/dropdown.model';
export * from './dropdown/dropdown/dropdown.component';
export * from './datetime-picker/date-time.model';
import {DropdownComponent} from './dropdown/dropdown/dropdown.component';

export * from './dom/content-editable.directive';
import {ContentEditableDirective} from './dom/content-editable.directive';

export * from './dropdown/dropdown-mobile/dropdown-mobile.component';
import {DropdownMobileComponent} from './dropdown/dropdown-mobile/dropdown-mobile.component';

export * from './dropdown/dropdown-desktop/dropdown-desktop.component';
import {DropdownDesktopComponent} from './dropdown/dropdown-desktop/dropdown-desktop.component';

export * from './datetime-picker/datetime-picker.component';
import {DateTimePickerComponent} from './datetime-picker/datetime-picker.component';

export * from './icon/icon.component';
import {IconComponent} from './icon/icon.component';

export * from './skeleton-loader/skeleton-loader.component';
import {SkeletonLoaderComponent} from './skeleton-loader/skeleton-loader.component';

export * from './image-viewer/image-viewer.component';
import {ImageViewerComponent} from './image-viewer/image-viewer.component';

export * from './scorm-player/scorm-player.component';
import {ScormPlayerComponent} from './scorm-player/scorm-player.component';

export * from './progess-spinner/progess-spinner.component';
import {ProgessSpinnerComponent} from './progess-spinner/progess-spinner.component';

export * from './duration-editor/duration-editor.component';
import {DurationEditorComponent} from './duration-editor/duration-editor.component';

export * from './time-editor/time-editor.component';
import {TimeEditorComponent} from './time-editor/time-editor.component';

export * from './scroll-anti-block/scroll-anti-block';
export * from './scroll-anti-block/scroll-anti-block.directive';
export * from './scroll-anti-block/modal-scroll-anti-block.service';
import {ScrollAntiBlockDirective} from './scroll-anti-block/scroll-anti-block.directive';

export * from './scroll/scroll.directive';
import {ScrollDirective} from './scroll/scroll.directive';

export * from './swipe-to-close/touch-swipe';
export * from './swipe-to-close/touch-swipe.directive';
export * from './swipe-to-close/modal-touch-swipe.service';
import {TouchSwipeDirective} from './swipe-to-close/touch-swipe.directive';

export * from './resize-element-change/resize-element-change.directive';
import {ResizeElementChangeDirective} from './resize-element-change/resize-element-change.directive';

export * from './pipes/cent-currency.pipe';
import {CentCurrencyPipe} from './pipes/cent-currency.pipe';

export * from './pipes/smart-date.pipe';
import {SmartDatePipe} from './pipes/smart-date.pipe';

export * from './pipes/linkify.pipe';
import {LinkifyPipe} from './pipes/linkify.pipe';

export * from './pipes/duration.pipe';
import {DurationPipe} from './pipes/duration.pipe';

export * from './pipes/bitmark-date.pipe';
import {BitmarkDatePipe} from './pipes/bitmark-date.pipe';

export * from './pipes/cast.pipe';
import {CastPipe} from './pipes/cast.pipe';

export * from './pipes/replace-url-resize-image.pipe';
import {ReplaceUrlResizePipe} from './pipes/replace-url-resize-image.pipe';

export * from './resource-buttons/resource-button.component';
import {ResourceButtonComponent} from './resource-buttons/resource-button.component';

export * from './document-icon/document-icon.component';
import {DocumentIconComponent} from './document-icon/document-icon.component';

export * from './user/user-box-photo/user-box-photo.component';
import {UserBoxPhotoComponent} from './user/user-box-photo/user-box-photo.component';

export * from './product-price/product-price.component';
import {ProductPriceComponent} from './product-price/product-price.component';

export * from './notebook-cover/notebook-cover.component';
import {NotebookCoverComponent} from './notebook-cover/notebook-cover.component';

export * from './notebook-cover/notebook-standard-cover/notebook-standard-cover.component';
import {NotebookStandardCoverComponent} from './notebook-cover/notebook-standard-cover/notebook-standard-cover.component';

export * from './bit-translate-language-picker/bit-translate-language-picker.component';
import {BitTranslateLanguagePickerComponent} from './bit-translate-language-picker/bit-translate-language-picker.component';

export * from './browser-events/keypress-utils.service';
export * from './browser-events/evt-utils.service';
export * from './utils/object.service';
export * from './utils/debounce.service';
export * from './utils/embed-video.service';
export * from './bitmark-text-content/bitmark-text-content.service';
export * from './url-rewrites/url-rewrite.service';
export * from './ng/ng-router-ext.service';

export * from './sub-sink/sub-sink';

export const components = [
  ContentEditableDirective,
  DropdownComponent,
  DropdownMobileComponent,
  DropdownDesktopComponent,
  DateTimePickerComponent,
  IconComponent,
  SkeletonLoaderComponent,
  ImageViewerComponent,
  ScormPlayerComponent,
  ProgessSpinnerComponent,
  DurationEditorComponent,
  TimeEditorComponent,
  ScrollAntiBlockDirective,
  ResizeElementChangeDirective,
  TouchSwipeDirective,
  LinkifyPipe,
  DurationPipe,
  BitmarkDatePipe,
  CastPipe,
  ReplaceUrlResizePipe,
  CentCurrencyPipe,
  SmartDatePipe,
  ResourceButtonComponent,
  DocumentIconComponent,
  UserBoxPhotoComponent,
  NotebookStandardCoverComponent,
  NotebookCoverComponent,
  ProductPriceComponent,
  BitTranslateLanguagePickerComponent,
  ScrollDirective
];

export const componentsExport = [
  UserBoxPhotoComponent,
  DateTimePickerComponent,
  NotebookCoverComponent,
  ProductPriceComponent,
  ScrollDirective
];

export const sharedPipes = [
  BitmarkDatePipe,
  CentCurrencyPipe,
  SmartDatePipe
];
