// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open-button-container {
  display: flex;
  justify-content: var(--bitmark-page-open-book-flex-align, flex-start);
}

.page-product-container {
  justify-content: var(--bitmark-page-open-book-flex-align, flex-start) !important;
  min-height: 30px;
}

.book-cover {
  height: 200px;
  width: 170px;
  width: var(--bitmark-page-open-book-image-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-open-book-image-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-open-book-image-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-open-book-image-size-margin, var(--bitmark-x-size-margin, 0));
}

.book-publisher {
  color: #656769;
  font-family: var(--bitmark-page-open-book-publisher-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-page-open-book-publisher-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-page-open-book-publisher-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-page-open-book-publisher-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-page-open-book-publisher-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-page-open-book-publisher-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-page-open-book-publisher-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-page-open-book-publisher-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-page-open-book-publisher-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-page-open-book-publisher-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.book-title {
  word-wrap: break-word;
  color: black;
  font-family: var(--bitmark-page-open-book-title-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-page-open-book-title-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-page-open-book-title-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-page-open-book-title-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-page-open-book-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-page-open-book-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-page-open-book-title-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-page-open-book-title-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-page-open-book-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-page-open-book-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.book-texts {
  padding-left: 2px;
  padding-right: 2px;
  width: var(--bitmark-page-open-book-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-open-book-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-open-book-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-open-book-body-size-margin, var(--bitmark-x-size-margin, 0));
  width: var(--bitmark-page-open-book-body-size-width, var(--bitmark-x-size-width, 170px));
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.btn-background-calm.open-button {
  background: var(--bitmark-page-open-book-button-background-shorthand, var(--bitmark-page-open-book-button-background, #543199));
  border-top: var(--bitmark-page-open-book-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-page-open-book-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-open-book-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-page-open-book-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-page-open-book-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-open-book-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-page-open-book-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-page-open-book-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-open-book-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-page-open-book-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-page-open-book-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-open-book-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-page-open-book-button-border-radius, var(--bitmark-x-border-radius, 30px));
  width: var(--bitmark-page-open-book-button-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-open-book-button-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-open-book-button-size-padding, var(--bitmark-x-size-padding, 0));
  padding: var(--bitmark-page-open-book-button-size-padding, var(--bitmark-page-open-book-button-size-padding, 5px 20px));
  margin: var(--bitmark-page-open-book-button-size-margin, var(--bitmark-page-open-book-button-size-margin, 10px 0 0 0));
  font-family: var(--bitmark-page-open-book-button-font-family, inherit);
  font-size: var(--bitmark-page-open-book-button-font-size, inherit);
  font-weight: var(--bitmark-page-open-book-button-font-weight, inherit);
  font-style: var(--bitmark-page-open-book-button-font-style, inherit);
  -webkit-text-decoration: var(--bitmark-page-open-book-button-font-text-decoration, inherit);
          text-decoration: var(--bitmark-page-open-book-button-font-text-decoration, inherit);
  line-height: var(--bitmark-page-open-book-button-font-line-height, inherit);
  color: var(--bitmark-page-open-book-button-font-color, #ffffff);
  text-align: var(--bitmark-page-open-book-button-font-align, inherit);
  justify-content: var(--bitmark-page-open-book-button-font-align, inherit);
}

.book-shadow {
  filter: drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
