export const translation = {
  'ToC': {
    'cover': 'inicio',
    'final': 'final'
  },
  'Auth': {
    'LogOut': 'Cerrar sesión',
    'Login': 'Iniciar sesión',
    'Register': 'Registrarse',
    'Settings': 'Configuración',
    'AlreadyHaveAnAccount': '¿Tienes ya una cuenta?',
    'Email': 'Email',
    'Password': 'Contraseña',
    'ShowPassword': 'Mostrar contraseña',
    'ResetPassword': 'Restablecer contraseña',
    'HidePassword': 'Ocultar contraseña',
    'LostPassword': '¿Olvidaste tu contraseña?',
    'SignInWith': 'Registrarse con {{socialMedia}}',
    'GoToLogin': 'Vuelve a conectarte...',
    'UseAnotherAccount': 'Usa otra cuenta...'
  },
  'Meta': {
    'ContentNotAvailable': 'Este contenido no está disponible',
    'NewVersionAvailable': 'Nueva versión disponible',
    'PleaseUpgrade': 'Por favor, actualice a la versión {{newVersion}}'
  },
  'Menu': {
    'Notebooks': 'Workspace',
    'Books': 'My Library',
    'BookShop': 'Academy'
  },
  'Contact': {
    'ContactUs': 'Contáctanos',
    'Support': 'Asistencia',
    'UpgradeAndLogout': 'Actualizar y volver a iniciar sesión',
    'ClearCache': 'Borrar caché'
  },
  'Search': {
    'ShowOnlyFreeTitles': 'Mostrar solo libros gratuitos',
    'SearchAllBooks': 'Buscar todos los libros',
    'SearchBooks': 'Buscar libros',
    'ContentForAllBooks': 'Contenido de todos los libros',
    'SearchAllBits': 'Buscar todos los Bits',
    'SearchNotebook': 'Buscar nota',
    'SearchBook': 'Buscar libro',
    'SearchChapter': 'Buscar capítulo',
    'BooksFor': 'Libros de «{{searchedText}}»',
    'ResultsByPublisher': 'Mostrar resultados por Editorial «{{searchedText}}»',
    'ResultsByAuthor': 'Mostrar resultados por Autor «{{searchedText}}»',
    'BitsFor': 'Bits de «{{searchedText}}»',
    'PaginationOutOf': '{{begin}} - {{end}} de {{total}}',
    'Previous': 'Anterior',
    'Next': 'Siguiente',
    'LastSearched': 'Última búsqueda',
    'LastViewed': 'Último visto',
    'NoBooksFound': 'No se encuentran ningún libro',
    'NoBitsFound': 'No se encuentran ningún Bit',
    'BooksNotFound': 'Libro no encontrado',
    'BitsNotFound': 'Bit no encontrado'
  },
  'Profile': {
    'Title': 'Título',
    'FirstName': 'Nombre',
    'LastName': 'Apellido',
    'Country': 'Pais',
    'Language': 'Idioma',
    'PrivateEmail': 'E-mail privado',
    'MobilePhone': 'Teléfono móvil',
    'DoubleChecking': '¡Comprobando que eres tú!',
    'ChangePassword': 'Cambiar contraseña',
    'ResetPassword': 'Restablecer contraseña',
    'YourOldPassword': 'Contraseña anterior',
    'NewPassword': 'Nueva contraseña',
    'ChangePasswordSuccess': 'Contraseña cambiada con éxito.',
    'ChangePasswordFail': 'Cambio de contraseña fallido.',
    'AddPhoto': 'Añadir foto',
    'RemovePhoto': 'Quitar foto',
    'SavePhoto': 'Guardar foto',
    'ChoosePhoto': 'Elige una foto o arrastra y pega una.',
    'UpdatePhotoFail': 'Actualización de la foto fallida.',
    'EmailEmptyError': 'El e-mail es obligatorio.',
    'PasswordEmptyError': 'La nueva contraseña es obligatoria.',
    'ImageTooLarge': 'La imagen es demasiado grande. Debe ser inferior a 3 MB',
    'ValidationMessage': 'Se ha enviado un correo electrónico de validación a su nueva dirección',
    'YourEmailAddress': 'Tu dirección de e-mail',
    'HasBeenVerified': 'ha sido verificado con éxito.',
    'ResendVerificationMailTo': 'Reenviar el correo de verificación a',
    'EmailNotVerified': 'Tu email no se ha verificado'
  },
  'Timeline': {
    'Available': 'Disponible',
    'NotAvailable': 'No disponible',
    'Reply': 'Responder',
    'Today': 'Hoy',
    'ConnectTo': 'Conectarse a ...',
    'EmailPeer': 'E-mail (de cuentas existente de Get More Brain)',
    'UserNotFound': 'No se encontró al usuario.',
    'NewMessages': 'Nuevos mensajes',
    'NewMessage': 'Nuevo mensaje',
    'TypeAMessage': 'Escriba un mensaje...',
    'ConnectMessage': 'Hola, encantado de conectar.',
    'MarkAllAsRead': 'Marcar todos los mensajes como leídos',
    'DeleteChat': 'Eliminar el chat',
    'DeleteChatConfirm': 'Realmente quieres borrar este chat?',
    'BlockUser': 'Bloquear usuario',
    'BlockUserConfirm': '¿Realmente quieres bloquear a este usuario?',
    'UnblockUser': 'Desbloquear usuario',
    'UnblockUserConfirm': '¿Realmente quieres desbloquear a este usuario?',
    'ConnectionRequest': 'Solicitud de conexión',
    'CreateEntry': {
      'AddMultiQuizContinue': 'Añadir un Multi-quiz...',
      'AddBlankQuiz': 'Añadir un Blank-quiz',
      'OpenLinkInBrowser': 'Abrir enlace en el navegador',
      'CopyLink': 'Copiar enlace',
      'AddHintContinue': 'Añadir pista...',
      'EditLinkContinue': 'Editar enlace...',
      'EditHintContinue': 'Editar pista....',
      'RemoveHint': 'Eliminar pista',
      'Unlink': 'Deshacer enlace',
      'Video': 'Video',
      'TakePicture': 'Have una foto o busca en tu biblioteca',
      'PhotoLibrary': 'Biblioteca de fotos',
      'Audio': 'Audio',
      'Document': 'Documento',
      'Emoji': 'Emoji'
    },
    'Actions': {
      'YouDeletedThisMessage': 'Has borrado este mensaje',
      'ThisMessageWasDeleted': 'Este mensaje ha sido borrado',
      'ThisMessageIsHidden': 'Este mensaje está oculto',
      'HideSolution': 'Ocultar respuesta',
      'RevealSolution': 'Mostrar respuesta',
      'Verify': 'Verificar',
      'Repeat': 'Repetir',
      'TextContinue': 'Texto...',
      'HrefContinue': 'Href...',
      'HintContinue': 'Pista...',
      'PasteVideoLink': 'Pegue un enlace de vídeo abajo',
      'BrowserDoesNotSupportAudio': 'Tu navegador no admite el elemento de audio.',
      'BrowserDoesNotSupportVideo': 'Tu navegador no admite el elemento de video.',
      'ChooseDocument': 'Elige un documento o arrastra y pega uno.',
      'ChooseAudio': 'Elige un audio o arrastra y pega uno.',
      'ErrorConnecting': 'Error de conexión con {{email}}',
      'UserNotFound': 'El usuario {{email}} no ha sido encontrado',
      'PasteAndSendBit': 'Pegar y enviar Bits',
      'SendBasket': 'Enviar Cesta',
      'ErrorUploadingPdf': 'We could not convert your pdf. The problem was logged. We are working on a solution to convert more complex pdf\'s in the future.'
    },
    'Quiz': {
      'CannotSend': 'No puedes enviar el cuestionario ahora',
      'CannotSendWithoutGap': 'No puede enviar un cuestionario sin ningún espacio.'
    },
    'SearchAll': 'Todos los usuarios'
  },
  'Notebooks': {
    'UntitledNotebook': 'Nota sin título',
    'UntitledDraftBook': 'Libro sin titulo',
    'UntitledFlashcards': 'Tarjetas sin titulo',
    'Title': 'Workspace',
    'PersonalNotebooks': 'Mis notas',
    'Homework': 'Deberes',
    'SmartNotebooks': 'Notas inteligentes',
    'DeleteConfirm': '¿Realmente quieres borrar tu nota? «{{notebook}}»? ¡Esto no se puede deshacer!',
    'Notebook': 'Nota',
    'Worksheet': 'Hoja de trabajo',
    'Flashcards': 'Tarjetas',
    'RecentQuizzes': 'Quizzes recientes',
    'RecentVideos': 'Videos recientes',
    'LearningPath': 'Aprendizaje',
    'DraftBook': 'Proyecto de libro',
    'DropFileHere': 'Suelta el archivo aquí',
    'DropFileAllowedTypes': 'Sólo se le permite soltar archivos PDF o de marca de bits'
  },
  'HandIn': {
    'Assign': 'Asignar...',
    'AssignToMe': 'Asignar a mí',
    'Locked': 'Bloqueado',
    'Today': 'hoy',
    'Yesterday': 'ayer',
    'YourHandInApproved': 'Su entrega ha sido aprobada',
    'YourHandInRejected': 'Su entrega ha sido rechazada',
    'PleaseReadComments': 'Por favor, lea atentamente los comentarios e inténtelo de nuevo.',
    'BookWasHandedIn': 'Libro entregado',
    'By': 'por',
    'YouHaveApprovedThis': 'Ha aprobado esto',
    'For':'por',
    'YouHaveRejectedThis': 'Ha rechazado esto',
    'PleaseHandInTheBookReady':'Por favor, entregue el libro cuando esté listo.',
    'PleaseHandInTheBookUntil': 'Por favor, entregue el libro hasta',
    'YourBookWillBeLocked': 'Tu libro se bloqueará 🔒 (sólo ver) después de la entrega.',
    'BookWasHandedInSuccessfully': 'El libro ha sido entregado correctamente.',
    'YourWorkOnThisBookWasApproved': '¡Tu trabajo en este libro ha sido aprobado!',
    'ThereWasAProblemHandingIn': 'Hubo un problema al entregar el libro',
    'ThereWasAnErrorAccepting': 'Se ha producido un error al aceptar la entrega',
    'ThereWasAnErrorAssigning': 'Se ha producido un error al asignar la entrega',
    'ErrorNoExperts':'No hay expertos para asignar',
    'NoNewHandIns':'No hay nuevos entregas',
    'NoAssignedHandIns': 'No hay entregas asignadas',
    'NoHandIns':'No hay entregas',
    'NewHandIns':'No asignado',
    'AssignedToMe':'Mis revisiones',
    'All':'Aprobado'
  },
  'Books': {
    'MyBooks': 'Libros',
    'Books': 'libros',
    'Courses': 'Courses',
    'MyClasses': 'Mis Clases',
    'RecentlyUsed': 'Usado recientemente',
    'SelfLearning': 'Apprendimento autodeterminato',
    'Purchased': 'Comprado',
    'All': 'Todos',
    'Unbuy': 'Devoluciones',
    'UnbuyTraining': 'Cancelar la formación',
    'ChatWithBook': 'Chat with Book',
    'Publisher': 'Editorial',
    'Authors': 'Autores',
    'Published': 'Publicado',
    'Updated': 'Actualizado',
    'Language': 'Idioma',
    'ISBN': 'ISBN',
    'GetFreeBooksFromShop': 'Obtener libros gratuitos y de pago de la',
    'CostsCoveredBy': 'Costes cubiertos por {{company}}',
    'Pages': 'Páginas',
    'AlsoAvailableIn': 'También disponible en',
    'Series': 'Series «{{seriesName}}»',
    'CurrentSeries': 'Series actuales',
    'OnlyPaper': 'Libro sólo disponible en papel.',
    'VoteMakeBookAvailable': 'Vote para que esté disponible en línea',
    'DigitalMaterial': 'Pero tenemos buen material digital adicional a este libro.',
    'OneVoteToMakeAvailable': '{{numberOfVotes}} vote para que esté disponible en línea',
    'VotesToMakeAvailable': '{{numberOfVotes}} vote para que esté disponible en línea',
    'Types': {
      'Flashcards': 'Cartas',
      'PPT': 'PPT',
      'Word': 'Word',
      'Excel': 'Excel',
      'PDF': 'PDF',
      'Script': 'Script',
      'Quizzes': 'Quizzes',
      'Video': 'Video',
      'VideoLecture': 'Video-lección',
      'LTIContainer': 'LTI contenedor',
      'WBT': 'Formación on-line (WBT)',
      'Audio': 'Audio',
      'LiveBook': 'Live Book',
      'Homework': 'Deberes'
    }
  },
  'Shared': {
    'BroadcastSubtitle': 'Transmitido a todos los estudiantes',
    'Join': 'Únase a',
    'Edit': 'Editar',
    'Edited': 'Edited',
    'EditContinue': 'Editando...',
    'SetContinue': 'Establecer...',
    'Remove': 'Eliminar',
    'Cancel': 'Cancelar',
    'OK': 'OK',
    'Search': 'Buscar',
    'SearchStudents': 'Buscar Estudiantes',
    'Open': 'Abrir',
    'OpenContinue': 'Abrir...',
    'Download': 'Descargar',
    'Share': 'Compartir',
    'Send': 'Enviar',
    'Show': 'Mostrar',
    'ShowHiddenFields': 'Mostrar Todos los Campos...',
    'HideHiddenFields': 'Ocultar Todos los Campos',
    'SendContinue': 'Enviando...',
    'Sending': 'Enviando',
    'SendingContinue': 'Enviando...',
    'Rename': 'Renombrar',
    'Replace': 'Reemplazar',
    'Delete': 'Borrar',
    'DeleteContinue': 'Borrando...',
    'DeletePermanentlyContinue': 'Borrar permanentemente...',
    'DeleteImmediately': 'Eliminar inmediatamente',
    'Save': 'Guardar',
    'Sell': 'Vender',
    'For': 'para',
    'Hide': 'Ocultar',
    'Free': 'Gratis',
    'Buy': 'Comprar',
    'Unbuy': 'Devolver',
    'Here': 'Aquí',
    'Continue': 'Continuar',
    'Name': 'Nombre',
    'Favorites': 'Favoritos',
    'Copy': 'Copiar',
    'Paste': 'Pegar',
    'PasteSpecial': 'Special Pegar...',
    'Cut': 'Cortar',
    'Undo': 'Deshacer',
    'Accept': 'Aceptar',
    'Reject': 'Rechazar',
    'Revoke': 'Revocar',
    'SentOn': 'Enviado el',
    'RejectedOn': 'Rechazado el',
    'RevokedOn': 'Revocado el',
    'UploadPDF': 'Cargar PDF',
    'Duplicate': 'Duplicar',
    'Page': 'Página',
    'MarkAsDone': 'Marcar como listo',
    'RateAndMarkAsDone': 'Rate and Mark as Done ✅',
    'HandIn': 'Entregar',
    'Choose': 'Elegir',
    'ChooseContinue': 'Seleccione...',
    'CloseConfirm': '¿Estas seguro que deseas cerrar?',
    'CloseModalConfirm': '¿Estas seguro que deseas cerrar la ventana?',
    'Retry': 'Reintentar',
    'True': 'Verdadero',
    'False': 'Falso',
    'Start': 'Lanzar',
    'ChooseBook': 'Seleccionar libro',
    'ChooseChapter': 'Seleccionar capítulo',
    'Book': 'Libro',
    'Chapter': 'Capítulo',
    'Video': 'Video',
    'CameraContinue': 'Cámara...',
    'UploadContinue': 'Subir...',
    'EmbedVideoContinue': 'Incrustar (Youtube, Vimeo)...',
    'Audio': 'Audio',
    'Voice': 'Mensaje de voz',
    'Image': 'Imagen',
    'UrlContinue': 'URL...',
    'Documents': 'Documentos',
    'LinkContinue': 'Enlace...',
    'Website': 'Sitio web',
    'ManageContinue': 'Organize...',
    'Caption': 'Subtítulo',
    'Attempts': 'Intentos',
    'Close': 'Cerca'
  },
  'Shop': {
    'Price': 'Precio',
    'TotalPrice': 'Precio total',
    'YouAreBuyingFor': 'Estás comprando para',
    'Shop': 'Academy',
    'PurchaseSuccessful': 'Compra realizada con éxito',
    'AddedSuccessfully': 'Añadido con éxito',
    'PurchaseFailed': 'Error en la compra',
    'BookNotFound': 'Libro no encontrado.',
    'AllBooksNext': 'Todow los libros »',
    'SubCategories': 'Subcategorías',
    'MoreInfo': 'Details...',
    'MoreInfoContinue': 'Más información...',
    'ThisIsAdditional': 'This is a Supporting Material for',
    'SupportingMaterial': 'Supporting Material',
    'ThanksForJoining': '¡Gracias por participar!',
    'Publish': 'Publish',
    'EditContinue': 'Edit...',
    'EditLP': 'Learning Path...',
    'InvitedBy': 'Has sido invitado por {{user}}',
    'InvitationFor': 'Invitación para',
    'GetAnotherCopy': 'Obtenga otra copia',
    'Editor': {
      'CopyEmailAddress': 'Copy E-mail Address',
      'Chat': 'Chat',
      'SendEmail': 'Send Email...',
      'AddFlag': 'Set Flag',
      'CopyAll': 'Copy All',
      'ReplaceAll': 'Replace All...',
      'AddSeveral': 'Add Several...',
      'ResendInvitation': 'Resend Invitation ✉️',
      'ByInvitationOnly': 'This class is by invitation only (it cannot be bought or joined)',
      'ClosedCourse': 'Closed Course - Participants are managed by Organizers only',
      'Content': 'Content',
      'ShopPage': 'Shop Page',
      'CustomShopPage': 'Custom Shop Page',
      'NewRelease': 'New Release',
      'InviteUsersToBuy': 'Invite Users to Buy...',
      'InviteUsersToJoin': 'Invite Users to Join...',
      'BuyForUsers': 'Buy For Users...',
      'JoinForUsers': 'Añadir usuario como participante...',
      'BuyForUser': 'Buy For User...',
      'JoinForUser': 'Join User...',
      'DeleteAll': 'Borrar todo',
      'SortByEmail': 'Sort by Email',
      'SortByFirstName': 'Sort by First Name',
      'SortByLastName': 'Sort by Last Name',
      'SendReleaseNotes': 'Send Release Notes'
    },
    'Course': {
      "Description": "Description",
      'Content': 'Contenido',
      'UpcomingDates': 'Clases',
      'CourseContent': 'Contenido del curso',
      'CourseLessons': 'Lecciones',
      'CourseStartDate': 'Start',
      'CourseBooks': 'Course Books',
      'TheCurriculum': 'El plan de estudios',
      'BuyForAll': 'Comprar para los participantes',
      'UpdateInvitations': 'Actualizar las invitaciones',
      'Now': 'Ahora',
      'Next': 'Próxima',
      'Done': 'Hecho',
      'Participants': 'Participantes',
      'Organizers': 'Organizadores',
      'Coaches': 'Entrenadores',
      'Editors': 'Editores',
      'InvitationsUpdated': 'Se han actualizado las invitaciones para todos los participantes.',
      'BoughtForParticipants': 'Todos los participantes se han incorporado al curso.',
      'CopyLink': 'Copiar Enlace',
      'Duration': 'Duración',
      'Effort': 'Esfuerzo',
      'Format': 'Formato',
      'ClassSize': 'Tamaño de la clase',
      'Career': 'Carrera',
      'Tuition': 'Tasas de matrícula',
      'MoveUp': 'Move up',
      'MoveDown': 'Move down'
    }
  },
  'Bits': {
    'With': 'Para',
    'SuccessfullySent': 'Enviado con éxito',
    'ToUser': 'para {{userName}}',
    'Recent': 'Reciente',
    'ModuleDone': 'Módulo Listo',
    'HadRead': 'leído',
    'SendAt': 'enviar a',
    'Item': 'Número',
    'Lead': 'Lead',
    'Location': 'Ubicación',
    'Instruction': 'Instrucción',
    'Action': 'Tarea',
    'Duration': 'Duración',
    'From': 'De',
    'To': 'A',
    'Quiz': 'Cuestionario',
    'PlaceholderBody': '...',
    'NoResourceSelected': 'No hay recurso seleccionado',
    'GetFeedback': 'Obtener comentarios',
    'DoItAgain': 'hazlo de nuevo',
    'FinalFeedback': 'Retroalimentación final',
    'FeedbackRetryOrSubmitMessage': 'Obtuviste {{correct}} de {{total}} respuestas correctas. Puedes repetir el ejercicio o enviar el resultado.',
    'Generate': 'Generar'
  },
  'Reader': {
    'ShowAll': 'Mostrar todo',
    'CreateNewNotebook': 'Crear nuevo «{{untitledNotebook}}»',
    'Search': {
      'Search': 'Búsqueda...',
      'LastSearched': 'Última búsqueda',
      'NoResultsFound': 'No se han encontrado resultados.'
    },
    'Editor': {
      'NewChapter': 'Nuevo capítulo',
      'Title': 'Título',
      'Subtitle': 'Subítulo',
      'Text': 'Texto',
      'Code': 'Código',
      'Math': 'Matemáticas',
      'Link': 'Enlace',
      'Quote': 'Cita',
      'ScientificQuote': 'Scientific Quote',
      'HistoricQuote': 'Historic Quote',
      'List': 'Lista',
      'NumberList': 'Lista Numerada',
      'LetterList': 'Lista de Letras',
      'RomanList': 'Lista Romana',
      'TaskList': 'Lista de Tareas',
      'NoBullets': 'Sin Balas',
      'AddNote': 'Añadir Nota',
      'AddHandwritten': 'Añadir Scribble',
      'AddQuickNote': 'Añadir Nota Rápida',
      'TypeSomething': 'Escribe Algo ...',
      'InvalidBitmark': 'bitmark no válida',
      'ImageLeftAligned': 'Alineado a la izquierda',
      'ImageRightAligned': 'Alineado a la derecha',
      'ImageCenterAligned': 'Centrado'
    },
    'Handwritten': {
      'WaitingForImage': 'Esperando imagen',
      'ErrorLoadingImage': 'Error de carga de la imagen',
      'LoadingImage': 'Cargando imagen',
      'PreparingImage': 'Preparando imagen',
      'ColorPalette': 'Paleta de color',
      'ExtraSmall': 'Extra pequeña',
      'Small': 'Pequeña',
      'MediumSmall': 'Mediana pequeña',
      'Medium': 'Mediano',
      'MediumLarge': 'Mediano grande',
      'Large': 'Grande',
      'ExtraLarge': 'Extra grande',
    },
    'Filters': {
      'Quiz': 'Contenido',
      'Resources': 'Recursos',
      'Grade': 'Grado',
      'Complexity': 'Complejidad',
      'Tags': 'Etiquetas',
      'Skills': 'Habilidades'
    },
    'Actions': {
      'Send': 'Enviar',
      'SendContinue': 'Enviando...',
      'SendToClass': 'Transmitido a todos los estudiantes...',
      'Broadcast': 'Transmitido',
      'SendBook': 'Enviar libro',
      'SendAll': 'Enviar todo',
      'DeleteBit': 'Borrar Bit',
      'DeleteAll': 'Borrar todo',
      'SaveToNotebook': 'Guardardo en notas...',
      'SaveToAnotherNotebook': 'Guardar en otra nota...',
      'ResetBookRating': 'Reset Book Rating',
      'SaveAllToNotebook': 'Guardar todo en notas',
      'FindInBook': 'Encontrar en el libro',
      'SuccessfullyStored': '¡Guardado con éxito!',
      'SavingTo': 'Guardando en «{{notebookName}}»',
      'SuccessfullyStoredToNotebook': 'Guardado con éxito en la nota «{{notebookName}}»',
      'ErrorOcurredStoring': 'Se ha producido un error al guardar en «{{notebookName}}»',
      'CopyBitmarkType': 'Copiar bitmark {{bitmarkType}}',
      'EditBitmarkType': 'Editar bitmark {{bitmarkType}}',
      'BrandingPublisherTheme': 'Editor {{publisherName}} {{newline}} {{publisherId}}/{{theme}}',
      'ExtractText': 'ExtractText',
      'CopyBitmarkJSON': 'Copiar bitmark JSON',
      'CopyLinkToBit': 'Copiar enlace a esta bit',
      'CopyAllBitsToBasket': 'Copiar todos los bits a la "Basket"',
      'ResetAnswer': 'Restablecer respuesta',
      'ResetHighlights': 'Restablecer aspectos destacados',
      'JoinCall': 'Unirse a la llamada',
      'Add': 'Añadir',
      'AddChapter': 'Añadir Chapter',
      'AddCard': 'Añadir Tarjeta',
      'UploadCards': 'Subir Tarjetas...',
      'PasteQuizletCards': 'Pegar tarjetas didácticas de Quizlet (TSV)',
      'PasteStepsScribePro': 'Pegar pasos desde Scribe Pro (Markdown)',
      'AddBit': 'Añadir Capítulo',
      'AddLearningPathLTI': 'Añadir un paso LTI',
      'AddLearningPath': 'Añadir un paso',
      'EditLearningPathContinue': 'Editar ruta de aprendizaje...',
      'LearningPathGoal': 'objetivos de aprendizaje',
      'LearningPathReadBook': 'leer el libro',
      'LearningPathStep': 'paso de aprendizaje',
      'LearningPathVideoCall': 'videollamada',
      'LearningPathExternalLink': 'enlace externo',
      'LearningPathLTI': 'Recurso LTI',
      'LearningPathClassroomEvent': 'evento en el aula',
      'LearningPathClosing': 'finalización',
      'AddBotMessage': 'añadir mensaje del bot',
      'AddBotAction': 'agregar acción del bot',
      'BotActionSend': 'enviar mensaje',
      'AddNote': 'Añadir la nota',
      'AddHandwritten': 'Añadir Scribble',
      'AddBookmark': 'Añadir marcador',
      'AddFavorite': 'Añadir favorito',
      'AddArticle': 'Añadir bloque de texto',
      'AddQuote': 'Añadir Cita',
      'ExampleNotes': 'Notas de Ejemplo',
      'InsertBasket': 'Insertar Basket',
      'SetCover': 'Cargar Cubierta Personalizada...',
      'RemoveCover': 'Eliminar la Cubierta Personalizada',
      'CoverStyle': 'Estilo de Cubierta',
      'DefaultCover': 'Imagen en portada',
      'FullCover': 'Imagen llena la portada',
      'CustomCover': 'Cubierta Personalizada',
      'ErrorSetCover': 'Esta imagen es demasiado pequeña. Proporcione una imagen que tenga al menos 700 px de ancho o 1000 px de alto.',
      'AddAuthorNote': 'Agregar nota de autor',
      'AddReviewerNote': 'Agregar nota del revisor',
      'AddInfo': 'Agregar información',
      'AddSideNote': 'Agregar nota al margen',
      'AddRemark': 'Agregar comentario',
      'AddExample': 'Agregar ejemplo'
    },
    'Basket': {
      'Basket': 'Basket',
      'EmptyBasket': ' Vaciar Basket',
      'AddToBasket': 'Colocar en la Basket',
      'RemoveFromBasket': 'Retirar de la Basket',
      'SendAll': 'Enviar todo',
      'SaveAll': 'Guardar todo'
    },
    'Bin': {
      'RestoreAll': 'poner todo de vuelta',
      'EmptyBin': 'Papelera vacía',
      'Restore': 'Volver',
      'MoveToBin': 'Mover a la papelera',
      'DeleteBitConfirmation': '¿Estás segura de que quieres eliminar este bit?'
    },
    'Bits': {
      'Level': 'Nivel'
    },
    'Translate': {
      'TranslateTitle': 'Traducir...',
      'ChooseLanguage': 'Elige lengua',
      'SearchLanguages': 'Idiomas de búsqueda',
      'RecentLanguages': 'Idiomas recientes',
      'AllLanguages': 'Todos los idiomas'
    }
  },
  'System': {
    'SystemTitle': 'System',
    'Bin': 'Papelera'
  },
  'Cockpit': {
    'AverageCompletedActivities': 'Promedio de actividades completadas',
    'AverageCorrectAnswers': 'Promedio de respuestas correctas',
    'NoDataYet': 'No hay datos todavía'
  }
};
