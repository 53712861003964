import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'bitmark-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: []
})
export class ImageViewerComponent implements OnInit, OnDestroy {
  @Input() imageUrl: string | SafeUrl;
  @Input() width: number;
  @Input() height: number;

  isLandscapeScreen = false;
  isLandscapeImage = false;
  wrapperCssStyle = {};
  imageCssStyle = {};

  constructor(private ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit() {
    const infinite = '1000%';
    this.isLandscapeImage = this.width > this.height;
    this.isLandscapeScreen = screen.width >= 480;
    this.wrapperCssStyle = {
      'height': 'calc(100vh - 110px)',
      'overflow-y': this.isLandscapeImage && this.isLandscapeScreen ? 'hidden' : 'auto',
      'overflow-x': this.isLandscapeImage && this.isLandscapeScreen ? 'auto' : 'hidden'
    };
    this.imageCssStyle = {
      'width': this.isLandscapeImage && this.isLandscapeScreen ? 'auto' : `${this.width}px`,
      'max-width': this.isLandscapeImage && this.isLandscapeScreen ? infinite : 'calc(100vw - 60px)',
      'height': this.isLandscapeImage && this.isLandscapeScreen ? `${this.height}px` : 'auto',
      'max-height': this.isLandscapeImage && this.isLandscapeScreen ? 'calc(100vh - 110px)' : infinite
    };
    if (!this.isLandscapeScreen) {
      document
        .getElementById('viewport')
        .setAttribute('content', 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, shrink-to-fit=no, user-scalable=yes, viewport-fit=cover');
    }
  }

  ngOnDestroy() {
    document
      .getElementById('viewport')
      .setAttribute('content', 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover');
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
