// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bit-resource-container {
  max-width: 100%;
}

.edit-choice-container {
  gap: 10px;
  background-color: #efefef;
  padding: 30px 0;
  border-radius: 5px;
  font-family: "Roboto", "sans-serif";
}

.resource-edit {
  border: 2px solid #efefef;
  border-radius: 10px;
}

.resource-edit-audio {
  width: calc(100% - 40px) !important;
}

.empty-resource-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
  padding: 0;
  border-radius: 5px;
  color: #fff;
}
.empty-resource-btn .trash-icon {
  width: 18px;
  height: 18px;
}

.empty-resource-btn-for-audio {
  top: 50%;
  right: 10px;
  transform: translate(0%, -50%);
}

.progress-status {
  width: 100%;
  text-align: center;
  padding: 5px;
  border: 2px solid #efefef;
  border-radius: 10px;
}
.progress-status .progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-status .progress-container .progress {
  width: 200px;
  border: 2px solid #000000;
  border-radius: 10px;
  padding: 2px;
  height: 20px;
  background-color: #ffffff;
}
.progress-status .progress-container .progress .progress-bar {
  background-color: #543199;
  border-radius: 10px;
}

.progress-status-video {
  background-color: #000000;
  color: #ffffff;
}
.progress-status-video .progress {
  background-color: #b1b1b1 !important;
}
.progress-status-video .progress-youtube-logo {
  padding-bottom: 35px;
  padding-top: 73px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
