import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EssayBit} from './essay.models';
import {BitResource} from '../bits.models';
import {ReaderContentWidth} from '../../shared/models/bitmark.models';
import {BitEvalResult} from '../bit-eval.service';

@Component({
  selector: 'bitmark-essay',
  templateUrl: './essay.component.html',
  styleUrls: ['../bits.scss']
})
export class EssayComponent implements OnInit {
  private _bit?: EssayBit;
  @Input() set bit(value: EssayBit) {
    this._bit = value;
    if (this.initialized && !value.answer) {
      this.wordBankItems = [];
      this.computeWordBank();
    }
  }
  get bit(): EssayBit {
    return this._bit;
  }
  @Input() readOnly = false;
  @Input() allowAnswerSubmit = true;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() focused = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<any>();

  rowsCount: number;
  wordBankItems: Array<{ text: string, used: boolean }> = [];

  private initialized = false;

  ngOnInit() {
    this.computeWordBank();

    if (this.bit?.reasonableNumOfChars && this.bit?.reasonableNumOfChars > 0) {
      const charsPerLine = window.innerWidth < ReaderContentWidth ? 25 : 50;
      this.rowsCount = Math.floor(this.bit.reasonableNumOfChars / charsPerLine) + 1;
    }

    this.initialized = true;
  }

  private computeWordBank() {
    if (this.bit.additionalSolutions?.length) {
      this.bit.additionalSolutions.forEach(x => {
        this.wordBankItems.push({text: x, used: this.bit.answer?.usedSolutions?.includes(x)});
      });
    }

    this.wordBankItems.sort();
  }

  toggleWordBankSolution(item: { text: string, used: boolean }) {
    item.used = !item.used;

    if (!this.bit.answer?.usedSolutions) {
      this.bit.answer = {
        usedSolutions: [item.text]
      };
    } else {
      if (this.bit.answer.usedSolutions.includes(item.text)) {
        this.bit.answer.usedSolutions = this.bit.answer.usedSolutions.filter(x => x !== item.text);
      } else {
        this.bit.answer.usedSolutions = [...this.bit.answer.usedSolutions, item.text];
      }
    }

    this.changed.emit();
  }

  change(evt: Event) {
    this.bit.answer = {text: (evt.target as any)?.value};
    this.changed.emit();
  }

  static evalAnswer(bit: EssayBit): BitEvalResult {
    return {
      totalAnswers: 1,
      answeredAnswers: !!bit.answer?.text ? 1 : 0,
      notAnsweredAnswers: !bit.answer?.text ? 1 : 0
    };
  }
}
