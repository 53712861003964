import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {arSA, cnCN, csCZ, deDE, esES, faIR, frFR, idID, itIT, trTR, jaJP, koKR, nbNO, ptBR, ptPT, ruRU, svSE, uaUA} from 'gridjs/l10n';
import {TableBit} from './table.models';

const languagesSet = {
  'ar': arSA,
  'cn': cnCN,
  'cs': csCZ,
  'de': deDE,
  'es': esES,
  'fa': faIR,
  'fr': frFR,
  'id': idID,
  'it': itIT,
  'tr': trTR,
  'ja': jaJP,
  'ko': koKR,
  'nb': nbNO,
  'pt': ptBR,
  'ptPT': ptPT,
  'ru': ruRU,
  'sv': svSE,
  'ua': uaUA
};

@Component({
  selector: 'bitmark-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() bit?: TableBit;

  className: any;
  language: any;
  paginationConfig: any;
  columns: Array<any> = [];
  asyncData: any;
  @ViewChild('grid') grid: ElementRef;

  ngOnInit() {
    const isHeaderVisible = !!this.bit?.table?.columns?.length;

    this.className = {
      container: `bitmark-table-container ${this.bit?.tablePagination ? 'bitmark-table-container-paginated' : ''}`,
      table: `bitmark-table ${this.bit?.tableWhitespaceNoWrap ? 'bitmark-table-no-wrap' : ''}`,
      thead: `bitmark-thead ${isHeaderVisible ? '' : 'bitmark-thead-hidden'}`,
      tbody: 'bitmark-tbody',
      footer: 'bitmark-tfooter',
      pagination: 'bitmark-tfooter-pagination',
      paginationButton: 'bitmark-tfooter-pagination-button'
    };

    const tableColumns = isHeaderVisible
      ? this.bit?.table?.columns
      : this.bit?.table?.data[0]?.map(() => '');

    const columnIds = tableColumns?.map(() => {
      return this.uuidV4();
    });

    this.columns = tableColumns?.map((x, index) => ({
      id: columnIds[index],
      name: x,
      resizable: this.bit?.tableResizableColumns,
      sort: this.bit?.tableSort
    }));

    const tableData = this.bit?.table?.data.map(x => {
      const newObj = {};

      x.forEach((y, index) => {
        newObj[columnIds[index]] = y;
      });

      return newObj;
    });
    // this.asyncData = tableData || [];
    this.asyncData = () => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(tableData || []);
        }, 500);
      });
    };

    if (this.bit?.lang) {
      this.language = languagesSet[this.bit.lang] || null;
    }
    if (this.bit?.tablePagination) {
      this.paginationConfig = this.bit?.tablePaginationLimit
        ? {limit: this.bit.tablePaginationLimit}
        : true;
    }

    if (this.bit?.tableSearch) {
      setTimeout(() => {
        (this.grid as any)?.getGridInstance()?.updateConfig({search: true})?.forceRender();
      }, 550);
    }
  }

  private uuidV4(): string {
    const stringArr = [];
    for (let i = 0; i < 4; i++) {
      const s4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(s4);
    }
    return stringArr.join('-');
  }
}
