// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes wiggle {
  0%, 100% {
    transform: rotate(-3deg);
  }
  50% {
    transform: rotate(3deg);
  }
}
.wiggle-animation {
  animation: wiggle 0.1s ease;
  animation-iteration-count: infinite;
}

.rating-container .add-to-library {
  padding: 5px 0 0 0;
  border-radius: 100%;
  min-width: inherit;
  width: 40px;
  height: 40px;
  border: none;
}
.rating-container .star {
  display: inline-block;
  width: 60px;
  height: 60px;
}
.rating-container .star img {
  width: 100%;
}
.rating-container .star .star-filled {
  border-top: var(--bitmark-virtual-mark-book-as-read-filled-star-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-virtual-mark-book-as-read-filled-star-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-filled-star-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-virtual-mark-book-as-read-filled-star-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-virtual-mark-book-as-read-filled-star-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-filled-star-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-virtual-mark-book-as-read-filled-star-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-virtual-mark-book-as-read-filled-star-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-filled-star-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-virtual-mark-book-as-read-filled-star-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-virtual-mark-book-as-read-filled-star-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-filled-star-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-virtual-mark-book-as-read-filled-star-border-radius, var(--bitmark-x-border-radius, 30px));
  width: var(--bitmark-virtual-mark-book-as-read-filled-star-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-virtual-mark-book-as-read-filled-star-size-height, var(--bitmark-x-size-height, inherit));
  font-family: var(--bitmark-virtual-mark-book-as-read-filled-star-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-virtual-mark-book-as-read-filled-star-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-virtual-mark-book-as-read-filled-star-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-virtual-mark-book-as-read-filled-star-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-virtual-mark-book-as-read-filled-star-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-virtual-mark-book-as-read-filled-star-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-virtual-mark-book-as-read-filled-star-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-virtual-mark-book-as-read-filled-star-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-virtual-mark-book-as-read-filled-star-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-virtual-mark-book-as-read-filled-star-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.rating-container .star .star-filled path {
  fill: var(--bitmark-virtual-mark-book-as-read-filled-star-background, white);
  stroke: var(--bitmark-virtual-mark-book-as-read-filled-star-background, white);
}
.rating-container .star .star-empty {
  border-top: var(--bitmark-virtual-mark-book-as-read-empty-star-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-virtual-mark-book-as-read-empty-star-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-empty-star-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-virtual-mark-book-as-read-empty-star-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-virtual-mark-book-as-read-empty-star-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-empty-star-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-virtual-mark-book-as-read-empty-star-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-virtual-mark-book-as-read-empty-star-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-empty-star-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-virtual-mark-book-as-read-empty-star-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-virtual-mark-book-as-read-empty-star-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-empty-star-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-virtual-mark-book-as-read-empty-star-border-radius, var(--bitmark-x-border-radius, 30px));
  width: var(--bitmark-virtual-mark-book-as-read-empty-star-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-virtual-mark-book-as-read-empty-star-size-height, var(--bitmark-x-size-height, inherit));
  font-family: var(--bitmark-virtual-mark-book-as-read-empty-star-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-virtual-mark-book-as-read-empty-star-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-virtual-mark-book-as-read-empty-star-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-virtual-mark-book-as-read-empty-star-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-virtual-mark-book-as-read-empty-star-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-virtual-mark-book-as-read-empty-star-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-virtual-mark-book-as-read-empty-star-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-virtual-mark-book-as-read-empty-star-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-virtual-mark-book-as-read-empty-star-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-virtual-mark-book-as-read-empty-star-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.rating-container .star .star-empty path {
  stroke: var(--bitmark-virtual-mark-book-as-read-empty-star-background, white);
}
.rating-container .buy-button-container {
  display: flex;
  justify-content: var(--bitmark-virtual-mark-book-as-read-flex-align, flex-start);
}
.rating-container .btn-background-calm {
  border-radius: 30px;
  padding: 5px 15px;
  margin-top: 15px;
}
.rating-container .btn-background-calm.buy-button {
  background: var(--bitmark-virtual-mark-book-as-read-button-background-shorthand, var(--bitmark-virtual-mark-book-as-read-button-background, #543199));
  border-top: var(--bitmark-virtual-mark-book-as-read-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-virtual-mark-book-as-read-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-virtual-mark-book-as-read-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-virtual-mark-book-as-read-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-virtual-mark-book-as-read-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-virtual-mark-book-as-read-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-virtual-mark-book-as-read-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-virtual-mark-book-as-read-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-mark-book-as-read-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-virtual-mark-book-as-read-button-border-radius, var(--bitmark-x-border-radius, 30px));
  width: var(--bitmark-virtual-mark-book-as-read-button-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-virtual-mark-book-as-read-button-size-height, var(--bitmark-x-size-height, inherit));
  font-family: var(--bitmark-virtual-mark-book-as-read-button-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-virtual-mark-book-as-read-button-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-virtual-mark-book-as-read-button-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-virtual-mark-book-as-read-button-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-virtual-mark-book-as-read-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-virtual-mark-book-as-read-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-virtual-mark-book-as-read-button-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-virtual-mark-book-as-read-button-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-virtual-mark-book-as-read-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-virtual-mark-book-as-read-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  padding: var(--bitmark-virtual-mark-book-as-read-button-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-virtual-mark-book-as-read-button-size-margin, var(--bitmark-x-size-margin, 0));
  margin: var(--bitmark-virtual-mark-book-as-read-button-size-margin, 15px 0 5px 0);
  padding: var(--bitmark-virtual-mark-book-as-read-button-size-padding, 5px 15px);
  color: var(--bitmark-virtual-mark-book-as-read-button-color, #ffffff);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
