import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookSource, BookSourcesBit } from './book-sources.models';
import {BitmarkFormat, ProductFamily} from '../../shared/models/bitmark.models';
import { TocItem } from '../../reader';
import { BitmarkPipe } from '../bitmark.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ReaderTipTapTapService } from '../../reader/tiptap/reader-tiptap.service';
import {UrlRewriteService} from '../../shared';

@Component({
  selector: 'bitmark-book-sources',
  templateUrl: './book-sources.component.html',
  styleUrls: ['../bits.scss', './book-sources.component.scss']
})
export class BookSourcesComponent implements OnInit {
  @Input() bit?: BookSourcesBit;
  @Input() bitId: string;
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }>();

  private bitmarkPipe: BitmarkPipe;

  BitmarkFormat = BitmarkFormat;

  constructor(private translate: TranslateService,
              private readerTipTapService: ReaderTipTapTapService) {
    this.bitmarkPipe = new BitmarkPipe(readerTipTapService, new UrlRewriteService());
  }

  ngOnInit() {
    if (Object.prototype.toString.call(this.bit?.body) !== '[object Array]') {
      this.bit.body = [];
    }
    this.bit.body?.forEach((s) => {
      s.pageContent = s.pageContent.replace(/\(.*?\)|\[.*?\]|\{.*?\}|<.*?>/gs, '')
          .replace(/\n+/g, '\n')
          .replace(/(\r?\n)+/g, '\r\n');
      s.displayChapterLead = this.displayTocLead(s.chapter);
      s.displayChapterElement = this.displayTocElement(s.chapter);
      s.displayContentLead = this.displayTocLead(s.tocItem);
      s.displayContentElement = this.displayTocElement(s.tocItem);
    });
  }

  parseMM(text: string): string {
    return this.bitmarkPipe.transform(text, BitmarkFormat.MM, 'html') as string;
  }

  private displayTocLead(item: TocItem) {
    if (!item) {
      return null;
    }

    if (item?.resourceType === 'website' || item?.resourceType === 'websiteLink') {
      return null;
    }

    let ret = item.item; //this.parseMM(item.item);

    if (item?.type === 'page') {
      ret = `${this.translate.instant('Shared.Page')} ${item.item}`;
    }

    return ret;
  }

  private displayTocElement(item: TocItem) {
    if (!item) {
      return null;
    }

    if (item?.resourceType === 'website' || item?.resourceType === 'websiteLink') {
      return 'Website';
    }

    let ret = '';

    if (item.title) {
      ret += item.title; // this.parseMM(item.title);
    }
    if (!ret?.length) {
      ret = item.type;
    }
    return ret;
  }

  navigateToSource(source: BookSource) {
    this.navigateToBook.emit({
      bookId: source?.bookId,
      fragment: source?.bitId
    });
  }
}
