// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
  overflow: hidden;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  color: inherit;
  background-color: var(--bitmark-input-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-input-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-input-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-input-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-input-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-input-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-input-background-animation-name) var(--bitmark-input-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-input-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-input-background-backdrop-filter);
  font-family: var(--bitmark-input-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  border-top: var(--bitmark-input-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-input-border-style, var(--bitmark-x-border-style)) var(--bitmark-input-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-input-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-input-border-style, var(--bitmark-x-border-style)) var(--bitmark-input-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-input-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-input-border-style, var(--bitmark-x-border-style)) var(--bitmark-input-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-input-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-input-border-style, var(--bitmark-x-border-style)) var(--bitmark-input-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-input-border-radius, var(--bitmark-x-border-radius, y));
}
.input:before {
  content: var(--bitmark-input-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-input-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-input-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.input:after {
  content: var(--bitmark-input-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-input-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-input-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.input::placeholder {
  font-style: italic;
  color: #767676;
}
.input.answered {
  background-color: var(--bitmark-input-answered-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-input-answered-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-input-answered-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-input-answered-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-input-answered-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-input-answered-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-input-answered-background-animation-name) var(--bitmark-input-answered-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-input-answered-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-input-answered-background-backdrop-filter);
  font-family: var(--bitmark-input-answered-font-family, var(--bitmark-input-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-answered-font-size, var(--bitmark-input-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-answered-font-weight, var(--bitmark-input-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-answered-font-style, var(--bitmark-input-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-answered-font-text-decoration, var(--bitmark-input-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-answered-font-text-decoration, var(--bitmark-input-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-answered-font-line-height, var(--bitmark-input-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-answered-font-color, var(--bitmark-input-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-answered-font-align, var(--bitmark-input-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-answered-font-align, var(--bitmark-input-font-align, var(--bitmark-main-font-alignt, left)));
  border-top: var(--bitmark-input-answered-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-input-answered-border-style, var(--bitmark-x-border-style)) var(--bitmark-input-answered-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-input-answered-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-input-answered-border-style, var(--bitmark-x-border-style)) var(--bitmark-input-answered-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-input-answered-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-input-answered-border-style, var(--bitmark-x-border-style)) var(--bitmark-input-answered-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-input-answered-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-input-answered-border-style, var(--bitmark-x-border-style)) var(--bitmark-input-answered-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-input-answered-border-radius, var(--bitmark-x-border-radius, y));
}
.input.correct {
  background-color: var(--bitmark-input-correct-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-input-correct-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-input-correct-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-input-correct-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-input-correct-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-input-correct-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-input-correct-background-animation-name) var(--bitmark-input-correct-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-input-correct-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-input-correct-background-backdrop-filter);
  font-family: var(--bitmark-input-correct-font-family, var(--bitmark-input-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-correct-font-size, var(--bitmark-input-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-correct-font-weight, var(--bitmark-input-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-correct-font-style, var(--bitmark-input-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-correct-font-text-decoration, var(--bitmark-input-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-correct-font-text-decoration, var(--bitmark-input-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-correct-font-line-height, var(--bitmark-input-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-correct-font-color, var(--bitmark-input-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-correct-font-align, var(--bitmark-input-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-correct-font-align, var(--bitmark-input-font-align, var(--bitmark-main-font-alignt, left)));
  border-top: var(--bitmark-input-correct-border-top-width, var(--bitmark-input-border-top-width)) var(--bitmark-input-correct-border-style, var(--bitmark-input-border-style)) var(--bitmark-input-correct-border-color, var(--bitmark-input-border-color));
  border-right: var(--bitmark-input-correct-border-right-width, var(--bitmark-input-border-right-width)) var(--bitmark-input-correct-border-style, var(--bitmark-input-border-style)) var(--bitmark-input-correct-border-color, var(--bitmark-input-border-color));
  border-bottom: var(--bitmark-input-correct-border-bottom-width, var(--bitmark-input-border-bottom-width)) var(--bitmark-input-correct-border-style, var(--bitmark-input-border-style)) var(--bitmark-input-correct-border-color, var(--bitmark-input-border-color));
  border-left: var(--bitmark-input-correct-border-left-width, var(--bitmark-input-border-left-width)) var(--bitmark-input-correct-border-style, var(--bitmark-input-border-style)) var(--bitmark-input-correct-border-color, var(--bitmark-input-border-color));
  border-radius: var(--bitmark-input-correct-border-radius, var(--bitmark-input-border-radius, y));
}
.input.correct:before {
  content: var(--bitmark-input-correct-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-input-correct-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-input-correct-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-correct-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-correct-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-correct-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-correct-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-correct-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-correct-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-correct-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-correct-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-correct-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.input.correct:after {
  content: var(--bitmark-input-correct-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-input-correct-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-input-correct-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-correct-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-correct-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-correct-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-correct-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-correct-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-correct-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-correct-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-correct-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-correct-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.input.incorrect {
  background-color: var(--bitmark-input-incorrect-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-input-incorrect-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-input-incorrect-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-input-incorrect-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-input-incorrect-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-input-incorrect-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-input-incorrect-background-animation-name) var(--bitmark-input-incorrect-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-input-incorrect-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-input-incorrect-background-backdrop-filter);
  font-family: var(--bitmark-input-incorrect-font-family, var(--bitmark-input-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-incorrect-font-size, var(--bitmark-input-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-incorrect-font-weight, var(--bitmark-input-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-incorrect-font-style, var(--bitmark-input-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-incorrect-font-text-decoration, var(--bitmark-input-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-incorrect-font-text-decoration, var(--bitmark-input-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-incorrect-font-line-height, var(--bitmark-input-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-incorrect-font-color, var(--bitmark-input-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-incorrect-font-align, var(--bitmark-input-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-incorrect-font-align, var(--bitmark-input-font-align, var(--bitmark-main-font-alignt, left)));
  border-top: var(--bitmark-input-incorrect-border-top-width, var(--bitmark-input-border-top-width)) var(--bitmark-input-incorrect-border-style, var(--bitmark-input-border-style)) var(--bitmark-input-incorrect-border-color, var(--bitmark-input-border-color));
  border-right: var(--bitmark-input-incorrect-border-right-width, var(--bitmark-input-border-right-width)) var(--bitmark-input-incorrect-border-style, var(--bitmark-input-border-style)) var(--bitmark-input-incorrect-border-color, var(--bitmark-input-border-color));
  border-bottom: var(--bitmark-input-incorrect-border-bottom-width, var(--bitmark-input-border-bottom-width)) var(--bitmark-input-incorrect-border-style, var(--bitmark-input-border-style)) var(--bitmark-input-incorrect-border-color, var(--bitmark-input-border-color));
  border-left: var(--bitmark-input-incorrect-border-left-width, var(--bitmark-input-border-left-width)) var(--bitmark-input-incorrect-border-style, var(--bitmark-input-border-style)) var(--bitmark-input-incorrect-border-color, var(--bitmark-input-border-color));
  border-radius: var(--bitmark-input-incorrect-border-radius, var(--bitmark-input-border-radius, y));
}
.input.incorrect:before {
  content: var(--bitmark-input-incorrect-content-before-string, var(--bitmark-x-before-string));
  margin: var(--bitmark-input-incorrect-content-before-margin, var(--bitmark-x-before-margin));
  font-family: var(--bitmark-input-incorrect-content-before-font-family, var(--bitmark-x-before-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-incorrect-content-before-font-size, var(--bitmark-x-before-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-incorrect-content-before-font-weight, var(--bitmark-x-before-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-incorrect-content-before-font-style, var(--bitmark-x-before-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-incorrect-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-incorrect-content-before-font-text-decoration, var(--bitmark-x-before-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-incorrect-content-before-font-line-height, var(--bitmark-x-before-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-incorrect-content-before-font-color, var(--bitmark-x-before-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-incorrect-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-incorrect-content-before-font-align, var(--bitmark-x-before-font-align, var(--bitmark-main-font-alignt, left)));
}
.input.incorrect:after {
  content: var(--bitmark-input-incorrect-content-after-string, var(--bitmark-x-after-string));
  margin: var(--bitmark-input-incorrect-content-after-margin, var(--bitmark-x-after-margin));
  font-family: var(--bitmark-input-incorrect-content-after-font-family, var(--bitmark-x-after-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-input-incorrect-content-after-font-size, var(--bitmark-x-after-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-input-incorrect-content-after-font-weight, var(--bitmark-x-after-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-input-incorrect-content-after-font-style, var(--bitmark-x-after-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-input-incorrect-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-input-incorrect-content-after-font-text-decoration, var(--bitmark-x-after-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-input-incorrect-content-after-font-line-height, var(--bitmark-x-after-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-input-incorrect-content-after-font-color, var(--bitmark-x-after-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-input-incorrect-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-input-incorrect-content-after-font-align, var(--bitmark-x-after-font-align, var(--bitmark-main-font-alignt, left)));
}
.input.inline {
  width: var(--bitmark-input-inline-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-input-inline-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-input-inline-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-input-inline-size-margin, var(--bitmark-x-size-margin, 0));
  display: inline-block;
  line-height: initial;
  white-space: nowrap;
}
@media (max-width: 1024px) {
  .input.inline {
    max-width: 65vw;
  }
}
@media (min-width: 1025px) {
  .input.inline {
    max-width: 350px;
  }
}
.input.short {
  width: var(--bitmark-input-short-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-input-short-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-input-short-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-input-short-size-margin, var(--bitmark-x-size-margin, 0));
}
.input.long {
  width: var(--bitmark-input-long-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-input-long-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-input-long-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-input-long-size-margin, var(--bitmark-x-size-margin, 0));
}
.input.rows-height {
  height: auto !important;
}
.input.disabled {
  background-color: #eeeeef !important;
}

.input-label {
  line-height: 1.2;
}
.input-label.inline {
  display: inline-block;
  padding: var(--bitmark-input-inline-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-input-inline-size-margin, var(--bitmark-x-size-margin, 0));
}
.input-label.short {
  padding: var(--bitmark-input-short-size-padding, var(--bitmark-x-size-padding, 0));
}
.input-label.long {
  padding: var(--bitmark-input-long-size-padding, var(--bitmark-x-size-padding, 0));
}

.solution-instruction-grouped {
  background-color: var(--bitmark-solution-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-solution-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-solution-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-solution-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-solution-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-solution-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-solution-background-animation-name) var(--bitmark-solution-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-solution-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-solution-background-backdrop-filter);
  border-top: var(--bitmark-solution-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-solution-border-style, var(--bitmark-x-border-style)) var(--bitmark-solution-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-solution-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-solution-border-style, var(--bitmark-x-border-style)) var(--bitmark-solution-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-solution-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-solution-border-style, var(--bitmark-x-border-style)) var(--bitmark-solution-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-solution-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-solution-border-style, var(--bitmark-x-border-style)) var(--bitmark-solution-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-solution-border-radius, var(--bitmark-x-border-radius, y));
  text-align: center;
  display: inline-block;
  width: 100%;
}
.solution-instruction-grouped li {
  display: inline;
}
.solution-instruction-grouped li:after {
  content: " ";
  letter-spacing: 1rem;
  background: url('dot.19f8d7d69d5b22da.svg') center center no-repeat;
}
.solution-instruction-grouped li span {
  white-space: nowrap;
}

.bit-radio input[type=radio] {
  opacity: 0;
  position: absolute;
}
.bit-radio input[type=radio] + label::after {
  content: none;
}
.bit-radio input[type=radio]:checked + label::after {
  content: var(--bitmark-radio-checked-content-string) !important;
}
.bit-radio label {
  margin: 0;
  padding: 0;
  position: relative;
  word-break: break-word;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.bit-radio label bitmark-text {
  padding-left: 10px;
  max-width: calc(100% - var(--bitmark-radio-size-width, 30px));
}
.bit-radio label span {
  padding-left: 10px;
}
.bit-radio label::before {
  position: static;
  content: "";
  align-self: flex-start;
  width: var(--bitmark-radio-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-radio-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-radio-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-radio-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-radio-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-radio-border-style, var(--bitmark-x-border-style)) var(--bitmark-radio-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-radio-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-radio-border-style, var(--bitmark-x-border-style)) var(--bitmark-radio-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-radio-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-radio-border-style, var(--bitmark-x-border-style)) var(--bitmark-radio-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-radio-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-radio-border-style, var(--bitmark-x-border-style)) var(--bitmark-radio-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-radio-border-radius, var(--bitmark-x-border-radius, y));
  background-color: var(--bitmark-radio-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-radio-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-radio-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-radio-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-radio-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-radio-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-radio-background-animation-name) var(--bitmark-radio-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-radio-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-radio-background-backdrop-filter);
}
.bit-radio label::after {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  color: var(--bitmark-radio-checked-color);
  width: var(--bitmark-radio-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-radio-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-radio-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-radio-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-radio-checked-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-radio-checked-border-style, var(--bitmark-x-border-style)) var(--bitmark-radio-checked-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-radio-checked-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-radio-checked-border-style, var(--bitmark-x-border-style)) var(--bitmark-radio-checked-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-radio-checked-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-radio-checked-border-style, var(--bitmark-x-border-style)) var(--bitmark-radio-checked-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-radio-checked-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-radio-checked-border-style, var(--bitmark-x-border-style)) var(--bitmark-radio-checked-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-radio-checked-border-radius, var(--bitmark-x-border-radius, y));
  background-color: var(--bitmark-radio-checked-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-radio-checked-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-radio-checked-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-radio-checked-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-radio-checked-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-radio-checked-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-radio-checked-background-animation-name) var(--bitmark-radio-checked-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-radio-checked-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-radio-checked-background-backdrop-filter);
  font-family: var(--bitmark-radio-checked-content-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-radio-checked-content-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-radio-checked-content-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-radio-checked-content-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-radio-checked-content-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-radio-checked-content-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-radio-checked-content-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-radio-checked-content-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-radio-checked-content-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-radio-checked-content-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.bit-radio.correct label::before, .bit-radio.correct label::after {
  background-color: var(--bitmark-input-correct-background-color) !important;
}
.bit-radio.incorrect label::before, .bit-radio.incorrect label::after {
  background-color: var(--bitmark-input-incorrect-background-color) !important;
}

.bit-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.bit-checkbox input[type=checkbox] + label::after {
  content: none;
}
.bit-checkbox input[type=checkbox]:checked + label::after {
  content: var(--bitmark-checkbox-checked-content-string) !important;
}
.bit-checkbox label {
  margin: 0;
  padding: 0;
  position: relative;
  word-break: break-word;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}
.bit-checkbox label bitmark-text {
  padding-left: 10px;
  max-width: calc(100% - var(--bitmark-radio-size-width, 30px));
}
.bit-checkbox label span {
  padding-left: 10px;
}
.bit-checkbox label::before {
  position: static;
  content: "";
  align-self: flex-start;
  width: var(--bitmark-checkbox-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-checkbox-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-checkbox-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-checkbox-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-checkbox-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-checkbox-border-style, var(--bitmark-x-border-style)) var(--bitmark-checkbox-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-checkbox-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-checkbox-border-style, var(--bitmark-x-border-style)) var(--bitmark-checkbox-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-checkbox-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-checkbox-border-style, var(--bitmark-x-border-style)) var(--bitmark-checkbox-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-checkbox-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-checkbox-border-style, var(--bitmark-x-border-style)) var(--bitmark-checkbox-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-checkbox-border-radius, var(--bitmark-x-border-radius, y));
  background-color: var(--bitmark-checkbox-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-checkbox-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-checkbox-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-checkbox-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-checkbox-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-checkbox-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-checkbox-background-animation-name) var(--bitmark-checkbox-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-checkbox-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-checkbox-background-backdrop-filter);
}
.bit-checkbox label::after {
  position: absolute;
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  color: var(--bitmark-checkbox-checked-color);
  width: var(--bitmark-checkbox-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-checkbox-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-checkbox-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-checkbox-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-checkbox-checked-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-checkbox-checked-border-style, var(--bitmark-x-border-style)) var(--bitmark-checkbox-checked-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-checkbox-checked-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-checkbox-checked-border-style, var(--bitmark-x-border-style)) var(--bitmark-checkbox-checked-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-checkbox-checked-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-checkbox-checked-border-style, var(--bitmark-x-border-style)) var(--bitmark-checkbox-checked-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-checkbox-checked-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-checkbox-checked-border-style, var(--bitmark-x-border-style)) var(--bitmark-checkbox-checked-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-checkbox-checked-border-radius, var(--bitmark-x-border-radius, y));
  background-color: var(--bitmark-checkbox-checked-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-checkbox-checked-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-checkbox-checked-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-checkbox-checked-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-checkbox-checked-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-checkbox-checked-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-checkbox-checked-background-animation-name) var(--bitmark-checkbox-checked-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-checkbox-checked-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-checkbox-checked-background-backdrop-filter);
  font-family: var(--bitmark-checkbox-checked-content-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-checkbox-checked-content-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-checkbox-checked-content-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-checkbox-checked-content-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-checkbox-checked-content-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-checkbox-checked-content-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-checkbox-checked-content-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-checkbox-checked-content-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-checkbox-checked-content-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-checkbox-checked-content-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.bit-checkbox.correct input[type=checkbox]:not(:checked) + label::after, .bit-checkbox.incorrect input[type=checkbox]:not(:checked) + label::after {
  content: "";
}
.bit-checkbox.correct label::after {
  background-color: var(--bitmark-input-correct-background-color) !important;
}
.bit-checkbox.incorrect label::after {
  background-color: var(--bitmark-input-incorrect-background-color) !important;
}

.bit-dropdown {
  z-index: 4;
  position: relative;
  background-color: var(--bitmark-dropdown-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-dropdown-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-dropdown-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-dropdown-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-dropdown-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-dropdown-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-dropdown-background-animation-name) var(--bitmark-dropdown-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-dropdown-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-dropdown-background-backdrop-filter);
  border-top: var(--bitmark-dropdown-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-dropdown-border-style, var(--bitmark-x-border-style)) var(--bitmark-dropdown-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-dropdown-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-dropdown-border-style, var(--bitmark-x-border-style)) var(--bitmark-dropdown-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-dropdown-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-dropdown-border-style, var(--bitmark-x-border-style)) var(--bitmark-dropdown-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-dropdown-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-dropdown-border-style, var(--bitmark-x-border-style)) var(--bitmark-dropdown-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-dropdown-border-radius, var(--bitmark-x-border-radius, 8px));
  color: var(--bitmark-dropdown-color);
  padding: 1px 10px 3px 10px;
  margin: 1px 2px;
  overflow: hidden;
}
.bit-dropdown .answer {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bit-dropdown .prefix, .bit-dropdown .postfix {
  white-space: nowrap;
}
.bit-dropdown.bit-dropdown-with-icon {
  padding-right: 5px;
}
.bit-dropdown.bit-dropdown-with-icon bitmark-icon {
  margin-top: 2px;
}
.bit-dropdown.correct {
  color: var(--bitmark-input-correct-font-color);
  background-color: var(--bitmark-input-correct-background-color);
}
.bit-dropdown.incorrect {
  color: var(--bitmark-input-incorrect-font-color);
  background-color: var(--bitmark-input-incorrect-background-color);
}

.button-caption {
  white-space: nowrap;
}

.bit-button {
  --bitmark-button-default-border-top-width: 1px;
  --bitmark-button-default-border-right-width: 1px;
  --bitmark-button-default-border-bottom-width: 1px;
  --bitmark-button-default-border-left-width: 1px;
  --bitmark-button-default-border-radius: 30px;
  --bitmark-button-default-border-style: solid;
  --bitmark-button-default-border-color: transparent;
  font-family: var(--bitmark-button-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-button-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-button-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-button-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-button-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-button-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  --bitmark-button-background-shorthand-composed: var(--bitmark-button-background-color, var(--bitmark-x-background-color)) var(--bitmark-button-background-image, var(--bitmark-x-background-image, none)) var(--bitmark-button-background-repeat, var(--bitmark-x-background-repeat, no-repeat)) var(--bitmark-button-background-position, var(--bitmark-x-background-position, center)) / var(--bitmark-button-background-size, var(--bitmark-x-background-size, cover));
  background: var(--bitmark-button-background-shorthand, var(--bitmark-button-background-shorthand-composed));
  transform: var(--bitmark-button-background-transform, var(--bitmark-x-background-transform, none));
  animation: var(--bitmark-button-background-animation-name) var(--bitmark-button-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-button-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-button-background-backdrop-filter);
  border-top: var(--bitmark-button-border-top-width, var(--bitmark-button-default-border-top-width)) var(--bitmark-button-border-style, var(--bitmark-button-default-border-style)) var(--bitmark-button-border-color, var(--bitmark-button-default-border-color));
  border-right: var(--bitmark-button-border-right-width, var(--bitmark-button-default-border-right-width)) var(--bitmark-button-border-style, var(--bitmark-button-default-border-style)) var(--bitmark-button-border-color, var(--bitmark-button-default-border-color));
  border-bottom: var(--bitmark-button-border-bottom-width, var(--bitmark-button-default-border-bottom-width)) var(--bitmark-button-border-style, var(--bitmark-button-default-border-style)) var(--bitmark-button-border-color, var(--bitmark-button-default-border-color));
  border-left: var(--bitmark-button-border-left-width, var(--bitmark-button-default-border-left-width)) var(--bitmark-button-border-style, var(--bitmark-button-default-border-style)) var(--bitmark-button-border-color, var(--bitmark-button-default-border-color));
  border-radius: var(--bitmark-button-border-radius, var(--bitmark-button-default-border-radius, y));
  color: var(--bitmark-button-font-color, #ffffff);
  padding: 0 30px;
}

.bit-label {
  font-family: var(--bitmark-label-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-label-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-label-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-label-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-label-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-label-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-label-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-label-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-label-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-label-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  margin-bottom: 10px;
}

@media (min-width: 680px) {
  .font-weight-normal-sm {
    font-weight: normal !important;
  }
  .font-weight-bold-sm {
    font-weight: bold !important;
  }
  .font-style-normal-sm {
    font-style: normal !important;
  }
  .font-style-italic-sm {
    font-style: italic !important;
  }
}
.edit-container {
  background-color: var(--bitmark-edit-settings-color);
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  font-family: "Roboto", "sans-serif";
}
.edit-container .state-button {
  justify-content: center !important;
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  min-height: 30px;
  font-size: 16px !important;
  min-width: 90px;
  font-weight: 500;
}
.edit-container .state-save-button {
  background-color: #543199 !important;
  color: #ffffff;
}
.edit-container .state-cancel-button {
  color: #543199;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
