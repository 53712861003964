import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {
  BaseUser,
  BrowserStorageService,
  SocketEvent,
  SocketIoService,
  SocketMessageType,
  UserPresenceService
} from 'core';
import {environment} from 'environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContainerComponent} from '../../container/container.component';

@Component({
  selector: 'app-user-box-simple',
  templateUrl: './user-box-simple.component.html',
  styleUrls: ['./user-box-simple.component.scss']
})
export class UserBoxSimpleComponent extends ContainerComponent implements OnInit, OnDestroy {
  @Input() user: BaseUser;
  @Input() photoSize;
  @Input() dotSize = '';
  @Input() canOpenAvatar?: boolean;
  private subSocket: Subscription;
  presenceReady: boolean;

  @ViewChild('avatar') avatarTemplate: ElementRef;

  constructor(private socketIoService: SocketIoService,
              private userPresenceService: UserPresenceService,
              private browserStorageService: BrowserStorageService,
              private ngbModal: NgbModal) {
    super();
  }

  ngOnInit() {
    if (this.subSocket) {
      return;
    }
    this.subSocket = this.socketIoService.event('timeline')
      .subscribe((socketEvt: SocketEvent) => {
        if (socketEvt.messageType === SocketMessageType.UserAvailability) {
          if (socketEvt.senderUserId === this.user.id) {
            this.storePulseToLocalCache(socketEvt.payload);
            this.user.isAvailable = socketEvt.payload;
          }
        }
      });

    this.presenceReady = false;
    if (this.user.isAvailable) {
      const isActive = this.retrievePulseFromLocalCache();
      if (isActive !== null) {
        this.user.isAvailable = this.user.isAvailable && isActive;
        this.presenceReady = true;
        return;
      }
      const pulseTimer = setTimeout(() => {
        this.userPresenceService.getPulse(this.user)
          .subscribe((res: { isActive: boolean }) => {
            this.storePulseToLocalCache(res.isActive);
            this.user.isAvailable = this.user.isAvailable && res.isActive;
            this.presenceReady = true;
            clearTimeout(pulseTimer);
          }, (err: HttpErrorResponse) => console.error(err));
      }, environment.heartBeatInterval);
    } else {
      this.presenceReady = true;
    }
  }

  private storePulseToLocalCache(isActive: boolean) {
    const pulses = this.browserStorageService.volatile.retrieve('users-pulses') || {};
    pulses[this.user.id] = isActive;
    this.browserStorageService.volatile.store('users-pulses', pulses);
  }

  private retrievePulseFromLocalCache(): boolean {
    const pulses = this.browserStorageService.volatile.retrieve('users-pulses');
    return (pulses && pulses[this.user.id]) || null;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subSocket) {
      this.subSocket.unsubscribe();
    }
  }

  openAvatar() {
    this.ngbModal.open(this.avatarTemplate, {
      windowClass: 'dark-modal lg',
      backdropClass: 'violet-backdrop',
      backdrop: 'static',
      keyboard: false,
      animation: false
    });
  }
}
