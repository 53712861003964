// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-wrapper {
  overflow: hidden;
  position: relative;
  box-shadow: var(--bitmark-flashcard-1-card-shadow, none);
  border-top: var(--bitmark-flashcard-1-card-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-flashcard-1-card-border-style, var(--bitmark-x-border-style)) var(--bitmark-flashcard-1-card-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-flashcard-1-card-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-flashcard-1-card-border-style, var(--bitmark-x-border-style)) var(--bitmark-flashcard-1-card-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-flashcard-1-card-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-flashcard-1-card-border-style, var(--bitmark-x-border-style)) var(--bitmark-flashcard-1-card-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-flashcard-1-card-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-flashcard-1-card-border-style, var(--bitmark-x-border-style)) var(--bitmark-flashcard-1-card-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-flashcard-1-card-border-radius, var(--bitmark-x-border-radius, y));
}

.card-wrapper-front.rotate {
  transform: rotate(-2deg);
}
.card-wrapper-front .card-logo {
  position: absolute;
  --bit-icon-position: var(--bitmark-flashcard-1-card-front-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-flashcard-1-card-front-icon-width, var(--bitmark-x-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-flashcard-1-card-front-icon-height, var(--bitmark-x-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-flashcard-1-card-front-icon-image, var(--bitmark-x-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-flashcard-1-card-front-icon-width, var(--bitmark-x-icon-width, 0));
  height: var(--bitmark-flashcard-1-card-front-icon-height, var(--bitmark-x-icon-height, 0));
  inset: var(--bitmark-flashcard-1-card-front-icon-location, var(--bitmark-x-icon-location, inherit));
  margin: var(--bitmark-flashcard-1-card-front-icon-margin, var(--bitmark-x-icon-margin, 0));
}
.card-wrapper-front .card-logo.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.card-wrapper-front .card-logo.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}

.card-wrapper-back.rotate {
  transform: rotate(8deg);
  margin-top: -100px;
  margin-bottom: 10px;
}
.card-wrapper-back:not(.rotate) {
  margin-top: 20px;
}
.card-wrapper-back .card-logo {
  position: absolute;
  --bit-icon-position: var(--bitmark-flashcard-1-card-back-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-flashcard-1-card-back-icon-width, var(--bitmark-x-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-flashcard-1-card-back-icon-height, var(--bitmark-x-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-flashcard-1-card-back-icon-image, var(--bitmark-x-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-flashcard-1-card-back-icon-width, var(--bitmark-x-icon-width, 0));
  height: var(--bitmark-flashcard-1-card-back-icon-height, var(--bitmark-x-icon-height, 0));
  inset: var(--bitmark-flashcard-1-card-back-icon-location, var(--bitmark-x-icon-location, inherit));
  margin: var(--bitmark-flashcard-1-card-back-icon-margin, var(--bitmark-x-icon-margin, 0));
}
.card-wrapper-back .card-logo.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.card-wrapper-back .card-logo.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}

.card {
  height: auto !important;
}

.card-front {
  width: var(--bitmark-flashcard-1-card-size-width, 300px);
  min-height: var(--bitmark-flashcard-1-card-size-height, 400px);
  padding: var(--bitmark-flashcard-1-card-size-padding, var(--bitmark-x-size-padding, 0));
  background-color: var(--bitmark-flashcard-1-card-front-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-flashcard-1-card-front-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-flashcard-1-card-front-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-flashcard-1-card-front-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-flashcard-1-card-front-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-flashcard-1-card-front-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-flashcard-1-card-front-background-animation-name) var(--bitmark-flashcard-1-card-front-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-flashcard-1-card-front-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-flashcard-1-card-front-background-backdrop-filter);
  font-family: var(--bitmark-flashcard-1-card-front-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-flashcard-1-card-front-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-flashcard-1-card-front-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-flashcard-1-card-front-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-flashcard-1-card-front-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-flashcard-1-card-front-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-flashcard-1-card-front-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-flashcard-1-card-front-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-flashcard-1-card-front-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-flashcard-1-card-front-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.rotate .card-front {
  padding-bottom: 120px;
}

.card-back {
  width: var(--bitmark-flashcard-1-card-back-size-width, var(--bitmark-flashcard-1-card-size-width, 300px));
  min-height: var(--bitmark-flashcard-1-card-back-size-height, var(--bitmark-flashcard-1-card-size-height, 400px));
  padding: var(--bitmark-flashcard-1-card-back-size-padding, var(--bitmark-flashcard-1-card-size-padding, 0));
  background-color: var(--bitmark-flashcard-1-card-back-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-flashcard-1-card-back-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-flashcard-1-card-back-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-flashcard-1-card-back-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-flashcard-1-card-back-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-flashcard-1-card-back-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-flashcard-1-card-back-background-animation-name) var(--bitmark-flashcard-1-card-back-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-flashcard-1-card-back-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-flashcard-1-card-back-background-backdrop-filter);
  font-family: var(--bitmark-flashcard-1-card-back-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-flashcard-1-card-back-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-flashcard-1-card-back-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-flashcard-1-card-back-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-flashcard-1-card-back-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-flashcard-1-card-back-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-flashcard-1-card-back-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-flashcard-1-card-back-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-flashcard-1-card-back-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-flashcard-1-card-back-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.card-logo {
  z-index: 1;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
