import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output} from '@angular/core';
import {VirtualHandInResultBit} from "./virtual-hand-in-result.models";
import {ProductFamily} from "../../shared/models/bitmark.models";

@Component({
  selector: 'bitmark-virtual-hand-in-result',
  templateUrl: './virtual-hand-in-result.component.html',
  styleUrls: ['./virtual-hand-in-result.component.scss', '../bits.scss']
})
export class VirtualHandInResultComponent implements OnInit {
  @Input() handInBit?: VirtualHandInResultBit;
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }> = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }>();
  @Output() connectWithUser: EventEmitter<{ email: string }> = new EventEmitter<{ email: string }>();

  handInDate: Date;
  // expertUser: any;
  bookExternalId?: string;

  // @HostListener('document:gmbUserDetailsDelivered', ['$event'])
  // onUserDetailsDelivered(event: any) {
  //   if (event?.detail?.email === this.expertUserEmail || event?.detail?.ssoEmail === this.expertUserEmail) {
  //     this.expertUser = event?.detail;
  //   }
  // }

  ngOnInit() {
    console.log('hand in bit: ', this.handInBit);
    this.handInDate = this.handInBit?.handIn?.returnedAt || this.handInBit?.handIn?.lastUpdatedAt;
    this.bookExternalId = this.handInBit?.handIn?.book?.externalId;
  }

  openBook(){
    this.navigateToBook.emit({bookId: this.bookExternalId, fragment: null});
  }
}
