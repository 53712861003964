// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  line-height: 0;
}

.dropdown-position {
  position: absolute;
  top: 6px;
  right: 6px;
}
.dropdown-position.annotation {
  right: 71px;
}

.dot-wrapper {
  display: inline-flex;
  cursor: pointer;
  line-height: 0;
  padding: 5px;
}
.dot-wrapper .dot {
  background-color: var(--bitmark-reader-primary-color, #543199);
  border: 1px solid #ffffff;
}

.absolute-menu {
  position: absolute !important;
  left: 49.42%;
  right: 50.58%;
  height: 16px;
  width: 16px;
  bottom: -8px;
}
.absolute-menu.absolute-menu-first {
  top: -8px;
}
.absolute-menu.absolute-menu-plus {
  height: 40px;
  width: 40px;
  left: 48%;
}

.absolute-menu-no-ending {
  bottom: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
