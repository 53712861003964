// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hand-in-emoji {
  font-size: 24px;
}

.page-product-container {
  justify-content: var(--bitmark-page-product-video-flex-align, flex-start) !important;
  min-height: 30px;
}

.book-cover .video-length {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  background-color: black;
  font-size: 12px;
  padding: 2px 6px 2px 6px;
  border-radius: 4px;
  margin: var(--bitmark-page-product-video-image-size-padding);
}

.book-publisher-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.book-title {
  word-wrap: break-word;
  color: black;
  font-family: var(--bitmark-page-product-video-title-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-page-product-video-title-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-page-product-video-title-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-page-product-video-title-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-page-product-video-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-page-product-video-title-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-page-product-video-title-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-page-product-video-title-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-page-product-video-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-page-product-video-title-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.book-publisher {
  color: #656769;
  font-family: var(--bitmark-page-product-video-publisher-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-page-product-video-publisher-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-page-product-video-publisher-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-page-product-video-publisher-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-page-product-video-publisher-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-page-product-video-publisher-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-page-product-video-publisher-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-page-product-video-publisher-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-page-product-video-publisher-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-page-product-video-publisher-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.book-texts {
  padding-left: 2px;
  padding-right: 2px;
  width: 100%;
}

.book-details {
  width: var(--bitmark-page-product-video-body-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-product-video-body-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-page-product-video-body-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-page-product-video-body-size-margin, var(--bitmark-x-size-margin, 0));
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.book-shadow {
  filter: drop-shadow(5px 0 0 white) drop-shadow(0 5px 0 white) drop-shadow(-5px 0 0 white) drop-shadow(0 -5px 0 white);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
