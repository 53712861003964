// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-container {
  padding: var(--bitmark-resource-video-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-video-size-margin, var(--bitmark-x-size-margin, 0));
  line-height: 0;
}

.resource-video-caption {
  font-family: var(--bitmark-resource-video-caption-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-resource-video-caption-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-resource-video-caption-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-resource-video-caption-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-resource-video-caption-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-resource-video-caption-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-resource-video-caption-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-resource-video-caption-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-resource-video-caption-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-resource-video-caption-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  margin: var(--bitmark-resource-video-caption-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-resource-video-caption-size-padding, var(--bitmark-x-size-padding, 0));
}

.video-title {
  font-weight: bold;
  font-size: 2.25rem;
  line-height: 1.3;
}

.video-description {
  line-height: 1.3;
  text-align: left;
}

.video-logo {
  width: 80px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
