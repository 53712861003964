import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {groupBy} from 'lodash';
import {
  ClozeAndMultipleChoiceBit,
  SelectPlaceholderBit,
  ClozeBit,
  GapPlaceholderBit,
  PlaceholderType
} from './cloze.models';
import {BitmarkFormat} from '../../shared/models';
import {ArrayService} from '../../shared/utils/array.service';
import BitUtilsService from '../../shared/utils/bit-utils.service';
import {BitEvalResult} from '../bit-eval.service';
import {BitResource} from '../bits.models';

interface PlaceholderPart {
  string?: string;
  isNewLine?: boolean;
  isNewLine2?: boolean;
  isSpace?: boolean;
  gapPlaceholderBit?: GapPlaceholderBit;
  selectPlaceholderBit?: SelectPlaceholderBit;
}

@Component({
  selector: 'bitmark-cloze',
  templateUrl: './cloze.component.html',
  styleUrls: ['../bits.scss', './cloze.component.scss']
})
export class ClozeComponent implements OnChanges, OnInit {
  private _bit?: ClozeBit;
  @Input() set bit(value: ClozeBit) {
    this._bit = value;
    if (this.initialized && value.quizStrikethroughSolutions && !this.hasAnswer(value)) {
      this.groupedResourceItems = [];
      this.computeGroupedItems();
    }
  }

  get bit(): ClozeBit {
    return this._bit;
  }

  @Input() clozeAndMultipleChoiceBit?: ClozeAndMultipleChoiceBit;
  @Input() isSolutionGrouped = false;
  @Input() isInstructionGrouped = false;
  @Input() readOnly = false;
  @Input() allowAnswerSubmit = true;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() focused = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<any>();

  groupedResourceItems: Array<{ text: string, solutions: Array<string>, occurrences?: number, found?: number }> = [];

  parts: Array<PlaceholderPart> = [];
  format: BitmarkFormat;

  private initialized = false;

  constructor(private arrayService: ArrayService,
              private bitUtilsService: BitUtilsService) {
  }

  private static parseBody(body: string, obj: any): Array<PlaceholderPart> {
    if (!obj || !body) {
      return [];
    }
    const placeholderRegex = new RegExp('\{[A-Za-z0-9_]+\}', 'g');
    const parts: Array<PlaceholderPart> = [];

    const parseString = (str: string) => {
      // console.log(str);
      if (!str) {
        return;
      }
      if (str.startsWith('\n')) {
        if (parts.length && parts[parts.length - 1].isNewLine) {
          parts.push({isNewLine2: true});
        } else {
          parts.push({isNewLine: true});
        }
        str = str.substr(1);
      } else if (str.startsWith('\r\n')) {
        if (parts.length && parts[parts.length - 1].isNewLine) {
          parts.push({isNewLine2: true});
        } else {
          parts.push({isNewLine: true});
        }
        str = str.substr(2);
      } else if (str.startsWith(' ')) {
        parts.push({isSpace: true});
        str = str.substr(1);
      } else if (str.startsWith('\t')) {
        parts.push({isSpace: true});
        str = str.substr(1);
      } else {
        const trimmed = str.trim();
        parts.push({string: trimmed});
        str = str.replace(trimmed, '');
      }
      // console.log('parts: ', parts, 'str: ', str);
      return parseString(str);
    };

    let bodyConsumed = body;
    while (bodyConsumed.length) {
      const found = placeholderRegex.exec(bodyConsumed);
      if (!found) {
        parseString(bodyConsumed);
        break;
      }
      const idx = found.index;
      const str = bodyConsumed.substring(0, idx);
      parseString(str);

      const placeholder = found[0];
      const objBit = obj[placeholder];
      if (!objBit) {
        parseString(placeholder);
      } else {
        if (objBit.type === PlaceholderType.Gap) {
          parts.push({gapPlaceholderBit: objBit});
        } else if (objBit.type === PlaceholderType.Select) {
          parts.push({selectPlaceholderBit: objBit});
        }
      }
      bodyConsumed = bodyConsumed.substring(idx + placeholder.length);
      placeholderRegex.lastIndex = 0;
    }
    // console.log(parts);
    return parts;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['bit'] || changes['clozeAndMultipleChoiceBit']) {
      this.parts = this.parseBody();
    }
  }

  ngOnInit() {
    this.format = this.bit?.format || this.clozeAndMultipleChoiceBit?.format;
    if (this.isSolutionGrouped || this.isInstructionGrouped) {
      this.computeGroupedItems();
    }

    this.initialized = true;
  }

  private computeGroupedItems() {
    const placeholders = this.bit?.placeholders;
    if (!placeholders) {
      return null;
    }

    const localGroupedResourceItems: Array<string> = [];
    let allAnswers: Array<string> = [];
    const solutions: any = {};
    const answers: any = {};

    const keys = Object.keys(placeholders);
    keys?.forEach(key => {
      const placeholder = placeholders[key] as GapPlaceholderBit;

      const toGroup = this.isSolutionGrouped
        ? placeholder?.solutions
        : (this.isInstructionGrouped ? [placeholder?.instruction] : null);


      let placeholderAnswerValue = '';

      if (placeholder?.answer?.text || placeholder?.isExample) {
        placeholderAnswerValue = this.bitUtilsService.convertBitSolutionAnswer((placeholder.answer?.text || placeholder.example));
        if (this.isInstructionGrouped && placeholder?.instruction) {
          allAnswers.push(placeholderAnswerValue);
        } else if (!this.isInstructionGrouped) {
          allAnswers.push(placeholderAnswerValue);
        }
      }
      if (this.bit?.quizCountItems) {
        toGroup.forEach(s => {
          solutions[s] = [...new Set([...(solutions[s] || []), ...placeholder.solutions])];
          answers[s] = [...new Set([...(answers[s] || []), placeholderAnswerValue])].filter(x => x);
        });
      }

      if (toGroup && toGroup.length) {
        if (this.bit?.quizCountItems) {
          toGroup.forEach(s => s && localGroupedResourceItems.push(s));
        } else {
          const found = this.isInstructionGrouped && placeholderAnswerValue ? 1 : 0;
          toGroup.forEach(s => {
            if (s) {
              this.groupedResourceItems.push({
                text: s,
                solutions: placeholder.solutions,
                occurrences: 1,
                found: this.isInstructionGrouped ? found : this.getFoundAnswers(allAnswers, placeholder.solutions, 1)
              });
            }
          });
        }
      }
    });

    if (this.bit.additionalSolutions?.length) {
      this.bit.additionalSolutions.forEach(additionalSolution => {
        solutions[additionalSolution] = [...new Set([...(solutions[additionalSolution] || []), additionalSolution])];
      })
      if (this.bit?.quizCountItems) {
        localGroupedResourceItems.push(...this.bit.additionalSolutions);
      } else {
        this.groupedResourceItems.push(...this.bit.additionalSolutions.map(x => {
          return {
            text: x,
            solutions: [],
            occurrences: 1,
            found: 0
          }
        }));
      }
    }

    if (!this.bit?.quizCountItems) {
      this.groupedResourceItems = this.arrayService.sortBy(this.groupedResourceItems, 'text');
      return;
    }

    const localGroupedByItems = groupBy(localGroupedResourceItems.sort(), (item: string) => {
      return item;
    });
    for (const prop in localGroupedByItems) {
      const occurrences = localGroupedByItems[prop]?.length || 0;
      const propSolutions = solutions[prop] || [];

      const found = this.isInstructionGrouped
        ? answers[prop]?.length
        : this.getFoundAnswers(allAnswers, propSolutions, occurrences);

      this.groupedResourceItems.push({
        text: prop,
        solutions: propSolutions,
        occurrences: occurrences,
        found: found
      });
    }
  }

  gapValueChanged() {
    if (this.bit?.quizStrikethroughSolutions) {
      const answers = this.parts.map(part => {
        return {
          instruction: part.gapPlaceholderBit?.instruction,
          text: this.bitUtilsService.convertBitSolutionAnswer(part.gapPlaceholderBit?.answer?.text)
        };
      }).filter(x => x.text);

      this.groupedResourceItems.forEach(item => {
        item.found = 0;
        const strokeAnswersPositions: Array<number> = [];
        answers.forEach((answer, index) => {
          if (item.found !== item.occurrences) {
            if (this.isInstructionGrouped && item.text === answer.instruction) {
              item.found++;
              strokeAnswersPositions.push(index);
            } else if (item.solutions?.includes(answer.text)) {
              item.found++;
              strokeAnswersPositions.push(index);
            }
          }
        });

        strokeAnswersPositions.reverse().forEach(pos => answers.splice(pos, 1));
      });
    }

    this.changed.emit();
  }

  private hasAnswer(bit: ClozeBit): boolean {
    const placeholders = bit?.placeholders;
    if (!placeholders) {
      return false;
    }

    let answerCount = 0;

    Object.keys(placeholders)?.forEach(key => {
      const placeholder = placeholders[key] as GapPlaceholderBit;

      if (placeholder?.answer?.text) {
        answerCount++;
      }
    });

    return answerCount > 0;
  }

  private getFoundAnswers(answers: Array<string>, solutions: Array<string>, occurrences: number): number {
    const strokeAnswersPositions: Array<number> = [];

    const foundAnswers = answers.filter((answer, index) => {
      if (strokeAnswersPositions.length >= occurrences) {
        return false;
      }

      const matches = solutions.includes(answer);
      if (matches) {
        strokeAnswersPositions.push(index);
      }
      return matches;
    })?.length;
    strokeAnswersPositions.reverse().forEach(pos => answers.splice(pos, 1));

    return foundAnswers;
  }

  private parseBody(): Array<PlaceholderPart> {
    if (this.bit) {
      return ClozeComponent.parseBody(this.bit.body, this.bit?.placeholders);
    }
    if (this.clozeAndMultipleChoiceBit) {
      return ClozeComponent.parseBody(this.clozeAndMultipleChoiceBit.body, this.clozeAndMultipleChoiceBit?.placeholders);
    }

    return [];
  }

  static evalAnswer(bit: ClozeBit): BitEvalResult {
    const placeholders = Object.values(bit.placeholders) as Array<any>;
    return {
      totalAnswers: placeholders.length,
      answeredAnswers: placeholders.filter(p => !!p.answer?.text).length,
      notAnsweredAnswers: placeholders.filter(p => !p.answer?.text).length,
      correctAnswers: placeholders.filter(p => {
        return !p.answer?.text
          ? false
          : p.type == 'select'
            ? p.options.filter(o => o.isCorrect).map(o => o.text).includes(p.answer?.text)
            : p.type == 'gap'
              ? p.solutions.includes(p.answer?.text)
              : false;
      }).length,
      incorrectAnswers: placeholders.filter(p => {
        return !p.answer?.text
          ? true
          : p.type == 'select'
            ? !p.options.filter(o => o.isCorrect).map(o => o.text).includes(p.answer?.text)
            : p.type == 'gap'
              ? !p.solutions.includes(p.answer?.text)
              : false;
      }).length
    };
  }
}
