import {Component, Input} from '@angular/core';
import {ReaderContentCommonComponent} from './reader-content-common.component';
import {BitApiWrapper, BitEditorStatus, BitType} from '../../../bits/bits.models';
import {DropdownItemModel} from '../../../shared';
import {BitmarkFormat} from '../../../shared/models/bitmark.models';
import {LearningPathLearningGoalBit} from '../../../bits/learning-path/learning-path-learning-goal/learning-path-learning-goal.models';
import {LearningPathBookBit} from '../../../bits/learning-path/learning-path-book/learning-path-book.model';
import {LearningPathStepBit} from '../../../bits/learning-path/learning-path-step/learning-path-step.models';
import {LearningPathVideoCallBit} from '../../../bits/learning-path/learning-path-video-call/learning-path-video-call.models';
import {LearningPathExternalLinkBit} from '../../../bits/learning-path/learning-path-external-link/learning-path-external-link.models';
import {LearningPathClassroomEventBit} from '../../../bits/learning-path/learning-path-classroom-event/learning-path-classroom-event.models';
import {LearningPathClosingBit} from '../../../bits/learning-path/learning-path-closing/learning-path-closing.models';
import {MessageBit} from '../../../bits/message/message.models';
import {BotActionSendBit} from '../../../bits/bot-action-send/bot-action-send.models';

@Component({
  template: ''
})
export class ReaderContentLearningPathCommonComponent extends ReaderContentCommonComponent {
  @Input() courseId?: string;

  protected createLPChapterAfterBit(dropdownItemModel: DropdownItemModel) {
    this.createChapterAfterBit(dropdownItemModel, this.courseId);
  }

  protected createLPNoteAfterBit(dropdownItemModel: DropdownItemModel) {
    this.createNoteAfterBit(dropdownItemModel, this.courseId);
  }

  protected createLearningPathGoalAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: LearningPathLearningGoalBit = {
      instruction: '',
      action: '',
      body: '',
      duration: '3600s',
      location: '',
      format: BitmarkFormat.PP,
      type: BitType.LearningPathLearningGoal
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected createLearningPathBookAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: LearningPathBookBit = {
      instruction: '',
      action: '',
      body: '',
      duration: '3600s',
      location: '',
      book: '',
      referenceEnd: '',
      format: BitmarkFormat.PP,
      type: BitType.LearningPathBook
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected createLearningPathStepAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: LearningPathStepBit = {
      instruction: '',
      action: '',
      body: '',
      duration: '3600s',
      location: '',
      format: BitmarkFormat.PP,
      type: BitType.LearningPathStep
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected createLearningPathVideoCallAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: LearningPathVideoCallBit = {
      instruction: '',
      action: '',
      body: '',
      duration: '3600s',
      location: '',
      videoCallLink: '',
      date: (new Date()).toISOString(),
      format: BitmarkFormat.PP,
      type: BitType.LearningPathVideoCall
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected createLearningPathExtLinkAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: LearningPathExternalLinkBit = {
      instruction: '',
      action: '',
      body: '',
      duration: '3600s',
      location: '',
      externalLink: '',
      externalLinkText: '',
      format: BitmarkFormat.PP,
      type: BitType.LearningPathExternalLink
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected createLearningPathClassroomEventAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: LearningPathClassroomEventBit = {
      instruction: '',
      action: '',
      body: '',
      duration: '3600s',
      location: '',
      date: (new Date()).toISOString(),
      format: BitmarkFormat.PP,
      type: BitType.LearningPathClassroomEvent
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected createLearningPathClosingAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: LearningPathClosingBit = {
      instruction: '',
      body: '',
      format: BitmarkFormat.PP,
      type: BitType.LearningPathClosing
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected createBotMessageAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: MessageBit = {
      instruction: '',
      body: '',
      format: BitmarkFormat.Text,
      type: BitType.Message
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected createBotActionSendAfterBit(dropdownItemModel: DropdownItemModel) {
    let bitWrapper;
    if (dropdownItemModel) {
      bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    }

    const newBit: BotActionSendBit = {
      instruction: '',
      date: (new Date()).toISOString(),
      format: BitmarkFormat.Text,
      type: BitType.BotActionSend
    };

    this.insertNewBit(newBit, bitWrapper?.id);
  }

  protected deleteCourseBit(dropdownItemModel: DropdownItemModel) {
    this.deleteBit(dropdownItemModel, this.courseId);
  }

  protected insertNewBit(newBit: any, bitWrapperId?: string) {
    this.bitEditorStatus = BitEditorStatus.Updating;
    this.bitBookApiService.insertBitAfterBit(this.bitBook.id, newBit, bitWrapperId, this.courseId)
      .subscribe((res: { bits: Array<BitApiWrapper>, toc: any }) => {
        res.bits?.forEach(b => b.isBeingEditedByUser = true);
        this.addBitsToBookContent(res.bits, bitWrapperId);
        this.setReaderAsReady();
      }, err => {
        console.error(err);
        this.bitEditorStatus = BitEditorStatus.Failed;
      });
  }

  protected insertCourseBasket(dropdownItemModel: DropdownItemModel) {
    this.onInsertBasket(dropdownItemModel, this.courseId);
  }
}
