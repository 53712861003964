import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PageProductVideoListBit} from './page-product-video-list.models';
import {BookType, HandInStatus, ProductFamily} from '../../shared/models/bitmark.models';
import {BitProductService} from "../bit-product.service";

@Component({
  selector: 'bitmark-page-product-video-list',
  templateUrl: './page-product-video-list.component.html',
  styleUrls: ['./page-product-video-list.component.scss']
})
export class PageProductVideoListComponent implements OnInit {
  @Input() bit?: PageProductVideoListBit;
  @Input() isNavigateDirectlyToBook?: boolean;
  @Output() navigateToProduct: EventEmitter<{ productId: string, family?: ProductFamily }> = new EventEmitter<{ productId: string, family?: ProductFamily }>();
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }> = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }>();
  isWideEntry = false;
  _operationId: string;
  products: Array<any>;

  @HostListener('document:gmbProductDetailsDelivered', ['$event'])
  onProductPriceDelivered(event: any) {
    this.products = this.bitProductService.getProductsFromEvent(event, this.products, this.bit?.productVideo);
  }

  constructor(private bitProductService: BitProductService) {
  }

  ngOnInit() {
    this._operationId = (this.bit?.productVideo[0]) + (Math.random() + 1).toString(36).substring(7);
    const event = new CustomEvent('gmbProductDetailsRequested', { detail: { productIds: this.bit?.productVideo, operationId: this._operationId} , bubbles: true });
    document.dispatchEvent(event);
  }

  openProductPage(product) {
    if (this.isNavigateDirectlyToBook) {
      if (product?.meta?.family === ProductFamily.XModule) {
        return this.navigateToBook.emit({bookId: product?._id, fragment: null, family: ProductFamily.XModule});
      }
      if (!product?.bookExists) {
        return;
      }
      this.navigateToBook.emit({bookId: product.meta?.bitmarkId || product?._id, fragment: 'start'})
    } else {
      this.navigateToProduct.emit({productId: product?._id, family: product?.meta?.family});
    }
  }

  setWideImage(){

  }

  getPropertyValue(arrayPropertyValue: Array<any>){
    if(arrayPropertyValue?.length){
      return arrayPropertyValue[0]?.data || arrayPropertyValue[0]?.file?.url;
    }
    return null;
  }

  protected readonly BookType = BookType;
  protected readonly HandInStatus = HandInStatus;
}
