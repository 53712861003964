import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatchMatrixBit, MatchMatrixCell, MatchMatrixRow} from './match-matrix.models';
import {BitResource} from '../bits.models';
import BitUtilsService from '../../shared/utils/bit-utils.service';
import {BitEvalResult} from "../bit-eval.service";

@Component({
  selector: 'bitmark-match-matrix',
  templateUrl: './match-matrix.component.html',
  styleUrls: ['./match-matrix.component.scss', '../bits.scss']
})
export class MatchMatrixComponent implements OnChanges {
  @Input() bit?: MatchMatrixBit;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() focused = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<any>();

  answersCss = {};

  constructor(private bitUtilsService: BitUtilsService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      this.bit.matrix?.forEach((r: MatchMatrixRow, ridx: number) => {
        r.cells?.forEach((c: MatchMatrixCell, cidx: number) => {
          const isAnswerCorrect = c.values
            .map(x => this.bitUtilsService.convertBitSolutionAnswer(x, r.isCaseSensitive))
            .includes(this.bitUtilsService.convertBitSolutionAnswer(c.answer?.text, r.isCaseSensitive));
          this.answersCss[ridx * 10 + cidx] = c.isExample || (isAnswerCorrect && this.hasFeedback)
            ? 'correct'
            : this.hasFeedback
              ? 'incorrect'
              : '';
        });
      });
    }
  }

  change(cell: MatchMatrixCell, evt: Event) {
    cell.answer = {text: (evt.target as any)?.value};
    this.changed.emit();
  }

  static evalAnswer(bit: MatchMatrixBit): BitEvalResult {
    const ret = {
      totalAnswers: 0,
      answeredAnswers: 0,
      notAnsweredAnswers: 0,
      correctAnswers: 0,
      incorrectAnswers: 0
    } as BitEvalResult;

    bit.matrix.forEach((row: MatchMatrixRow) => {
      ret.totalAnswers += row.cells.length;
      ret.answeredAnswers += row.cells.filter((c: MatchMatrixCell) => !!c.answer?.text).length;
      ret.notAnsweredAnswers += row.cells.filter((c: MatchMatrixCell) => !c.answer?.text).length;
      ret.correctAnswers += row.cells.filter((c: MatchMatrixCell) => !!c.answer?.text && c.values.includes(c.answer.text)).length;
      ret.incorrectAnswers += row.cells.filter((c: MatchMatrixCell) => (!!c.answer?.text && !c.values.includes(c.answer.text)) || !c.answer?.text).length;
    });

    return ret;
  }
}
