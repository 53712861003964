// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bot-action-response-container .bot-action-response-message-arrow {
  position: absolute;
  top: -11px;
  left: 15px;
  color: #9bd0ff;
}
.bot-action-response-container .bot-action-user-answer-message-arrow {
  position: absolute;
  top: -11px;
  right: 15px;
  color: #9bd0ff;
}
.bot-action-response-container .bot-action-response-message {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 600px;
  background-color: #9bd0ff;
  border-radius: 0.3rem;
}

.bot-check-container .bot-check {
  background-color: #5e63c7;
  border-radius: 50%;
}

.bot-action-option {
  border-radius: 25px;
}
.bot-action-option .bot-action-option-item {
  color: #ffffff;
  background-color: #543199;
}

.animation-init {
  opacity: 0;
  transform: translate3d(0, 25px, 0);
  transition-property: opacity, transform;
}

.animation-animate {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.animation-typing {
  animation: typing;
  animation-duration: 2s;
}

.transition-delay-1s {
  transition-delay: 1s;
}

.animation-delay-1s {
  animation-delay: 1s;
}

.transition-delay-2s {
  transition-delay: 2s;
}

.animation-delay-2s {
  animation-delay: 2s;
}

.transition-delay-3s {
  transition-delay: 3s;
}

.animation-delay-3s {
  animation-delay: 3s;
}

.transition-delay-4s {
  transition-delay: 4s;
}

.animation-delay-4s {
  animation-delay: 4s;
}

.transition-delay-5s {
  transition-delay: 5s;
}

.animation-delay-5s {
  animation-delay: 5s;
}

.transition-delay-6s {
  transition-delay: 6s;
}

.animation-delay-6s {
  animation-delay: 6s;
}

.bot-action-response-typing-message {
  top: 0;
  left: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.bot-action-response-typing-message span {
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
  height: 10px;
  width: 10px;
  background: #543199;
  border-radius: 50%;
}
.bot-action-response-typing-message span:nth-child(2) {
  animation-delay: 0.2s;
}
.bot-action-response-typing-message span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes typing {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
