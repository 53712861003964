import {Component, Inject, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ClipboardService} from 'ngx-clipboard';
import {DropdownItemModel} from '../../shared';
import {BitApiWrapper, BitResourceType, BitType} from '../bits.models';
import {BitmarkConfig} from '../../bitmark.module';
import {BitmarkFormat} from '../../shared/models/bitmark.models';
import {InterviewBit, InterviewQuestion} from '../interview/interview.models';
import BitUtilsService from '../../shared/utils/bit-utils.service';
import {AppFlashcardsBit} from '../app/app-flashcards/app-flashcards.models';
import {BrandingRenderService} from '../../reader/branding-render.service';
import {AppCodeEditorBit} from '../app/app-code-editor/app-code-editor.models';
import {CookIngredientsBit} from '../cook-ingredients/cook-ingredients.models';

@Component({
  selector: 'bitmark-bit-actions',
  templateUrl: './bit-actions.component.html',
  styleUrls: ['./bit-actions.component.scss']
})
export class BitActionsComponent implements OnInit {
  @Input() bitWrapper?: BitApiWrapper;
  @Input() actions: Array<DropdownItemModel> = [];
  @Input() isCustomIcon = false;
  @Input() cssClass = '';
  @Input() dropdownClass = '';
  augmentedActions: Array<DropdownItemModel> = [];

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private translate: TranslateService,
              private clipboardService: ClipboardService,
              private bitUtilsService: BitUtilsService,
              private brandingRenderService: BrandingRenderService) {
  }

  ngOnInit() {
    requestAnimationFrame(() => {
      this.cssClass = this.cssClass?.indexOf('position-relative') !== -1
        ? this.cssClass
        : this.cssClass.concat(' dropdown-position');
      if ([BitType.AnnotationBookmark].includes(this.bitWrapper?.bit?.type)) {
        this.cssClass = this.cssClass.concat(' annotation');
      }
    });
  }

  augmentActions($event: MouseEvent) {
    if (this.bitWrapper?.bit?.type === BitType.VirtualMarkBookAsRead) {
      this.augmentedActions = this.actions?.filter((a) => a.id === 'reset-book-rating')?.map((i) => {
        return Object.assign({}, this.getAugmentedItem(i), {isHidden: true});
      });
      this.augmentedActions?.forEach((a) => a.isHidden = false);
      return;
    } else {
      const brb = this.actions?.find((a) => a.id === 'reset-book-rating');
      if (brb) {
        brb.isHidden = true;
      }
    }
    this.actions = this.actions.map(x => {
      if (x.showOnAltKey !== undefined) {
        x.isHidden = !(x.showOnAltKey === $event.altKey);
      }
      return x;
    });

    if (this.bitWrapper?.bit && this.actions.find(x => x.id === 'move-to-bin' || x.id === 'delete-immediately') && !$event.altKey) {
      const bitBody = (this.bitWrapper?.bit as any)?.body
        || (this.bitWrapper?.bit as any)?.bit
        || (this.bitWrapper?.bit as any)?.content; // bit & content are for prosemirror bit
      const isBitEmpty = this.bitUtilsService.isBitEmpty(this.bitWrapper.bit, bitBody);
      this.actions = this.actions.map(a => {
        if (a.id === 'move-to-bin') {
          a.isHidden = isBitEmpty;
        } else if (a.id === 'delete-immediately') {
          a.isHidden = !isBitEmpty;
        }
        return a;
      });
    }

    if (!this.bitWrapper?.meta?.originBook?.externalId || !(this.bitWrapper?.meta?.originBitId || this.bitWrapper?.meta?.rootBitId)) {
      this.actions = this.actions.filter((a) => a.id !== 'find-in-book');
    }
    if (![
      BitType.Article,
      BitType.Info,
      BitType.SideNote,
      BitType.Remark,
      BitType.Example,
      BitType.Note,
      BitType.Details1,
      BitType.SampleSolution,
      BitType.Message,
      BitType.BotActionSend,
      BitType.PadletEmbed,
      BitType.LearningPathLti,
      BitType.LearningPathBook,
      BitType.LearningPathClosing,
      BitType.LearningPathStep,
      BitType.LearningPathLearningGoal,
      BitType.LearningPathExternalLink,
      BitType.LearningPathClassroomEvent,
      BitType.LearningPathVideoCall,
      BitType.Scorm,
      BitType.Module
    ].includes(this.bitWrapper?.bit?.type)) {
      // ].includes(this.bitWrapper?.bit?.type) || this.bitWrapper?.bit?.format === BitmarkFormat.Prosemirror) {
      this.actions?.forEach(x => {
        if (['show-hidden-fields', 'hide-hidden-fields'].includes(x.id)) {
          x.isHidden = true;
        }
      });
    } else {
      const isAlreadyEditing = this.bitWrapper?.isBeingEditedByUser;
      const showAction = this.actions.find(x => x.id === 'show-hidden-fields');
      const hideAction = this.actions.find(x => x.id === 'hide-hidden-fields');
      if (hideAction) {
        hideAction.isHidden = !isAlreadyEditing;
      }
      if (showAction) {
        showAction.isHidden = isAlreadyEditing;
      }
      const sep = this.actions.find(i => i.id === 'edit-bit-data-separator');
      if (sep) {
        sep.isHidden = false;
      }
    }
    if (this.bitWrapper?.bit?.type === BitType.Page && (this.bitWrapper?.bit as any)?.body && this.actions.findIndex((a) => {
      return a.label === this.translate.instant('Reader.Actions.ExtractText');
    }) === -1) {
      const idx = this.actions.findIndex((b) => b.label === 'Reader.Actions.EditBitmarkType');
      if (idx !== -1) {
        this.actions.splice(idx + 1, 0, {
          label: this.translate.instant('Reader.Actions.ExtractText'),
          handler: this.copyBitmarkToClipboard.bind(this)
        });
      }
    }
    const idx = this.actions.findIndex((b) => b.label === 'Reader.Actions.EditBitmarkType');
    if (idx !== -1) {
      this.actions[idx].isHidden = !this.bitmarkConfig.isProUser;
    }
    // const idxGenerate = this.actions.findIndex((b) => b.id === 'generate-quizzes-for-chapter');
    // if (idxGenerate !== -1 && (this.bitWrapper?.bit?.type !== BitType.Chapter || (this.bitWrapper?.bit as any).level !== 1)) {
    //   this.actions[idxGenerate].isHidden = true;
    // } else {
    //   if (idxGenerate !== -1) {
    //     this.actions[idxGenerate].isHidden = !this.bitmarkConfig.isProUser;
    //   }
    // }
    const idxColor = this.actions.findIndex((b) => b.id === 'color');
    if (this.bitWrapper?.bit?.type === BitType.AnnotationHandwritten && idxColor !== -1) {
      this.actions[idxColor].isHidden = true;
      this.actions[idxColor + 1].isHidden = true;
    } else if (idxColor !== -1) {
      this.actions[idxColor].isHidden = false;
      this.actions[idxColor + 1].isHidden = false;
    }
    const idxSpeechToText = this.actions.findIndex((b) => b.id === 'speech-to-text');
    if (([BitResourceType.Audio, BitResourceType.Video].includes(this.bitWrapper?.bit?.resource?.type)
        || [BitType.RecordAudio, BitType.RecordVideo].includes(this.bitWrapper?.bit?.type))
      && idxSpeechToText !== -1) {
      this.actions[idxSpeechToText].isHidden = false;
    } else if (idxSpeechToText !== -1) {
      this.actions[idxSpeechToText].isHidden = true;
    }

    this.actions.forEach(action => {
      if (action.id?.includes('code-language')) {
        action.isHidden = ![BitType.Code, BitType.AppCodeEditor].includes(this.bitWrapper?.bit?.type);
      }
      if (action.id === 'code-language') {
        action.label = this.translate.instant('Profile.Language') + ' ' + ((this.bitWrapper?.bit as AppCodeEditorBit)?.computerLanguage || 'plaintext');
      } else if (action.id === 'code-language-change') {
        if (this.bitWrapper?.bit?.type === BitType.AppCodeEditor) {
          action.isHidden = false;
          const origHandler = action.actions[0].handler;
          action.actions = (window as any).monaco.languages
            .getLanguages()
            .map(lang => {
              return {
                id: lang.id,
                label: lang.id,
                handler: origHandler
              };
            });
        } else {
          action.isHidden = true;
        }
      }
    });


    this.augmentedActions = this.actions.map((a: DropdownItemModel) => {
      if (a?.actions?.length) {
        a.actions = a.actions.map((x: DropdownItemModel) => {
          return this.getAugmentedItem(x);
        });
      }
      return this.getAugmentedItem(a);
    });
  }

  private isBodyNonEmpty(): boolean {
    const bit = this.bitWrapper?.bit as any;

    if (typeof bit?.body === 'string') {
      return bit.body.trim().length;
    }

    return true;
  }

  private getAugmentedItem(a: DropdownItemModel) {
    if (a.label === 'Reader.Actions.CopyBitmarkType') {
      return Object.assign({}, a, {
        label: a.label?.replace('Reader.Actions.CopyBitmarkType', this.translate.instant('Reader.Actions.CopyBitmarkType', {bitmarkType: `[.${this.bitWrapper?.bit?.type}]`})),
        data: this.bitWrapper
      });
    }
    if (a.label === 'Reader.Actions.EditBitmarkType') {
      return Object.assign({}, a, {
        label: a.label?.replace('Reader.Actions.EditBitmarkType', this.translate.instant('Reader.Actions.EditBitmarkType', {bitmarkType: `[.${this.bitWrapper?.bit?.type}]`})),
        data: this.bitWrapper
      });
    }
    if (a.label === 'Reader.Actions.BrandingPublisherTheme') {
      return Object.assign({}, a, {
        label: a.label?.replace('Reader.Actions.BrandingPublisherTheme',
          this.translate.instant(
            'Reader.Actions.BrandingPublisherTheme',
            {
              publisherName: this.brandingRenderService.getBitPublisherName(this.bitWrapper),
              publisherId: this.brandingRenderService.getBitPublisherCode(this.bitWrapper),
              theme: this.brandingRenderService.getBitThemeName(this.bitWrapper),
              newline: '<br/>'
            }
          )),
        data: this.bitWrapper
      });
    }
    if (a.id === 'reset-answer') {
      let hasInterviewQuestionAnswered = false;
      if (this.bitWrapper?.bit?.type === BitType.Interview) {
        const interviewBit = this.bitWrapper.bit as InterviewBit;
        if (interviewBit) {
          hasInterviewQuestionAnswered = !!interviewBit.questions?.filter((x: InterviewQuestion) => x.answer?.text)?.length;
        }
      }

      if (this.bitWrapper?.bit?.type === BitType.AppFlashcards) {
        const flashcardsBit = this.bitWrapper.bit as AppFlashcardsBit;
        if (flashcardsBit) {
          const hasFlashcardsQuestionAnswered = !!flashcardsBit.answer?.state
            || !!flashcardsBit.answer?.normal
            || !!flashcardsBit.answer?.leitner?.state
            || !!flashcardsBit.answer?.leitner?.pots?.filter(x => x.length).length;

          return Object.assign({}, a, {
            isHidden: !hasFlashcardsQuestionAnswered,
            data: this.bitWrapper
          });
        }
      }

      if (this.bitWrapper?.bit?.type === BitType.CookIngredients) {
        const cookIngredientsBit = this.bitWrapper.bit as CookIngredientsBit;
        if (cookIngredientsBit) {
          const hasCookIngredientsAnswer = !!cookIngredientsBit.ingredients?.find(x => x.answer !== undefined);
          return Object.assign({}, a, {
            isHidden: !hasCookIngredientsAnswer,
            data: this.bitWrapper
          });
        }
      }

      if (this.bitWrapper?.bit?.isExample) {
        return Object.assign({}, a, {
          isHidden: !this.bitWrapper?.bitInstanceId,
          data: this.bitWrapper
        });
      }

      return Object.assign({}, a, {
        isHidden: !this.bitWrapper?.bit?.feedback
          && !this.bitWrapper?.bit?.answer
          && !hasInterviewQuestionAnswered,
        data: this.bitWrapper
      });
    }

    if (a.id === 'reset-highlights') {
      return {
        ...a,
        isHidden: !(this.bitWrapper.bitInstanceId && this.containsHighlights(this.bitWrapper.bit.id)),
        data: this.bitWrapper
      };
    }

    if (a.id === 'shuffle') {
      return {
        ...a,
        isHidden: this.bitWrapper?.bit?.type !== BitType.AppFlashcards
      };
    }

    return Object.assign({}, a, {
      label: a.label,
      data: this.bitWrapper
    });
  }

  private copyBitmarkToClipboard(dropdownItemModel: DropdownItemModel) {
    const bitWrapper = (dropdownItemModel.data as BitApiWrapper);
    const bdy = (bitWrapper.bit as any).body;
    return this.clipboardService.copy(bdy.replace(/\s{2,}/g, ' ') || '');
  }

  private containsHighlights(bitId: string): boolean {
    return document.querySelector(`#bit-${bitId} mark.user-highlight, #bit-${bitId} mark.bitmark-text-user-highlight`) !== null;
  }
}
