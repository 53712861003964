import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbDateAdapter, NgbDatepickerI18n, NgbTimeAdapter, NgbTimepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {cloneDeep} from 'lodash';
import {createEvent, EventAttributes} from 'ics';
import {LearningPathVideoCallBit} from './learning-path-video-call.models';
import {BitResource} from '../../bits.models';
import {CustomDateAdapter} from '../../../shared/adapters/custom-date.adapter';
import {CustomTimeAdapter} from '../../../shared/adapters/custom-time.adapter';
import {CustomDatepickerI18n} from '../../../shared/i18n/custom-datepicker.i18n';
import {CustomTimepickerI18n} from '../../../shared/i18n/custom-timepicker.i18n';
import {TimeService} from '../../../shared/utils/time.service';
import {FileUtilsService} from '../../../shared/utils/file-utils.service';
import UrlService from '../../../shared/utils/url.service';
import {LearningPathCommon} from '../learning-path.common';
import {BitmarkTextContentService} from '../../../shared';
import {ReaderTipTapTapService} from "../../../reader/tiptap/reader-tiptap.service";

@Component({
  selector: 'bitmark-learning-path-video-call',
  templateUrl: './learning-path-video-call.component.html',
  styleUrls: ['./learning-path-video-call.component.scss', '../learning-path.common.scss', '../../bits.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomDateAdapter},
    {provide: NgbTimeAdapter, useClass: CustomTimeAdapter},
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbTimepickerI18n, useClass: CustomTimepickerI18n}
  ]
})
export class LearningPathVideoCallComponent extends LearningPathCommon {
  @Input() bit?: LearningPathVideoCallBit;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() changed = new EventEmitter<any>();
  @Output() editClosed = new EventEmitter<any>();
  @Output() saveInlineBit = new EventEmitter<LearningPathVideoCallBit>();

  private _isBeingEditedByUser?: boolean;
  @Input()
  set isBeingEditedByUser(value: boolean) {
    this._isBeingEditedByUser = value;
    this.trimHeader(this.bit);

    if (value) {
      this.isContentVisible = true;
      this.prevLearningPathVideoCallBit = cloneDeep(this.bit);
      this.learningPathVideoCallBitTime = this.bit.date || null;
      this.learningPathVideoCallBitEndTime = this.bit.dateEnd || null;
    }
  }
  get isBeingEditedByUser(): boolean {
    return this._isBeingEditedByUser;
  }

  private _saveUserEdit?: boolean;
  @Input()
  set saveUserEdit(value: boolean) {
    this._saveUserEdit = value;

    if (this.isBeingEditedByUser && value) {
      this.saveChanges();
    }
  }
  get saveUserEdit(): boolean {
    return this._saveUserEdit;
  }

  public isContentVisible = true;
  private prevLearningPathVideoCallBit?: LearningPathVideoCallBit;
  public learningPathVideoCallBitTime?: string | null;
  public learningPathVideoCallBitEndTime?: string | null;

  get isHeaderMinimized(): boolean {
    return !this.isContentVisible ||
      (this.bit.isInfoOnly && !this.bit.body);
  }

  get isHeaderOnly(): boolean {
    if (this.bit.isInfoOnly && !this.bit.body && !this.isBeingEditedByUser) {
      return true;
    }

    return !this.bit.body && !this.isBeingEditedByUser && !this.bit.videoCallLink;
  }

  get isTracked(): boolean {
    return this.bit.isTracked ?? true;
  }

  constructor(private timeService: TimeService,
              private fileUtilsService: FileUtilsService,
              readerTipTapService: ReaderTipTapTapService,
              bitmarkTextContentService: BitmarkTextContentService,
              private urlService: UrlService) {
    super(readerTipTapService, bitmarkTextContentService);
  }

  cancelChanges() {
    this.bit = cloneDeep(this.prevLearningPathVideoCallBit);
    this.editClosed.emit();
  }

  saveChanges() {
    if (this.bit.date && this.learningPathVideoCallBitTime) {
      const newDate = new Date(this.bit.date);
      const newTime = new Date(this.learningPathVideoCallBitTime);
      this.bit.date = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newTime.getHours(), newTime.getMinutes(), newTime.getSeconds()).toISOString();
    }

    if (this.bit.date && this.learningPathVideoCallBitEndTime) {
      const newDate = new Date(this.bit.date);
      const newTime = new Date(this.learningPathVideoCallBitEndTime);
      this.bit.dateEnd = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), newTime.getHours(), newTime.getMinutes(), newTime.getSeconds()).toISOString();
    }

    this.trimHeader(this.bit);
    this.saveInlineBit.emit(this.bit);
  }

  toggleContent() {
    this.isContentVisible = !this.isContentVisible;
  }

  joinCall() {
    if (!this.bit.answer?.hasJoined && this.isTracked) {
      this.bit.answer = {
        ...this.bit.answer,
        hasJoined: true
      };
      this.changed.emit();
    }

    if (this.bit.videoCallLink) {
      window.open(this.bit.videoCallLink, '_blank');
    }
  }

  markAsDone() {
    this.bit.answer = {
      ...this.bit.answer,
      isDone: true
    };
    this.changed.emit();
  }

  reset(event) {
    event.stopPropagation();
    this.bit.answer = {
      hasJoined: false,
      isDone: false
    };
    this.changed.emit();
  }

  downloadAddToCalendar() {
    if (!this.bit.date) {
      return;
    }

    const startDate = new Date(this.bit.date);
    const endDate = this.bit.dateEnd ? new Date(this.bit.dateEnd) : null;
    const duration = this.timeService.parseDuration(this.bit.duration);

    let body = this.getTextFromJson(this.bit.body, this.bit.format);
    if (this.bit.action) {
      body = `${this.bit.action}\r\n${body}`;
    }

    const event: EventAttributes = {
      start: [startDate.getUTCFullYear(), startDate.getUTCMonth() + 1, startDate.getUTCDate(), startDate.getUTCHours(), startDate.getUTCMinutes()],
      startInputType: 'utc',
      startOutputType: 'local',
      end: endDate ? [endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, endDate.getUTCDate(), endDate.getUTCHours(), endDate.getUTCMinutes()] : undefined,
      endInputType: 'utc',
      endOutputType: 'local',
      duration: endDate ? undefined : {hours: duration.hours(), minutes: duration.minutes()},
      title: this.bit.instruction || ' ',
      description: body,
      location: this.bit.location,
      url: this.urlService.isValid(this.bit.videoCallLink) ? this.bit.videoCallLink : undefined,
      busyStatus: 'BUSY'
    };

    createEvent(event, (error, value) => {
      if (error) {
        console.error(error);
        return;
      }
      this.fileUtilsService.download('meeting.ics', value);
    });
  }
}
