// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  word-break: break-word;
}

.bit-container[lang] {
  -webkit-hyphens: auto;
          hyphens: auto;
}

.feedback-button-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 70px;
    opacity: 1;
  }
}
.fadeInDown {
  animation-name: fadeInDown;
}

.full-screen {
  height: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
