// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-wrapper {
  display: inline-flex;
  align-items: center;
}
.item-wrapper .item-bit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.item-wrapper .item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.lead-bit {
  display: inline-flex;
  align-items: baseline;
}

.bitmark-instruction {
  font-family: var(--bitmark-module-instruction-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-module-instruction-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-module-instruction-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-module-instruction-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-module-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-module-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-module-instruction-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-module-instruction-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-module-instruction-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-module-instruction-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-module-instruction-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-module-instruction-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-module-instruction-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-module-instruction-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-module-instruction-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-module-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-instruction-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-module-instruction-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-module-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-instruction-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-module-instruction-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-module-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-instruction-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-module-instruction-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-module-instruction-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-instruction-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-module-instruction-border-radius, var(--bitmark-x-border-radius, y));
}

.bitmark-item {
  font-family: var(--bitmark-module-item-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-module-item-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-module-item-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-module-item-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-module-item-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-module-item-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-module-item-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-module-item-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-module-item-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-module-item-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-module-item-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-module-item-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-module-item-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-module-item-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-module-item-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-module-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-item-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-module-item-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-module-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-item-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-module-item-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-module-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-item-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-module-item-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-module-item-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-item-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-module-item-border-radius, var(--bitmark-x-border-radius, y));
}

.bitmark-lead {
  font-family: var(--bitmark-module-lead-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-module-lead-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-module-lead-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-module-lead-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-module-lead-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-module-lead-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-module-lead-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-module-lead-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-module-lead-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-module-lead-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-module-lead-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-module-lead-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-module-lead-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-module-lead-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-module-lead-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-module-lead-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-lead-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-module-lead-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-module-lead-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-lead-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-module-lead-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-module-lead-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-lead-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-module-lead-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-module-lead-border-style, var(--bitmark-x-border-style)) var(--bitmark-module-lead-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-module-lead-border-radius, var(--bitmark-x-border-radius, y));
}

.lead-dash-module {
  line-height: var(--bitmark-module-lead-font-line-height);
  font-family: var(--bitmark-module-lead-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-module-lead-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-module-lead-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-module-lead-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-module-lead-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-module-lead-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-module-lead-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-module-lead-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-module-lead-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-module-lead-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.instruction-bit {
  display: block;
  white-space: pre-wrap;
}

.technical-term-bit {
  display: flex;
  white-space: pre-wrap;
}

.instruction-technical-term-container {
  width: 100%;
}

.duration {
  position: absolute;
  right: -20px;
  top: 16px;
  padding: 2px 16px 2px 10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: var(--bitmark-module-duration-background-color, --bitmark-reader-primary-color, #543199);
  font-family: var(--bitmark-module-duration-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-module-duration-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-module-duration-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-module-duration-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-module-duration-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-module-duration-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-module-duration-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-module-duration-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-module-duration-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-module-duration-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.cursor-pointer {
  cursor: pointer;
}

.accordion-image img {
  max-height: 100%;
}
.accordion-image .image-container {
  width: 60px;
  min-height: 60px;
  min-width: 60px;
}

.remove-image {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--bitmark-reader-primary-color);
}

.image-placeholder {
  width: 60px;
  height: 60px;
  opacity: 0.7;
  color: var(--bitmark-reader-primary-color);
  fill: var(--bitmark-reader-primary-color);
}

.image-editable {
  cursor: pointer;
}
.image-editable .image-container {
  background-color: white;
  opacity: 0.5;
}

.image-container {
  background-image: none !important;
}

::ng-deep .module-duration-picker-window #ngb-popover-0 {
  position: fixed !important;
  right: -50px !important;
  top: 35px !important;
  left: auto !important;
}
::ng-deep .bit-type-module .bit-icon {
  display: none !important;
}

.module-fields-container-small {
  max-width: 310px;
}

.module-fields-container-large {
  max-width: 350px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
