// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-button-wrapper {
  font-family: var(--bitmark-internal-link-instruction-font-family, var(--bitmark-bit-instruction-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-internal-link-instruction-font-size, var(--bitmark-bit-instruction-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-internal-link-instruction-font-weight, var(--bitmark-bit-instruction-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-internal-link-instruction-font-style, var(--bitmark-bit-instruction-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-internal-link-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-internal-link-instruction-font-text-decoration, var(--bitmark-bit-instruction-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-internal-link-instruction-font-line-height, var(--bitmark-bit-instruction-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-internal-link-instruction-font-color, var(--bitmark-bit-instruction-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-internal-link-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-internal-link-instruction-font-align, var(--bitmark-bit-instruction-font-align, var(--bitmark-main-font-alignt, left)));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
