import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

declare var loadImage: any; // package blueimp-load-image

@Injectable({providedIn: 'root'})
export class ImageProcessorService {
  loadWithOptions(imageFileOrBlobOrUrl: File, options: any): Observable<{ canvas: HTMLCanvasElement, blob: Blob }> {
    const allOptions: any = Object.assign(options, {
      meta: true,
      canvas: true,
      orientation: true,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high'
    });
    // if (allOptions.maxWidth) {
    //   allOptions.maxWidth = allOptions.maxWidth / window.devicePixelRatio;
    // }
    // if (allOptions.maxHeight) {
    //   allOptions.maxHeight = allOptions.maxHeight / window.devicePixelRatio;
    // }
    return new Observable((x: any) => {
      loadImage(imageFileOrBlobOrUrl, (canvas, data) => {
        // alert(JSON.stringify(data));
        // document.body.appendChild(canvas);
        canvas.toBlob((blob) => {
          x.next({
            canvas,
            blob
          });
          x.complete();
        });
      }, allOptions);
    });
  }

  blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // The result attribute contains the data as a base64 encoded string
        resolve(reader.result);
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(blob);
    });
  }
}
