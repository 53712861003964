// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-wrapper {
  display: inline-flex;
  align-items: center;
}
.item-wrapper .item-bit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.item-wrapper .item-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.lead-bit {
  display: inline-flex;
  align-items: baseline;
}

.instruction-bit {
  display: block;
  white-space: pre-wrap;
}

.technical-term-bit {
  display: flex;
  white-space: pre-wrap;
}

.instruction-technical-term-container {
  width: 100%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
