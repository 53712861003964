// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buy-button-container {
  display: flex;
  justify-content: var(--bitmark-page-buy-button-flex-align, flex-start);
}

.buy-button-body {
  margin: var(--bitmark-page-buy-button-body-size-margin);
  padding: var(--bitmark-page-buy-button-body-size-padding);
  height: var(--bitmark-page-buy-button-body-size-height);
  width: var(--bitmark-page-buy-button-body-size-width);
}

.btn-background-calm.buy-button {
  background: var(--bitmark-page-buy-button-button-background-shorthand, var(--bitmark-page-buy-button-button-background, #543199));
  color: var(--bitmark-page-buy-button-button-color, #ffffff);
  padding: var(--bitmark-page-buy-button-button-size-padding, var(--bitmark-page-buy-button-button-size-padding, 3px 32px));
  border-top: var(--bitmark-page-buy-button-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-page-buy-button-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-buy-button-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-page-buy-button-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-page-buy-button-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-buy-button-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-page-buy-button-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-page-buy-button-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-buy-button-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-page-buy-button-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-page-buy-button-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-page-buy-button-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-page-buy-button-button-border-radius, var(--bitmark-x-border-radius, 30px));
  width: var(--bitmark-page-buy-button-button-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-page-buy-button-button-size-height, var(--bitmark-x-size-height, inherit));
  font-family: var(--bitmark-page-buy-button-button-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-page-buy-button-button-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-page-buy-button-button-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-page-buy-button-button-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-page-buy-button-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-page-buy-button-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-page-buy-button-button-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-page-buy-button-button-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-page-buy-button-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-page-buy-button-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  margin: var(--bitmark-page-buy-button-button-size-margin, var(--bitmark-x-size-margin, 0));
}

.add-to-library {
  padding: 5px 0 0 0;
  border-radius: 100%;
  min-width: inherit;
  width: 40px;
  height: 40px;
  border: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
