import {Injectable} from '@angular/core';
import {MqService} from 'core';
import {Observable} from 'rxjs';
import {PluginType, TimelineEntry} from 'main/timeline/timeline.models';
import {BitType} from "bitmark";
import {BitEntry} from 'main/timeline/bit/bit.models';

@Injectable({providedIn: 'root'})
export class TimelineMqService {
  constructor(private mqService: MqService) {
  }

  notifyNewEntrySubmitError(err: any) {
    this.mqService.pub('timeline-new-entry-error', err);
  }

  onNewEntrySubmitError(): Observable<any> {
    return this.mqService.sub('timeline-new-entry-error');
  }

  notifyEntryDeleted(aggregateId: number, pluginType: PluginType) {
    this.mqService.pub('timeline-entry-deleted', {aggregateId, pluginType});
  }

  onEntryDeleted(): Observable<{ aggregateId: number, pluginType: PluginType }> {
    return this.mqService.sub('timeline-entry-deleted');
  }

  notifyEntryRead(id: number) {
    this.mqService.pub('timeline-entry-read', id);
  }

  onEntryRead(): Observable<number> {
    return this.mqService.sub('timeline-entry-read');
  }

  notifyEntryReplying(message: { entry: TimelineEntry, isReplyBy: number }) {
    return this.mqService.pub('timeline-replying-entry', message);
  }

  onEntryReplying(): Observable<{ isReplyBy: number, entry: any }> {
    return this.mqService.sub('timeline-replying-entry');
  }

  notifyConnectWithBot(botId: number, bitType: BitType, message: string) {
    return this.mqService.pub('timeline-connect-with-bot', {botId, bitType, message});
  }

  onConnectWithBot(): Observable<{ botId: number, bitType: BitType, message: string }> {
    return this.mqService.sub('timeline-connect-with-bot');
  }

  notifyConnectWithUsers(emails: string) {
    return this.mqService.pub('timeline-connect-with-users', emails);
  }

  onConnectWithUsers(): Observable<number> {
    return this.mqService.sub('timeline-connect-with-users');
  }

  notifyEntryReplyLoaded(entryHeight: number) {
    return this.mqService.pub('timeline-reply-loaded', entryHeight);
  }

  onEntryReplyLoaded(): Observable<number> {
    return this.mqService.sub('timeline-reply-loaded');
  }

  notifyEntryReplyingEnd(timelineEntryId: number) {
    return this.mqService.pub('timeline-replying-entry-end', timelineEntryId);
  }

  onEntryReplyingEnd(): Observable<number> {
    return this.mqService.sub('timeline-replying-entry-end');
  }

  notifyEntryCreatedLocal(x: TimelineEntry) {
    return this.mqService.pub('timeline-created-local-entry', x);
  }

  onNewEntryCreatedLocal(): Observable<TimelineEntry> {
    return this.mqService.sub('timeline-created-local-entry');
  }

  notifyEntryCreatedAndProcessedLocal(x: TimelineEntry) {
    return this.mqService.pub('timeline-created-processed-local-entry', x);
  }

  onNewEntryCreatedAndProcessedLocal(): Observable<TimelineEntry> {
    return this.mqService.sub('timeline-created-processed-local-entry');
  }

  notifyScrollToEntry(timelineEntryId: number) {
    return this.mqService.pub('timeline-scroll-to-entry', timelineEntryId);
  }

  onScrollToEntry(): Observable<number> {
    return this.mqService.sub('timeline-scroll-to-entry');
  }

  notifyConReqIdChanged(conReqId: number) {
    return this.mqService.pub('timeline-con-req-id-changed', conReqId);
  }

  onConReqIdChanged(): Observable<number> {
    return this.mqService.sub('timeline-con-req-id-changed');
  }

  notifyTimelineUsersReloadRequest() {
    return this.mqService.pub('timeline-users-reload-request');
  }

  onTimelineUsersReloadRequest() {
    return this.mqService.sub('timeline-users-reload-request');
  }

  notifyConnectionRequestAccepted(userId: number) {
    return this.mqService.pub('timeline-users-con-req-accepted', userId);
  }

  onConnectionRequestAccepted(): Observable<number> {
    return this.mqService.sub('timeline-users-con-req-accepted');
  }

  notifyChatDeleted() {
    return this.mqService.pub('timeline-chat-deleted');
  }

  onChatDeleted() {
    return this.mqService.sub('timeline-chat-deleted');
  }

  notifyUserBlocked(userId: number) {
    return this.mqService.pub('timeline-user-blocked', userId);
  }

  onUserBlocked() {
    return this.mqService.sub('timeline-user-blocked');
  }

  notifyUserUnblocked(userId: number) {
    return this.mqService.pub('timeline-user-unblocked', userId);
  }

  onUserUnblocked() {
    return this.mqService.sub('timeline-user-unblocked');
  }
}
