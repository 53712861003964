import {Pipe, PipeTransform} from '@angular/core';
import linkifyStr from 'linkify-string';
import linkifyHtml from 'linkify-html';
import {generateHTML, generateText, JSONContent} from '@tiptap/core';
import {TextAst} from '@gmb/bitmark-parser-generator';
import * as bpgLib from '@gmb/bitmark-parser-generator/dist/browser/bitmark-parser-generator.min.js';
import {BitmarkFormat} from '../shared/models/bitmark.models';
import {ReaderTipTapTapService, TipTapEditorType} from '../reader/tiptap/reader-tiptap.service';
import {highlightCodeblocks} from '../reader/tiptap/nodes/codeblock/paragraph-code.node';
import {UrlRewriteService} from '../shared';

const {bitmarkTextParse} = bpgLib;

@Pipe({name: 'bitmark', pure: true})
export class BitmarkPipe implements PipeTransform {
  constructor(private readerTipTapService: ReaderTipTapTapService,
              private urlRewriteService: UrlRewriteService) {
  }

  private parseMM(bitmark: string) {
    let html = this.escapeHtml(bitmark);

    const regex_ = /(\\\*)|(\\_)|(\\\\)|(?:\*\*)(\S[\s\S]*?\**)(?:\*\*)|(?:__)(\S[\s\S]*?_*)(?:__)/gm;
    html = html.replace(regex_, (_fullMatch: string, _esc_asterisk, _esc_underscore, _esc_backslash, _important_match, _em_match) => {
      let h_ = '';
      if (_important_match && _important_match !== '') {
        h_ = '<strong>' + _important_match + '</strong>';
      } else if (_em_match && _em_match !== '') {
        h_ = '<em>' + _em_match + '</em>';
      } else if (_esc_asterisk && _esc_asterisk !== '') {
        h_ = '*';
      } else if (_esc_underscore && _esc_underscore !== '') {
        h_ = '_';
      } else if (_esc_backslash && _esc_backslash !== '') {
        h_ = '\\';
      }
      return h_;
    });

    const imgNewRegex = /\|image:(https:\/\/(?:(?:(?!\|).)+?)) *\|(?:(.*)\|)?/gm;
    html = html.replace(imgNewRegex, (fullMatch, imgUrl, attrs) => {
      const attrsArray = attrs ? attrs.split('|') : [];

      const widthAttr: string = attrsArray.find((a: string) => a.toLowerCase().startsWith('@width:'));
      const width = widthAttr ? `${widthAttr.replace('@width:', '')}px` : 'auto';

      const captionAttr: string = attrsArray.find((x: string) => x.toLowerCase().startsWith('@caption:'));
      const caption = captionAttr ? captionAttr.replace('@caption:', '') : '';

      const copyrightAttr: string = attrsArray.find((x: string) => x.toLowerCase().startsWith('@copyright:'));
      const copyright = copyrightAttr ? copyrightAttr.replace('@copyright:', '') : '';

      return `<div style="
position: relative;
display: flex;
flex-direction: column;
width: 100%;
overflow: hidden;
background-color: var(--bitmark-bitmark-image-background-color, transparent);
padding: var(--bitmark-bitmark-image-size-padding);
margin: var(--bitmark-bitmark-image-size-margin);
align-items: var(--bitmark-bitmark-image-flex-align);">
<figure>
<img src="${imgUrl}" style="width: ${width}; max-width: ${740 / 2}">
<figcaption style="width: 100%; ${this.renderBitmarkBrandingFont('bitmark-image-caption')}">${caption}</figcaption>
<figcaption style="width: 100%; ${this.renderBitmarkBrandingFont('bitmark-image-caption')}">${copyright}</figcaption>
</figure>
</div>`;
    });

    // handles bitmark++ link formats: ==Caption==|https://the-link.con|
    const linksRegex = /== *((?:(?!==).)+?) *==\|((?:https:\/\/|mailto:)(?:.+?))\|/gmi;
    html = html.replace(linksRegex, (fullMatch, caption, url) => {
      const decoyUrl = url
        .replace(/http/gmi, '_ht_p_')
        .replace(/www./gmi, '_w_w\._')
        .trim();
      return `<a href="${decoyUrl}" target="_blank">${caption}</a>`;
    });

    // const webLinkRegex = /(?:(?:https?):\/\/|www\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gmi;
    // html = html.replace(webLinkRegex, (url, protocol) => {
    //   const isImage = ['.webp', '.jpg', ',jpeg', '.gif', '.png'].findIndex(x => url.includes(x)) !== -1;
    //   if (isImage) {
    //     return url;
    //   }
    //   // const isSearchResult = url.includes('??');
    //   // if (isSearchResult) {
    //   //   url = url.replace(/\?\?/gmi, '');
    //   // }
    //
    //   if (!url.startsWith('http')) {
    //     url = `http://${url}`;
    //   }
    //   const decoyUrl = url
    //     .replace(/http/gmi, '_ht_p_')
    //     .replace(/www\./gmi, '_w_w._')
    //     .trim();
    //   // return isSearchResult ?
    //   //  `??<a href="${decoyUrl}" target="_blank">${url}</a>??`
    //   //  : `<a href="${decoyUrl}" target="_blank">${url}</a>`;
    //   return `<a href="${decoyUrl}" target="_blank">${url}</a>`;
    // });

    html = linkifyHtml(html, {
      target: '_blank',
      ignoreTags: ['a', 'img']
    });

    const varsRegex = /(== *((?:(?!==).)+?) *==)\|((@var:)(.+?))\|/g;
    html = html.replace(varsRegex, (fullMatch, g1, varValue) => {
      return varValue;
    });

    return html
      .replace(/_ht_p_/gmi, 'http')
      .replace(/_w_w\._/gmi, 'www.')
      .trim();
  }

  private removeBitmarkTags(bitmark: string): string {
    const firstTagRegex = /^\[%(.*?)]/gm;
    const secondTagRegex = /^\[!([\s\S]*?)]/gm;
    return bitmark.replace(firstTagRegex, '').replace(secondTagRegex, '');
  }

  private escapeHtml(unsafe: string | any): string {
    if (!unsafe) {
      return '';
    }

    if (!(typeof unsafe === 'string' || unsafe instanceof String)) {
      console.warn('Not a string to escape!', unsafe);
      return '';
    }

    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      // .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  transform(value: any, format: BitmarkFormat = BitmarkFormat.Text, outputFormat: 'html' | 'json' | 'text', editorType: TipTapEditorType = null, language = '', staticText = false): string | JSONContent {
    if (outputFormat === 'html') {
      return this.transformToHtml(value, format, editorType, language, staticText);
    } else if (outputFormat === 'text') {
      return this.transformToText(value, format, editorType);
    } else {
      return this.transformToJson(value, format, editorType);
    }
  }

  private transformToHtml(value: any, format: BitmarkFormat = BitmarkFormat.Text, editorType: TipTapEditorType = null, language = '', staticText = false): string {
    if (!value) {
      return value;
    }
    if (format === BitmarkFormat.JSON) {
      return '';
    }

    let ret = value; // this.removeBitmarkTags(value);
    // if ([BitmarkFormat.MM, BitmarkFormat.PP, BitmarkFormat.Prosemirror].includes(format)) {
    if ([BitmarkFormat.MM, BitmarkFormat.PP].includes(format)) {
      try {
        ret = this.parsePeggy(ret, format, editorType, language, staticText);
      } catch (ex) {
        console.error(ex);
        ret = this.parseMM(ret);
      }
    } else if (editorType === TipTapEditorType.Code) {
      ret = typeof ret === 'string' && !ret.includes('<code>') ? `<pre language="${language}" codelanguage="${language}"><code class="language-${language}">${ret}</code></pre>` : ret;
      return highlightCodeblocks(ret);
    } else if (format === BitmarkFormat.Text) {
      if (typeof ret === 'string') {
        ret = linkifyStr(ret, {target: '_blank'});
      } else {
        ret = 'Mismatch between bitmark format and content';
        console.error('Mismatch between bitmark format and content', format, ret);
      }
    }

    const highlightRegex = /\?\?(.*?)\?\?/gmi;
    ret = ret.replace(highlightRegex, (fullMatch: string, inner: string) => {
      return `<span style='background-color: #f8e81a'>${inner.trim()}</span>`;
    });

    // const newLineRegex = /^===/gm;
    // ret = ret.replace(newLineRegex, '');

    return ret;
  }

  private transformToJson(value: any, format: BitmarkFormat, editorType: TipTapEditorType): JSONContent {
    if (typeof value === 'object') {
      return value;
    }

    if (!value) {
      return {type: 'doc', content: []};
    }

    if (format === BitmarkFormat.Text) {
      return this.handleJsonContentValue(JSON.parse(value));
    }

    // if (format === BitmarkFormat.Prosemirror) {
    //   try {
    //     if (typeof value === 'string') {
    //       return this.handleJsonContentValue(JSON.parse(value));
    //     } else {
    //       return this.handleJsonContentValue(value);
    //     }
    //   } catch (ex) {
    //     console.error(ex);
    //     return null;
    //   }
    // }

    try {
      if (editorType === TipTapEditorType.Code) {
        return {
          type: 'doc',
          content: bitmarkTextParse(value, {startRule: BitmarkFormat.MM.replace(/-/g, 'Minus')}) as TextAst
        };
      }
      return {
        type: 'doc',
        content: bitmarkTextParse(value, {startRule: format.replace(/\+/g, 'Plus').replace(/-/g, 'Plus')}) as TextAst
      };
    } catch (ex) {
      console.error(ex);
      return null;
    }
  }

  private transformToText(value: any, format: BitmarkFormat, editorType: TipTapEditorType): string {
    if (!value) {
      return '';
    }

    if (typeof value === 'string') {
      return value;
    }

    editorType = editorType || this.getEditorType(format);

    const valueJson = this.handleJsonContentValue(value);
    return generateText(valueJson, this.readerTipTapService.getExtensions(editorType, null, null, false, null, null, true));
  }

  public handleJsonContentValue(value: any): JSONContent {
    if (value?.type === 'doc') {
      return value;
    } else {
      return {
        type: 'doc',
        content: value
      };
    }
  }

  private parsePeggy(value: any, format: BitmarkFormat, editorType: TipTapEditorType = null, language = '', staticText = false): string {
    let parsedJson: any;
    if (Array.isArray(value) && value?.length && value[0]?.type === 'doc') {
      if (value[0].content?.length) {
        value = value[0].content;
      } else {
        return '';
      }
    }
    if (typeof value === 'object') {
      parsedJson = this.handleJsonContentValue(value);
    } else if (editorType === TipTapEditorType.Code) {
      parsedJson = {
        type: 'doc',
        content: bitmarkTextParse(value, {startRule: BitmarkFormat.MM.replace(/-/g, 'Minus')}) as TextAst
      };
    } else {
      //TODO: find what is the best course of action dupa pompierit
      value = this.urlRewriteService.rewriteUrl(value);
      parsedJson = {
        type: 'doc',
        content: bitmarkTextParse(value, {startRule: format.replace(/\+/g, 'Plus').replace(/-/g, 'Plus')}) as TextAst
      };
    }

    editorType = editorType || this.getEditorType(format);

    try {
      const generatedHtml = editorType === TipTapEditorType.Code
        ? generateHTML(parsedJson, this.readerTipTapService.getExtensions(editorType, null, null, false, null, language, staticText))
        : generateHTML(parsedJson, this.readerTipTapService.getExtensions(editorType, null, null, false, null, null, staticText));
      if (editorType === TipTapEditorType.Code || editorType === TipTapEditorType.BitmarkPlus) {
        return highlightCodeblocks(generatedHtml);
      }
      return generatedHtml;
    } catch (ex) {
      console.log(parsedJson);
      throw ex;
    }
  }

  private getEditorType(format: BitmarkFormat): TipTapEditorType {
    return format === BitmarkFormat.PP
      ? TipTapEditorType.BitmarkPlus
      : format === BitmarkFormat.MM
        ? TipTapEditorType.BitmarkPlus
        : null;
    // : format === BitmarkFormat.Prosemirror
    //   ? TipTapEditorType.BitmarkPlus
    //   : null;
  }

  private renderBitmarkBrandingFont(x: string) {
    return `
font-family: var(--bitmark-${x}-font-family, var(--bitmark-main-font-family));
font-size: var(--bitmark-${x}-font-size, var(--bitmark-main-font-size));
line-height: var(--bitmark-${x}-font-line-height, var(--bitmark-main-font-height));
font-weight: var(--bitmark-${x}-font-weight, var(--bitmark-main-font-weight));
font-style: var(--bitmark-${x}-font-style, var(--bitmark-main-font-style));
color: var(--bitmark-${x}-font-color, var(--bitmark-main-font-height));
text-align: var(--bitmark-${x}-font-align, left);
justify-content: var(--bitmark-${x}-font-align, left);
`;
  }
}
