// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-black {
  background-color: #000000;
}

.small-font {
  font-size: 16px;
}

.avatar {
  width: 50px;
  height: 50px;
  font-size: 27px;
  font-weight: 300;
  color: #000000;
  border-radius: 50% !important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hand-in-container .view-resources-toggle {
  position: absolute;
  right: 20px;
}
.hand-in-container .add-to-library {
  padding: 5px 0 0 0;
  border-radius: 100%;
  min-width: inherit;
  width: 40px;
  height: 40px;
  border: none;
}
.hand-in-container .buy-button-container {
  display: flex;
  justify-content: var(--bitmark-virtual-hand-in-flex-align, flex-start);
}
.hand-in-container .btn-background-calm {
  border-radius: 8px;
  padding: 5px 15px;
  margin-top: 15px;
}
.hand-in-container .btn-background-calm.buy-button {
  background: var(--bitmark-virtual-hand-in-button-background-shorthand, var(--bitmark-virtual-hand-in-button-background, #543199));
  border-top: var(--bitmark-virtual-hand-in-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-virtual-hand-in-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-hand-in-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-virtual-hand-in-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-virtual-hand-in-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-hand-in-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-virtual-hand-in-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-virtual-hand-in-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-hand-in-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-virtual-hand-in-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-virtual-hand-in-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-virtual-hand-in-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-virtual-hand-in-button-border-radius, var(--bitmark-x-border-radius, 30px));
  width: var(--bitmark-virtual-hand-in-button-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-virtual-hand-in-button-size-height, var(--bitmark-x-size-height, inherit));
  font-family: var(--bitmark-virtual-hand-in-button-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-virtual-hand-in-button-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-virtual-hand-in-button-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-virtual-hand-in-button-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-virtual-hand-in-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-virtual-hand-in-button-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-virtual-hand-in-button-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-virtual-hand-in-button-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-virtual-hand-in-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-virtual-hand-in-button-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  padding: var(--bitmark-virtual-hand-in-button-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-virtual-hand-in-button-size-margin, var(--bitmark-x-size-margin, 0));
  border-radius: var(--bitmark-virtual-hand-in-border-radius, 8px);
  margin: var(--bitmark-virtual-hand-in-button-size-margin, 15px 0 5px 0);
  padding: var(--bitmark-virtual-hand-in-button-size-padding, 5px 15px);
  color: var(--bitmark-virtual-hand-in-button-color, #ffffff);
}
.hand-in-container .person-avatar {
  border-radius: var(--bitmark-virtual-hand-in-person-avatar-border-radius, 50%);
  width: var(--bitmark-virtual-hand-in-person-avatar-width, var(--bitmark-virtual-hand-in-person-avatar-width, 50px));
  height: var(--bitmark-virtual-hand-in-person-avatar-height, var(--bitmark-virtual-hand-in-person-avatar-height, 50px));
  margin: var(--bitmark-virtual-hand-in-person-avatar-margin, var(--bitmark-virtual-hand-in-person-avatar-margin, 0));
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hand-in-container .person-name {
  font-family: var(--bitmark-virtual-hand-in-person-name-family, inherit);
  font-size: var(--bitmark-virtual-hand-in-person-name-size, inherit);
  font-weight: var(--bitmark-virtual-hand-in-person-name-weight, inherit);
  font-style: var(--bitmark-virtual-hand-in-person-name-style, inherit);
  -webkit-text-decoration: var(--bitmark-virtual-hand-in-person-name-text-decoration, inherit);
          text-decoration: var(--bitmark-virtual-hand-in-person-name-text-decoration, inherit);
  line-height: var(--bitmark-virtual-hand-in-person-name-line-height, inherit);
  color: var(--bitmark-virtual-hand-in-person-name-color, inherit);
  text-align: var(--bitmark-virtual-hand-in-person-name-align, inherit);
  justify-content: var(--bitmark-virtual-hand-in-person-name-align, inherit);
}
.hand-in-container .person-container {
  font-family: var(--bitmark-virtual-hand-in-person-container-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-virtual-hand-in-person-container-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-virtual-hand-in-person-container-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-virtual-hand-in-person-container-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-virtual-hand-in-person-container-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-virtual-hand-in-person-container-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-virtual-hand-in-person-container-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-virtual-hand-in-person-container-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-virtual-hand-in-person-container-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-virtual-hand-in-person-container-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  width: var(--bitmark-virtual-hand-in-person-container-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-virtual-hand-in-person-container-size-height, var(--bitmark-x-size-height, inherit));
  margin: var(--bitmark-virtual-hand-in-person-container-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-virtual-hand-in-person-container-size-padding, var(--bitmark-x-size-padding, 0));
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
