import {Component, Input, OnInit} from '@angular/core';
import {BitFeedback, BitType} from '../bits.models';
import {MessageBit} from '../message/message.models';
import {BitmarkFormat} from '../../shared/models/bitmark.models';

@Component({
  selector: 'bitmark-bit-feedback',
  templateUrl: './bit-feedback.component.html',
  styleUrls: ['./bit-feedback.component.scss']
})
export class BitFeedbackComponent implements OnInit {
  @Input() bit: Array<BitFeedback>;

  messages: Array<MessageBit>;

  ngOnInit() {
    if (!this.bit) {
      return;
    }
    this.messages = [];
    this.bit.forEach((fi: BitFeedback, idx: number) => {
      setTimeout(() => {
        if (fi?.context) {
          this.messages.push({
            type: BitType.Message,
            format: BitmarkFormat.PP,
            body: '' + (fi.correctness === 'WRONG' ? '😞 ' : '😀 ') + fi?.context[0]?.content + ''
          } as MessageBit);
        }
        this.messages.push({
          type: BitType.Message,
          format: BitmarkFormat.MM,
          body: fi.message
        } as MessageBit);
      }, idx * 500);
    });
  }
}
