import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, TemplateRef,
  ViewChild,
} from '@angular/core';
import { TimelineMqService } from 'main/timeline/timeline-mq.service';
import { DropdownItemModel } from 'shared';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { ShopListUser } from 'main/shop/shop.models';
import { ShopInfo } from 'core';

@Component({
  selector: 'app-course-user-list-item',
  templateUrl: './course-user-list-item.component.html',
  styleUrls: ['../course-user-list.component.scss'],
})
export class CourseUserListItemComponent implements OnInit, OnChanges {
  @Input() user: ShopListUser;
  @Input() isOrganizer: boolean;
  @Input() filterText: string;
  @Input() isParticipantsList: boolean;
  @Input() usersWhoBoughtCourse: Array<number>;
  @Input() shopBranding: ShopInfo;
  @Input() course: any;
  @Output() onDeleteEmail = new EventEmitter<string>();
  @Output() onEditEmail = new EventEmitter<{ oldEmail: string, newEmail: string }>();
  @Output() onEditUser = new EventEmitter<ShopListUser>();
  @Output() onResendInvitation = new EventEmitter<ShopListUser>();
  @Output() onBuyForUser = new EventEmitter<ShopListUser>();
  @Output() onSetUserFlagEmojis = new EventEmitter<ShopListUser>();
  @ViewChild('editingLabel') editingLabelEl: ElementRef;
  @ViewChild('emojiMenu') emojiMenuEl: TemplateRef<any>;
  userActions: Array<DropdownItemModel>;
  isEditing: boolean;
  originalUser: ShopListUser;
  highlightedEmail: string;
  highlightedFullName: string;

  constructor(private timelineMqService: TimelineMqService,
              private translate: TranslateService,
              private clipboardService: ClipboardService) {
  }

  ngOnInit() {
    this.originalUser = JSON.parse(JSON.stringify(this.user));
    this.highlightedEmail = this.user?.email;
    this.highlightedFullName = this.user?.fullName;
    if (this.user?.status) {
    }
  }

  ngAfterViewInit() {
    this.userActions = this.isOrganizer ? [{
      label: this.translate.instant('Shop.Editor.ResendInvitation'),
      handler: this.resendInvitation.bind(this),
      isHidden: !this.isParticipantsList || this.user?.isCourseSessionJoined
    }, {
      label: this.translate.instant('Shop.Editor.BuyForUser'),
      handler: this.buyForUser.bind(this),
      isHidden: !this.user?.id || !this?.course?.simulatedPrice || !this.isParticipantsList || this.user?.isCourseSessionJoined
    }, {
      label: this.translate.instant('Shared.Edit'),
      handler: this.editEmail.bind(this),
    }, {
      label: this.translate.instant('Shop.Editor.AddFlag'),
      additionalContent: this.emojiMenuEl,
    }, {
      isSeparator: true,
    }, {
      label: this.translate.instant('Shop.Editor.Chat'),
      handler: this.connectWithUser.bind(this),
    }, {
      label: this.translate.instant('Shop.Editor.SendEmail'),
      handler: this.sendEmail.bind(this),
    }, {
      label: this.translate.instant('Shop.Editor.CopyEmailAddress'),
      handler: this.copyEmail.bind(this),
    }, {
      isSeparator: true,
    }, {
      label: this.translate.instant('Shared.Delete'),
      handler: this.deleteEmail.bind(this),
    }] : [{
      label: this.translate.instant('Shop.Editor.Chat'),
      handler: this.connectWithUser.bind(this),
    }];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.originalUser) {
      return;
    }
    this.getHighlightedEmail();
  }

  copyEmail() {
    console.log(this.emojiMenuEl);
    this.clipboardService.copy(this.user.email);
  }

  sendEmail() {
    window.open(`mailto:${this.user?.email}`, '_blank');
  }

  resendInvitation(){
    this.onResendInvitation.emit(this.user);
  }

  deleteEmail() {
    this.onDeleteEmail.emit(this.user.email);
  }

  editEmail() {
    this.isEditing = true;
  }

  cancelEditing() {
    this.isEditing = false;
  }

  getHighlightedEmail() {
    if (this.filterText?.length && this.user?.email?.toLowerCase().indexOf(this.filterText?.toLowerCase()) !== -1) {
      const usr = this.user?.email?.toLowerCase();
      const idx = usr.indexOf(this.filterText?.toLowerCase());
      this.highlightedEmail = `${usr.slice(0, idx)}<span class='user-highlight'>${usr.slice(idx, idx + this.filterText?.length)}</span>${usr.slice(idx + this.filterText?.length)}`;
    } else{
      this.highlightedEmail = this.user?.email?.toLowerCase();
    }
    if (this.filterText?.length && this.user?.fullName?.toLowerCase().indexOf(this.filterText?.toLowerCase()) !== -1) {
      const usr = this.user?.fullName?.toLowerCase();
      const idx = usr?.indexOf(this.filterText.toLowerCase());
      this.highlightedFullName = `${this.user?.fullName?.slice(0, idx)}<span class='user-highlight'>${this.user?.fullName?.slice(idx, idx + this.filterText.length)}</span>${this.user?.fullName?.slice(idx + this.filterText.length)}`;
    } else{
      this.highlightedFullName = this.user?.fullName;
    }
  }

  saveEmail() {
    this.isEditing = !this.isEditing;
    // this.user = {email: this.editingLabelEl.nativeElement.innerText};
    this.onEditEmail.emit({oldEmail: this.user.email, newEmail: this.editingLabelEl.nativeElement.innerText});
    this.getHighlightedEmail();
  }

  toggleEmoji(emoji: string) {
    this.user.emoji = this.user.emoji || [];
    if (this.user?.emoji?.indexOf(emoji) !== -1) {
      this.user.emoji = this.user.emoji.filter((e) => e !== emoji);
    } else {
      this.user.emoji.push(emoji);
    }
    this.onEditUser.emit(this.user);
  }

  connectWithUser() {
    if (this.user.id) {
      this.timelineMqService.notifyConnectWithUsers(this?.user?.email);
    }
  }

  buyForUser(){
    this.onBuyForUser.emit(this.user);
  }
}
