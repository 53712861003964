import {Component, EventEmitter, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BitResource, BitResourceType} from '../../../bits.models';
import {ApiService} from '../../../../shared/api/api.service';
import UrlService from '../../../../shared/utils/url.service';

@Component({
  selector: 'bitmark-resource-website-link-edit',
  templateUrl: './resource-website-link-edit.component.html',
  styleUrls: ['./resource-website-link-edit.component.scss', '../../../bits.scss']
})
export class ResourceWebsiteLinkEditComponent {
  @Output() onSave: EventEmitter<BitResource> = new EventEmitter<BitResource>();

  websiteLink: string;
  websiteLinkResource: BitResource | undefined;
  websiteLinkPlaceholderResource: BitResource | undefined;
  isLoading = false;

  constructor(private ngbActiveModal: NgbActiveModal,
              private apiService: ApiService,
              private urlService: UrlService) {
    this.websiteLinkPlaceholderResource = {
      type: BitResourceType.WebsiteLink,
      preview: {
        title: 'Example Domain',
        description: 'This domain is for use in illustrative examples in documents. You may use this domain in literature without prior coordination or asking for permission.',
        image: {
          src1x: '../../../../../assets/images/resources/website-placeholder.png'
        }
      },
      websiteLink: {
        url: 'https://www.example.com',
        siteName: 'Example Domain'
      }
    };
  }

  onLinkChanged() {
    if (this.websiteLink) {
      this.websiteLinkResource = undefined;

      if (!this.urlService.isValidInternalOrExternal(this.websiteLink)) {
        return;
      }

      this.isLoading = true;

      this.apiService.post('learning-paths/link-info', null, {link: this.websiteLink}).subscribe(result => {
        this.isLoading = false;
        this.websiteLinkResource = {
          type: BitResourceType.WebsiteLink,
          preview: {
            title: result?.title || '',
            description: result?.description || '',
            image: {
              src1x: result?.imageUrl
            }
          },
          websiteLink: {
            url: this.websiteLink,
            siteName: result?.title || ''
          }
        };
      });
    } else {
      this.websiteLinkResource = undefined;
    }
  }

  saveChanges() {
    if (!this.websiteLinkResource?.websiteLink?.url || !this.urlService.isValidInternalOrExternal(this.websiteLinkResource?.websiteLink?.url)) {
      alert('Invalid URL');
      return;
    }

    this.onSave.emit(this.websiteLinkResource);
    this.ngbActiveModal.close();
  }

  cancelChanges() {
    this.ngbActiveModal.close();
  }
}
