import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from '../api/api.service';
import {tap} from "rxjs/operators";

export type Space = 'personal' | 'auth0' | 'auth0local' | 'iffp' | 'swisslife' | 'ef' | 'goldstuck' | 'additiv' | 'electrosuisse';

export interface SsoLogin {
  type: 'email' | 'openid' | 'saml2'
  loginUrl: string
  logoutUrl: string
}

@Injectable({providedIn: 'root'})
export class SsoService {
  constructor(private apiService: ApiService) {
  }

  getProviderByEmail(email: string): Observable<{ provider: Space }> {
    return this.apiService.get('auth/sso/provider/{email}', {email});
  }

  readEndPoints(provider: Space): Observable<SsoLogin> {
    return provider === 'personal'
      ? of({type: 'email'})
      : this.apiService.get('auth/sso/endpoints/{provider}', {provider});
  }

  login(provider: Space): Observable<SsoLogin> {
    return provider === 'personal'
      ? of({})
      : this.apiService.get('auth/sso/login/{provider}', {provider});
  }

  logout(provider: Space): Observable<any> {
    if (provider === 'personal') {
      return of(null);
    }
    return this.readEndPoints(provider)
      .pipe(tap((sso: SsoLogin) => {
        if (sso?.logoutUrl) {
          window.location.href = sso.logoutUrl;
        }
      }));
  }
}
