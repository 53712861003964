// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-audio-wrapper {
  background-color: var(--bitmark-resource-audio-recording-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-resource-audio-recording-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-resource-audio-recording-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-resource-audio-recording-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-resource-audio-recording-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-resource-audio-recording-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-resource-audio-recording-background-animation-name) var(--bitmark-resource-audio-recording-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-resource-audio-recording-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-resource-audio-recording-background-backdrop-filter);
  width: var(--bitmark-resource-audio-recording-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-resource-audio-recording-size-height, var(--bitmark-x-size-height, inherit));
  margin: var(--bitmark-resource-audio-recording-size-margin, var(--bitmark-x-size-margin, 0));
  padding: var(--bitmark-resource-audio-recording-size-padding, var(--bitmark-x-size-padding, 0));
  border-top: var(--bitmark-resource-audio-recording-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-resource-audio-recording-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-audio-recording-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-resource-audio-recording-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-resource-audio-recording-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-audio-recording-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-resource-audio-recording-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-resource-audio-recording-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-audio-recording-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-resource-audio-recording-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-resource-audio-recording-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-audio-recording-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-resource-audio-recording-border-radius, var(--bitmark-x-border-radius, y));
}
.resource-audio-wrapper .resource-audio-action-buttons-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.resource-audio-wrapper .resource-audio-action-buttons-wrapper .resource-audio-action-btn {
  width: var(--bitmark-resource-audio-recording-icon-button-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-resource-audio-recording-icon-button-size-height, var(--bitmark-x-size-height, inherit));
  min-width: var(--bitmark-resource-audio-recording-icon-button-size-width, var(--bitmark-x-size-width, inherit));
  min-height: var(--bitmark-resource-audio-recording-icon-button-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-resource-audio-recording-icon-button-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-audio-recording-icon-button-size-margin, var(--bitmark-x-size-margin, 0));
  border-top: var(--bitmark-resource-audio-recording-icon-button-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-resource-audio-recording-icon-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-audio-recording-icon-button-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-resource-audio-recording-icon-button-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-resource-audio-recording-icon-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-audio-recording-icon-button-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-resource-audio-recording-icon-button-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-resource-audio-recording-icon-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-audio-recording-icon-button-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-resource-audio-recording-icon-button-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-resource-audio-recording-icon-button-border-style, var(--bitmark-x-border-style)) var(--bitmark-resource-audio-recording-icon-button-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-resource-audio-recording-icon-button-border-radius, var(--bitmark-x-border-radius, y));
  background: var(--bitmark-resource-audio-recording-icon-button-background-shorthand, var(--bitmark-resource-audio-recording-icon-button-background));
  color: var(--bitmark-resource-audio-recording-icon-button-color);
}
.resource-audio-wrapper .resource-audio-action-buttons-wrapper .resource-audio-action-btn-icon {
  width: var(--bitmark-resource-audio-recording-icon-button-icon-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-resource-audio-recording-icon-button-icon-size-height, var(--bitmark-x-size-height, inherit));
  padding: var(--bitmark-resource-audio-recording-icon-button-icon-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-resource-audio-recording-icon-button-icon-size-margin, var(--bitmark-x-size-margin, 0));
}
.resource-audio-wrapper .resource-audio-visualizer {
  height: 100px;
}
.resource-audio-wrapper .resource-audio-time {
  margin-bottom: 20px;
  font-family: var(--bitmark-resource-audio-recording-time-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-resource-audio-recording-time-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-resource-audio-recording-time-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-resource-audio-recording-time-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-resource-audio-recording-time-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-resource-audio-recording-time-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-resource-audio-recording-time-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-resource-audio-recording-time-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-resource-audio-recording-time-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-resource-audio-recording-time-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.resource-audio-wrapper .empty-resource-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  min-width: 30px;
  width: 30px;
  min-height: 30px;
  height: 30px;
  padding: 0;
  border-radius: 5px;
  color: #fff;
}
.resource-audio-wrapper .empty-resource-btn .trash-icon {
  width: 18px;
  height: 18px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
