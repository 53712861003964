// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversation-text-wrapper {
  border-top: var(--bitmark-conversation-right-1-bubble-border-top-width, var(--bitmark-x-border-top-width)) var(--bitmark-conversation-right-1-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-right-1-bubble-border-color, var(--bitmark-x-border-color));
  border-right: var(--bitmark-conversation-right-1-bubble-border-right-width, var(--bitmark-x-border-right-width)) var(--bitmark-conversation-right-1-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-right-1-bubble-border-color, var(--bitmark-x-border-color));
  border-bottom: var(--bitmark-conversation-right-1-bubble-border-bottom-width, var(--bitmark-x-border-bottom-width)) var(--bitmark-conversation-right-1-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-right-1-bubble-border-color, var(--bitmark-x-border-color));
  border-left: var(--bitmark-conversation-right-1-bubble-border-left-width, var(--bitmark-x-border-left-width)) var(--bitmark-conversation-right-1-bubble-border-style, var(--bitmark-x-border-style)) var(--bitmark-conversation-right-1-bubble-border-color, var(--bitmark-x-border-color));
  border-radius: var(--bitmark-conversation-right-1-bubble-border-radius, var(--bitmark-x-border-radius, y));
  padding: var(--bitmark-conversation-right-1-bubble-content-size-padding, var(--bitmark-x-size-padding, 0));
  margin: var(--bitmark-conversation-right-1-bubble-content-size-margin, var(--bitmark-x-size-margin, 0));
  background-color: var(--bitmark-conversation-right-1-bubble-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-conversation-right-1-bubble-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-conversation-right-1-bubble-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-conversation-right-1-bubble-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-conversation-right-1-bubble-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-conversation-right-1-bubble-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-conversation-right-1-bubble-background-animation-name) var(--bitmark-conversation-right-1-bubble-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-conversation-right-1-bubble-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-conversation-right-1-bubble-background-backdrop-filter);
}
.conversation-text-wrapper.with-partner {
  margin-right: -36px;
}
.conversation-text-wrapper.with-icon {
  margin-bottom: 50px;
}
.conversation-text-wrapper.without-icon {
  margin-bottom: 39px;
}

.user-avatar {
  font-family: var(--bitmark-conversation-right-1-partner-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-conversation-right-1-partner-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-conversation-right-1-partner-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-conversation-right-1-partner-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-conversation-right-1-partner-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-conversation-right-1-partner-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-conversation-right-1-partner-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-conversation-right-1-partner-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-conversation-right-1-partner-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-conversation-right-1-partner-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}

.rectangle-speech-normal:before {
  content: "";
  position: absolute;
  z-index: 10;
  bottom: -30px;
  right: 50px;
  width: 60px;
  height: 30px;
  border-radius: 0 0 0 100%;
  box-shadow: 5px -3px 0 0 var(--bitmark-conversation-right-1-bubble-border-color, #5a8f00) inset, 28px 0 0 0 var(--bitmark-conversation-right-1-bubble-background-color, var(--bitmark-reader-content-background-color)) inset, 35px 0px 0 0 var(--bitmark-conversation-right-1-bubble-border-color, #5a8f00) inset;
}
.rectangle-speech-normal > :first-child:before {
  content: "";
  position: absolute;
  bottom: -30px;
  right: calc(50px - var(--bitmark-conversation-right-1-bubble-border-bottom-width, 5px) / 2);
  width: var(--bitmark-conversation-right-1-bubble-border-bottom-width, 5px);
  height: var(--bitmark-conversation-right-1-bubble-border-bottom-width, 5px);
  background: var(--bitmark-conversation-right-1-bubble-border-color, #5a8f00);
  border-radius: 50%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
