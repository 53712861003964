import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TrueFalse1Bit} from './true-false-1.models';
import {BitResource} from '../bits.models';
import {BitEvalResult} from "../bit-eval.service";

@Component({
  selector: 'bitmark-true-false-1',
  templateUrl: './true-false-1.component.html',
  styleUrls: ['../bits.scss']
})
export class TrueFalse1Component implements OnInit, OnChanges {
  @Input() bit?: TrueFalse1Bit;
  @Input() bitId: string;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() focused = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<any>();

  answerTrueCss: string;
  answerFalseCss: string;

  ngOnInit() {
    if (this.bit.isExample) {
      this.hasFeedback = true;
      this.bit.answer = {choice: this.bit.example};
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      this.handleFeedback();
    }
  }

  change(value: boolean) {
    this.bit.answer = {choice: value};
    this.focused.emit(true);
    this.changed.emit();
  }

  private handleFeedback() {
    if (this.hasFeedback) {
      const isAnswerCorrect = this.bit.isCorrect === this.bit.answer?.choice;
      this.answerTrueCss = !this.bit.answer
        ? 'incorrect'
        : this.bit.answer?.choice
          ? (isAnswerCorrect ? 'correct' : 'incorrect')
          : '';
      this.answerFalseCss = !this.bit.answer
        ? 'incorrect'
        : !this.bit.answer?.choice
          ? (isAnswerCorrect ? 'correct' : 'incorrect')
          : '';
    } else {
      this.answerTrueCss = this.answerFalseCss = '';
    }
  }

  static evalAnswer(bit: TrueFalse1Bit): BitEvalResult {
    return {
      totalAnswers: 1,
      answeredAnswers: !!bit.answer ? 1 : 0,
      notAnsweredAnswers: !bit.answer ? 1 : 0,
      correctAnswers: bit.answer && bit.answer.choice === bit.isCorrect ? 1 : 0,
      incorrectAnswers: (bit.answer && bit.answer.choice !== bit.isCorrect) || !bit.answer ? 1 : 0
    };
  }
}
