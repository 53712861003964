import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GapPlaceholderBit} from '../cloze.models';
import BitUtilsService from '../../../shared/utils/bit-utils.service';

@Component({
  selector: 'bitmark-gap-placeholder',
  templateUrl: './gap-placeholder.component.html',
  styleUrls: ['./gap-placeholder.component.scss', '../../bits.scss']
})
export class GapPlaceholderComponent implements OnChanges, OnInit {
  @Input() gapPlaceholderBit?: GapPlaceholderBit;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Input() allowAnswerSubmit = true;
  @Input() showInstruction = false;
  @Output() focused = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<any>();

  answerCssClass = {};

  constructor(private bitUtilsService: BitUtilsService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      if (this.hasFeedback) {
        const isAnswerCorrect = this.gapPlaceholderBit.solutions
          .map(x => this.bitUtilsService.convertBitSolutionAnswer(x, this.gapPlaceholderBit.isCaseSensitive))
          .includes(this.bitUtilsService.convertBitSolutionAnswer(this.gapPlaceholderBit.answer?.text, this.gapPlaceholderBit.isCaseSensitive));
        this.answerCssClass = {
          correct: this.gapPlaceholderBit.isExample || isAnswerCorrect,
          incorrect: !isAnswerCorrect,
          disabled: !this.allowAnswerSubmit && !this.gapPlaceholderBit.isExample
        };
      } else {
        this.answerCssClass = {
          disabled: !this.allowAnswerSubmit && !this.gapPlaceholderBit.isExample
        };
      }
    }
  }

  ngOnInit() {
    if (this.gapPlaceholderBit.isExample && !this.gapPlaceholderBit.answer) {
      const correctAnswer = this.gapPlaceholderBit?.solutions[0] || '';
      const exampleAnswer = this.gapPlaceholderBit?.example || correctAnswer;

      this.setExampleAnswer({text: exampleAnswer, isCorrect: correctAnswer === exampleAnswer});
    }
  }

  setExampleAnswer(answer: { text: string, isCorrect: boolean }) {
    this.gapPlaceholderBit.answer = {text: answer.text};
    this.answerCssClass = {
      correct: answer.isCorrect,
      incorrect: !answer.isCorrect,
      disabled: !this.allowAnswerSubmit && !this.gapPlaceholderBit.isExample
    };
  }

  change(evt: Event) {
    this.gapPlaceholderBit.answer = {text: (evt.target as any)?.value};
    this.changed.emit();
  }
}
