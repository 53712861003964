import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@faubulous/ng2-ui-auth';
import {Observable} from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {IdentityService} from './identity.service';
import {appHost} from "app-host";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private identityService: IdentityService,
              private router: Router,
              private cookieService: CookieService) {
  }

  private getSpaceFromUrl(url: string): string {
    const spaceFromUrlMatches = url.match(/\/space\/.*\//)
      || url.match(/\/space\/.*$/);
    const afterSpace = spaceFromUrlMatches
      ? spaceFromUrlMatches[0]
        .replace(new RegExp('/space/', 'g'), '')
      : null;
    if (!afterSpace) {
      return null;
    }
    const x = afterSpace.indexOf('/');
    return x !== -1
      ? afterSpace.substr(0, x)
      : afterSpace;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    const subDomainCfg = appHost.getSubdomainConfig();
    const queryParams = (state as any)._root?.value?.queryParams;
    const forcedSpace = queryParams?.spc;
    const baseUrl = state.url.split('?')[0];

    if (isAuthenticated) {
      const space = this.identityService.getSpace();
      const spaceFromUrl = this.getSpaceFromUrl(state.url);
      if (spaceFromUrl && space !== spaceFromUrl) {
        this.router.navigate(['generic-error'], {
          skipLocationChange: true,
          queryParams: {
            code: 400,
            message: `Space assigned at login (${space}) is different from the space requested in the url (${spaceFromUrl})`
          }
        });
        return false;
      }
      const allowedPublicPage = ['/public', '/shop', '/logout', '/logout-sso', '/verify-email']
        .find(x => state.url.startsWith(x));
      if (!allowedPublicPage) {
        if (["/space", `/space/${space}`].includes(baseUrl)) {
          const redirectCookieUrl = this.getCookieRedirectUrl();
          if (redirectCookieUrl) {
            console.log(`Founded redirectUrl in cookies: ${redirectCookieUrl}`);
          }
          let redirectUrl = redirectCookieUrl || `space/${space}/my-library`;
          const fragmentIdx = redirectUrl.indexOf('#');
          let fragment = null;
          if (fragmentIdx != -1) {
            fragment = redirectUrl.substring(fragmentIdx + 1);
            redirectUrl = redirectUrl.substring(0, fragmentIdx);
          }
          this.router.navigate([redirectUrl], {
            replaceUrl: true,
            fragment
          });
          return false;
        }
        const knownPage = ['/login', '/register', '/timeline', '/workspace', '/contact', '/academy', '/profile', '/my-library']
          .find(x => state.url.startsWith(x));
        if (knownPage) {
          this.router.navigate(['space', space, knownPage.substr(1)], {replaceUrl: true});
          return false;
        }
        const allowedBasePage = ['/space', '/bo']
          .find(x => state.url.startsWith(x));
        if (!allowedBasePage) {
          this.router.navigate(['space', space, 'my-library'], {replaceUrl: true});
          return false;
        }
      }
    } else {
      let space = forcedSpace
        ? 'personal'
        : subDomainCfg?.space
        || (['localhost', 'cosmic', 'app'].includes(subDomainCfg.subDomain) ? state.url.split('/')[2] : '')
        || 'personal';
      if (['undefined', 'null'].includes(space)) {
        space = 'personal';
      }
      const isProperLoginUrl = state.url.startsWith(`/space/${space}/login`);
      const allowedBasePage = ['/public', '/shop', '/logout', '/logout-sso', '/generic-error', '/register', '/verify-email', '/lost-password', '/reset-password']
        .find(x => state.url.startsWith(x));
      if (!isProperLoginUrl && !allowedBasePage) {
        if (state.url !== '/space' && !state.url.includes('/login') && !state.url.startsWith('/space?')) {
          const expiryDate: Date = new Date();
          expiryDate.setHours(expiryDate.getMinutes() + 10);
          this.cookieService.set('redirectUrl', state.url, expiryDate);
        }
        this.router.navigate(['space', space, 'login'], {
          queryParams,
          replaceUrl: true
        });
        return false;
      }
    }
    return this.identityService.canAny(route.data?.claims);
  }

  private getCookieRedirectUrl() {
    const redirectCookieUrl = this.cookieService.get('redirectUrl');
    this.cookieService.delete('redirectUrl');

    const redirectCookieUrlDecoded = redirectCookieUrl
      ? decodeURIComponent(redirectCookieUrl)
      : null;

    const isInvalid = redirectCookieUrlDecoded?.includes('/bo/');

    return isInvalid
      ? null
      : redirectCookieUrlDecoded;
  }
}
