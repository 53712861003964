import {
  Component,
  EventEmitter, Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SubSink} from '../../../shared';
import {BitbookMqService} from '../../bitbook-mq.service';
import {BitApiWrapper, BitsViewPortVisibility} from '../../../bits/bits.models';
import {BookConfiguration, BookEntity, ReaderModes} from '../../reader.models';
import {ReaderLocalContentService} from '../../reader-local-content.service';
import {BookEntityToc, HandInStatus, ProductFamily, ReaderHiddenElements} from "../../../shared/models/bitmark.models";
import {BitmarkConfig} from "../../../bitmark.module";

@Component({
  selector: 'bitmark-reader-content',
  templateUrl: './reader-content.component.html',
  styleUrls: ['../../reader-common.scss']
})
export class ReaderContentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() bitBook: BookEntity;
  @Input() isExternalSearch: boolean;
  @Input() externalLocation: string;
  @Input() readerMode: ReaderModes = ReaderModes.Book;
  @Input() courseId = '';
  @Input() hiddenElements: Array<ReaderHiddenElements> = [];
  @Input() fontScale?: 80 | 100 | 110 | 120 | 140 = null;
  @Input() configuration?: BookConfiguration;

  @Output() sendBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() sendBitsToClass = new EventEmitter<Array<BitApiWrapper>>();
  @Output() saveBits = new EventEmitter<Array<BitApiWrapper>>();
  @Output() copyLinkToBit = new EventEmitter<BitApiWrapper>();
  @Output() findInBook = new EventEmitter<BitApiWrapper>();
  @Output() toggleToc = new EventEmitter<any>();
  @Output() close = new EventEmitter();
  @Output() isWorking = new EventEmitter<boolean>();
  @Output() assignHandIn = new EventEmitter<{ handInId: number, isSelfAssign?: boolean }>();
  @Output() navigateToBook = new EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }>();
  @Output() navigateToProduct = new EventEmitter<{ productId: string, family?: ProductFamily }>();

  ReaderModes = ReaderModes;

  bookTitle: string;
  chapterPath: string;
  chapterPathObj: Array<BookEntityToc>;
  isLoading = true;
  isShopReader = false;
  isSelfLearningReader = false;
  isReadOnly = false;

  private sub = new SubSink();

  constructor(@Inject('BitmarkConfig') private bitmarkConfig: BitmarkConfig,
              private ngZone: NgZone,
              private ngbModal: NgbModal,
              private readerLocalContentService: ReaderLocalContentService,
              private bitbookMqService: BitbookMqService) {
  }

  ngOnInit() {
    this.isShopReader = [
      ReaderModes.Shop,
      ReaderModes.ShopReadonly,
      ReaderModes.ShopSection,
      ReaderModes.ShopSectionReadonly,
      ReaderModes.ShopPublic
    ].includes(this.readerMode);
    this.isSelfLearningReader = [
      ReaderModes.SelfLearningReadonly
    ].includes(this.readerMode);
    this.sub.sink = this.bitbookMqService.onReaderChapterDeleted()
      .subscribe(this.removeChapterFromPath.bind(this));
    this.sub.sink = this.bitbookMqService.onBitsScrollVisibility()
      .subscribe(this.updateChapterPath1.bind(this));
    this.sub.sink = this.bitbookMqService.onBitsVisibility()
      .subscribe(this.updateChapterPath1.bind(this));
  }

  ngOnDestroy() {
    if (this.bitBook) {
      this.readerLocalContentService.destroyL1Cache(this.bitBook.externalId);
    }
    this.ngbModal.dismissAll();
    this.sub.unsubscribe();
  }

  ngOnChanges() {
    if (this.bitBook) {
      this.ngZone.run(() => {
        this.bookTitle = this.bitBook.subtitle
          ? this.bitBook.title + ' • ' + this.bitBook.subtitle
          : this.bitBook.title;
      });
      // if anyone but the student tries to open the book search with a rejected hand in status, make it readonly
      if (this.bitBook.processHandIn && this.bitBook?.handIn && (this.bitBook?.handIn?.status !== HandInStatus.Rejected ||
        (this.bitBook?.handIn?.status === HandInStatus.Rejected &&
          this.bitBook?.handIn?.student?.email !== this.bitmarkConfig?.userEmails?.email &&
          this.bitBook?.handIn?.student?.email !== this.bitmarkConfig?.userEmails?.ssoEmail))) {
        this.isReadOnly = true;
      }
    }
  }

  handleFail() {
    this.bitBook = null;
    this.isLoading = false;
  }

  showBitInBook(e) {
    // this.readerMode = this.initialReaderMode;
  }

  updateChapterPath1(visibleBits: BitsViewPortVisibility) {
    if (this.isExternalSearch) {
      return;
    }
    const firstVisibleBit = visibleBits.bitsWithContentInViewPort[0] || visibleBits.bitsTouchingViewPort[0];
    if (!firstVisibleBit) {
      return;
    }
    if (firstVisibleBit.id === 'start') {
      this.chapterPathObj = null;
      this.chapterPath = this.cleanChapterPath(this.bitBook.title);
      return;
    }
    let chapterPath = firstVisibleBit.chapterPath;
    if (firstVisibleBit.chapterPath?.length > 1) {
      chapterPath = firstVisibleBit.chapterPath.slice(1);
    }
    if (chapterPath?.length) {
      this.chapterPath = this.computeChapterPath(chapterPath);
      this.chapterPathObj = chapterPath;
    }
  }

  removeChapterFromPath(chapterData: { externalBookId: string, chapterId: string }) {
    if (this.isExternalSearch) {
      return;
    }
    if (chapterData.chapterId && this.chapterPathObj?.length) {
      this.chapterPathObj = this.chapterPathObj.filter(c => c.ref !== chapterData.chapterId);
      this.chapterPath = this.computeChapterPath(this.chapterPathObj);
      return;
    }
  }

  private computeChapterPath(chapterPathObj: Array<BookEntityToc>): string {
    return [chapterPathObj[chapterPathObj.length - 1]]
      .filter(x => x)
      .map(x => this.cleanChapterPath(`${x?.item ?? ''} ${x?.isGenericTitle ? '' : x?.title}`))
      .join('');
  }

  private cleanChapterPath(path: string): string {
    if (!path) {
      return '';
    }

    return path.trim().replace(/(\r\n|\n|\r)+/g, ' – ');
  }

  protected readonly ReaderHiddenElements = ReaderHiddenElements;
}
