// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-input {
  visibility: hidden;
  width: 1px;
  height: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.placeholder {
  color: #656769;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
