import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {FileUtilsService} from '../../utils/file-utils.service';
import {ObjectService} from '../../utils/object.service';

declare const randomAvatar: any;

@Component({
  selector: 'bitmark-user-box-photo',
  templateUrl: './user-box-photo.component.html',
  styleUrls: ['./user-box-photo.component.scss']
})
export class UserBoxPhotoComponent implements OnChanges, OnDestroy {
  @Input() iconUrl: string;
  @Input() iconAlt: string;
  @Input() userEmail?: string;
  // @Input() userId?: number;
  @Input() fullName: string;
  @Input() size = 'md';
  @Input() alwaysShowIcon = true;
  @Input() showName = false;
  @Input() showIconDefaultText = true;
  @Input() namePosition: 'left' | 'right' = 'right';

  photoUrl: string;
  defaultAvatarSize = {
    sm: 25,
    md: 50,
    lg: 70,
    xl: 110,
    xxl: 320
  }


  constructor(private fileUtilsService: FileUtilsService,
              private objectService: ObjectService) {
  }

  ngOnChanges() {
    this.computePhotoUrl();
  }

  ngOnDestroy() {
    if (this.photoUrl?.startsWith('blob:')) {
      URL.revokeObjectURL(this.photoUrl);
    }
  }

  private getDefaultPhotoText(): string {
    if (!this.userEmail || !this.fullName) {
      return '';
    }

    if (!this.showIconDefaultText) {
      return '';
    }
    const split = this.fullName.split(' ');
    let txt = split[0].substr(0, 1);
    if (split.length > 1) {
      txt += split[1].substr(0, 1);
    }
    return txt.toUpperCase();
  }

  private computePhotoUrl() {
    if (!this.iconUrl) {
      const defaultText = this.getDefaultPhotoText();

      const imageDataUrl = randomAvatar({
        text: defaultText,
        style: 'circle',
        size: this.defaultAvatarSize[this.size || 'md'],
        colorSeed: this.objectService.getHashOfString(this.userEmail || this.fullName) || defaultText.split('').map(x => x.charCodeAt(0)).reduce((partialSum, x) => partialSum + x, 0),
        outputType: 'base64',
      });
      this.photoUrl = URL.createObjectURL(this.fileUtilsService.dataURItoBlob(imageDataUrl));

      return;
    }
    const image = new Image();
    image.onload = () => this.photoUrl = image.src;
    image.onerror = () => this.photoUrl = null;
    image.src = this.iconUrl;
  }
}
