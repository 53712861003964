import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PageProductVideoBit} from './page-product-video.models';
import {BookType, HandInStatus, ProductFamily} from '../../shared/models/bitmark.models';
import {BitProductService} from "../bit-product.service";

@Component({
  selector: 'bitmark-page-product-video',
  templateUrl: './page-product-video.component.html',
  styleUrls: ['./page-product-video.component.scss'],
})
export class PageProductVideoComponent implements OnInit {
  @Input() bit?: PageProductVideoBit;
  @Input() isNavigateDirectlyToBook?: boolean;
  @Output() navigateToProduct: EventEmitter<{ productId: string, family?: ProductFamily }> = new EventEmitter<{ productId: string, family?: ProductFamily }>();
  @Output() navigateToBook: EventEmitter<{ bookId: string, fragment: string, family?: ProductFamily }> = new EventEmitter<{
    bookId: string,
    fragment: string,
    family?: ProductFamily
  }>();
  isWideEntry = false;
  publisher: string;
  publisherAvatar: string;
  title: string;
  bookType: string;
  _operationId: string;
  product: any;
  productId: string;
  videoLength: string;


  @HostListener('document:gmbProductDetailsDelivered', ['$event'])
  onProductPriceDelivered(event: any) {
    const product = this.bitProductService.getProductsFromEvent(event, this.product ? [this.product] : [], [this.bit?.productVideo]);
    this.product = product?.length ? product[0] : {};
    this.publisher = this.bitProductService.getPropertyValue(this.product?.meta?.publisher)
    this.title = this.product && this.product?.texts?.title ? this.product.texts.title : '';
    this.publisherAvatar = this.getPropertyValue(this.product?.onlyPublisherAvatar);
    this.videoLength = this.getPropertyValue(this.product?.meta?.video_duration)
  }

  constructor(private bitProductService: BitProductService){

  }

  ngOnInit() {
    this.productId = this.bit?.productVideo || (this.bit?._product?.length ? this.bit?._product[0] : null);
    this._operationId = (this.productId) + (Math.random() + 1).toString(36).substring(7);
    const event = new CustomEvent('gmbProductDetailsRequested', {
      detail: {
        productIds: [this.productId],
        operationId: this._operationId
      }, bubbles: true
    });
    document.dispatchEvent(event);
  }

  openProductPage() {
    if (this.isNavigateDirectlyToBook) {
      if (this.product?.meta?.family === ProductFamily.XModule) {
        return this.navigateToBook.emit({bookId: this.bit?.productVideo, fragment: null, family: ProductFamily.XModule});
      }
      if (!this.product?.bookExists) {
        return;
      }
      this.navigateToBook.emit({bookId: this.bit?.productVideo, fragment: 'start'})
    } else {
      if (this.product?.meta?.family === ProductFamily.XModule) {
        return this.navigateToProduct.emit({productId: this.bit?.productVideo, family: ProductFamily.XModule});
      }
      this.navigateToProduct.emit({productId: this.bit?.productVideo, family: this.product?.meta?.family});
    }
  }

  getPropertyValue(arrayPropertyValue: Array<any>) {
    if (arrayPropertyValue?.length) {
      return arrayPropertyValue[0]?.data || arrayPropertyValue[0]?.file?.url;
    }
    return null;
  }

  protected readonly BookType = BookType;
  protected readonly HandInStatus = HandInStatus;
}
