import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MultipleResponseBit, MultipleResponseOption, MultipleResponseQuiz} from './multiple-response.models';
import {BitResource} from '../bits.models';
import {BitEvalResult} from "../bit-eval.service";

@Component({
  selector: 'bitmark-multiple-response',
  templateUrl: './multiple-response.component.html',
  styleUrls: ['./multiple-response.component.scss', '../bits.scss']
})
export class MultipleResponseComponent implements OnInit, OnChanges {
  @Input() bit?: MultipleResponseBit;
  @Input() bitId: string;
  @Input() readOnly = false;
  @Input() hasFeedback = false;
  @Output() openResource = new EventEmitter<BitResource>();
  @Output() focused = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<any>();

  answersCss = {};

  ngOnInit() {
    this.initializeExample();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFeedback']) {
      this.bit.quizzes?.forEach((q: MultipleResponseQuiz, idx: number) => {
        q.responses?.forEach((c: MultipleResponseOption, ridx: number) => {
          this.answersCss[idx * 10 + ridx] = this.handleFeedback(q.answer?.responses || [], c, this.hasFeedback);
        });
      });
    }

    if (changes['hasFeedback']?.previousValue && !changes['hasFeedback']?.currentValue) {
      this.initializeExample();
    }
  }

  setQuizAnswer($event: Event, quiz: MultipleResponseQuiz, selectedResponse: string) {
    if (!quiz.answer) {
      quiz.answer = {responses: []};
    }
    const isChecked = ($event.target as any).checked;
    const idx = quiz.answer.responses.indexOf(selectedResponse);

    if (isChecked && idx === -1) {
      quiz.answer.responses.push(selectedResponse);
    }
    if (!isChecked && idx !== -1) {
      quiz.answer.responses.splice(idx, 1);
    }

    this.focused.emit(true);
    this.changed.emit();
  }

  isResponseChecked(quiz: MultipleResponseQuiz, response: MultipleResponseOption): boolean {
    if (response.isExample) {
      return response.example;
    }

    if (!quiz.answer?.responses) {
      return false;
    }
    const idx = quiz.answer.responses.indexOf(response.response);
    return idx !== -1;
  }

  private initializeExample() {
    if (!this.bit.isExample) {
      return;
    }

    this.bit.quizzes?.forEach((q: MultipleResponseQuiz, idx: number) => {
      if (q.isExample) {
        q.responses?.forEach((c: MultipleResponseOption, ridx: number) => {
          if (c.example) {
            q.answer = {responses: [c.response]};
          }
          this.answersCss[idx * 10 + ridx] = this.handleFeedback(q.answer?.responses || [], c, q.isExample);
        });
      }
    });
  }

  private handleFeedback(quizResponses: string[], c: MultipleResponseOption, responseHasFeedback: boolean) {
    return !responseHasFeedback
      ? ''
      : ((c.isCorrect && quizResponses.includes(c.response)) || (!c.isCorrect && !quizResponses.includes(c.response)))
        ? 'correct'
        : 'incorrect';
  }

  static evalAnswer(bit: MultipleResponseBit): BitEvalResult {
    const ret = {
      totalAnswers: bit.quizzes?.length,
      answeredAnswers: 0,
      notAnsweredAnswers: 0,
      correctAnswers: 0,
      incorrectAnswers: 0
    } as BitEvalResult;

    bit.quizzes.forEach((q: MultipleResponseQuiz) => {
      if (q.answer?.responses?.length) {
        ret.answeredAnswers++;
      } else {
        ret.notAnsweredAnswers++;
      }

      const isAnswerCorrect = q.responses.every(r => {
        const answerUsed = q.answer?.responses.includes(r.response);
        return (r.isCorrect && answerUsed) || (!r.isCorrect && !answerUsed);
      });
      if (isAnswerCorrect) {
        ret.correctAnswers++;
      } else {
        ret.incorrectAnswers++;
      }
    });

    console.log(ret);

    return ret;
  }
}
