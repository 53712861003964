// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flashcards-card-container {
  perspective: 1000px;
}

.flashcards-card {
  width: var(--bitmark-app-flashcards-card-size-width, var(--bitmark-x-size-width, inherit));
  height: var(--bitmark-app-flashcards-card-size-height, var(--bitmark-x-size-height, inherit));
  position: relative;
  text-align: center;
  transform-style: preserve-3d;
}
.flashcards-card .flashcards-card-logo {
  position: absolute;
  --bit-icon-position: var(--bitmark-app-flashcards-card-icon-location, var(--bitmark-bit-icon-location, inherit));
  --bitmark-local-icon-width: var(--bitmark-app-flashcards-card-icon-width, var(--bitmark-x-icon-width, \$defaultSize));
  --bitmark-local-icon-height: var(--bitmark-app-flashcards-card-icon-height, var(--bitmark-x-icon-height, \$defaultSize));
  --bitmark-local-icon-location-top-center: 0 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  --bitmark-local-icon-location-bottom-center: auto 0 0 calc(50% - (var(--bitmark-local-icon-width) / 2));
  background-image: var(--bitmark-custom-icon-image, var(--bitmark-app-flashcards-card-icon-image, var(--bitmark-x-icon-image)));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: var(--bitmark-app-flashcards-card-icon-width, var(--bitmark-x-icon-width, 0));
  height: var(--bitmark-app-flashcards-card-icon-height, var(--bitmark-x-icon-height, 0));
  inset: var(--bitmark-app-flashcards-card-icon-location, var(--bitmark-x-icon-location, inherit));
  margin: var(--bitmark-app-flashcards-card-icon-margin, var(--bitmark-x-icon-margin, 0));
}
.flashcards-card .flashcards-card-logo.icon-top-center {
  inset: var(--bitmark-local-icon-location-top-center);
}
.flashcards-card .flashcards-card-logo.icon-bottom-center {
  inset: var(--bitmark-local-icon-location-bottom-center);
}
.flashcards-card .flashcards-card-front, .flashcards-card .flashcards-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
  border-radius: 15px;
  overflow: hidden;
}
.flashcards-card .flashcards-card-front {
  padding: var(--bitmark-app-flashcards-card-size-padding, var(--bitmark-x-size-padding, 0));
  background-color: var(--bitmark-app-flashcards-card-front-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-app-flashcards-card-front-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-app-flashcards-card-front-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-app-flashcards-card-front-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-app-flashcards-card-front-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-app-flashcards-card-front-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-app-flashcards-card-front-background-animation-name) var(--bitmark-app-flashcards-card-front-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-app-flashcards-card-front-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-app-flashcards-card-front-background-backdrop-filter);
  font-family: var(--bitmark-app-flashcards-card-front-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-app-flashcards-card-front-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-app-flashcards-card-front-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-app-flashcards-card-front-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-app-flashcards-card-front-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-app-flashcards-card-front-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-app-flashcards-card-front-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-app-flashcards-card-front-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-app-flashcards-card-front-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-app-flashcards-card-front-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
}
.flashcards-card .flashcards-card-front > bitmark-text {
  flex: 1;
  max-height: calc(100% - 20px);
}
.flashcards-card .flashcards-card-back {
  padding: var(--bitmark-app-flashcards-card-back-size-padding, var(--bitmark-app-flashcards-card-size-padding, 0));
  background-color: var(--bitmark-app-flashcards-card-back-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, var(--bitmark-x-background-color, inherit))));
  background-image: var(--bitmark-app-flashcards-card-back-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image, var(--bitmark-x-background-image))));
  background-size: var(--bitmark-app-flashcards-card-back-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, var(--bitmark-x-background-size, cover))));
  background-position: var(--bitmark-app-flashcards-card-back-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, var(--bitmark-x-background-position, center))));
  background-repeat: var(--bitmark-app-flashcards-card-back-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, var(--bitmark-x-background-repeat, no-repeat))));
  transform: var(--bitmark-app-flashcards-card-back-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, var(--bitmark-x-background-transform, none))));
  animation: var(--bitmark-app-flashcards-card-back-background-animation-name) var(--bitmark-app-flashcards-card-back-background-animation-time) ease infinite;
  backdrop-filter: var(--bitmark-app-flashcards-card-back-background-backdrop-filter);
  -webkit-backdrop-filter: var(--bitmark-app-flashcards-card-back-background-backdrop-filter);
  font-family: var(--bitmark-app-flashcards-card-back-font-family, var(--bitmark-main-font-family, var(--bitmark-main-font-family)));
  font-size: var(--bitmark-app-flashcards-card-back-font-size, var(--bitmark-main-font-size, var(--bitmark-main-font-size)));
  font-weight: var(--bitmark-app-flashcards-card-back-font-weight, var(--bitmark-main-font-weight, var(--bitmark-main-font-weight)));
  font-style: var(--bitmark-app-flashcards-card-back-font-style, var(--bitmark-main-font-style, var(--bitmark-main-font-style)));
  -webkit-text-decoration: var(--bitmark-app-flashcards-card-back-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
          text-decoration: var(--bitmark-app-flashcards-card-back-font-text-decoration, var(--bitmark-main-font-text-decoration, var(--bitmark-main-font-text-decoration)));
  line-height: var(--bitmark-app-flashcards-card-back-font-line-height, var(--bitmark-main-font-line-height, var(--bitmark-main-font-line-height)));
  color: var(--bitmark-app-flashcards-card-back-font-color, var(--bitmark-main-font-color, var(--bitmark-main-font-color)));
  text-align: var(--bitmark-app-flashcards-card-back-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-align, left)));
  justify-content: var(--bitmark-app-flashcards-card-back-font-align, var(--bitmark-main-font-align, var(--bitmark-main-font-alignt, left)));
  transform: rotateY(180deg);
}
.flashcards-card .flashcards-card-back > bitmark-text {
  height: calc(100% - 50px);
}
.flashcards-card .flashcards-card-front-buttons {
  display: var(--bitmark-app-flashcards-card-front-emoji-display, flex) !important;
}
.flashcards-card .flashcards-card-buttons {
  height: 50px;
  font-size: 30px;
}
.flashcards-card .flashcards-card-buttons .right-btn {
  cursor: pointer;
  justify-content: flex-end;
}
.flashcards-card .flashcards-card-buttons .right-btn::before {
  content: var(--bitmark-app-flashcards-got-it-right-emoji-string);
}
.flashcards-card .flashcards-card-buttons .wrong-btn {
  cursor: pointer;
}
.flashcards-card .flashcards-card-buttons .wrong-btn::before {
  content: var(--bitmark-app-flashcards-got-it-wrong-emoji-string);
}
.flashcards-card .flashcards-card-buttons .right-btn, .flashcards-card .flashcards-card-buttons .wrong-btn {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-end;
}
.flashcards-card .flashcards-card-buttons .selected-action-btn {
  font-size: 38px;
  transition: 0.1s ease-out;
}
.flashcards-card .flashcards-card-buttons .opposite-selected-action-btn {
  font-size: 18px;
  transition: 0.1s ease-out;
}
.flashcards-card .flashcards-card-index {
  font-size: 14px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
